import {whiteColor} from '../../material-dashboard-react'

const chipStyle = theme => ({
  root: {
    margin: 2,
    fontSize: 13,
    minHeight: 33,
    height: 'auto!Important'
  },
  avatar: {
    height: 24,
    width: 24
  },
  avatarChildren: {
    height: 14,
    width: 14
  },
  infoRoot: {
    padding: 4,
    margin: '0 5px 5px 0',
    borderRadius: 6,
    backgroundColor: theme.colors.backgroundChip,
    fontSize: 10,
    minHeight: 33,
    height: 'auto!Important',
    //border: `1px solid ${theme.colors.common.border40}`
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
    },
  },
  infoRootMultiline: {
    height: 'auto',
    '& :nth-child(1)': {
      alignSelf: 'flex-start'
    }
  },
  infoRootIcon: {
    width: 33,
    justifyContent: 'flex-start',
    fontSize: 14
  },
  infoRootSlimmer: {
    padding: '3px 6px 3px',
    borderRadius: 50,
    border: 0,
    backgroundColor: theme.colors.backgroundChip,
    margin: 2,
    fontSize: 8,
    minHeight: 'auto!Important',
  },
  infoRootSlimmerLabel: {
    color: 'white !important'
  },
  infoRootSlim: {
    padding: '6px 12px 8px',
    borderRadius: 50,
    border: 0,
    backgroundColor: theme.colors.backgroundChip,
    margin: 2,
    fontSize: 8,
    height: 20
  },
  infoRootSlimLabel: {
    paddingLeft: `0px !important`,
    paddingRight: `0px !important`,
  },
  infoRootSlimLabelError: {
    color: `${theme.colors.common.danger} !important`,
  },
  infoRootSlimLabelWarning: {
    color: `${theme.colors.common.danger} !important`,
  },
  infoRootSlimLabelSuccess: {
    color: `${theme.colors.common.success} !important`,
  },
  infoRootHighlightError: {
    padding: 4,
    borderRadius: 6,
    border: `2px solid ${theme.colors.common.danger}`,
    backgroundColor: theme.colors.common.dangerLight,
    margin: 2,
    fontSize: 10,
    height: 40
  },
  infoAvatar: {
    borderRadius: 3,
    margin: 0,
    height: 25,
    width: 25,
    fontSize: '1.2rem',
    background: 'none',
    display: 'block',
    textAlign: 'center',
    lineHeight: '1.4'
  },
  infoAvatarChildren: {
    height: 22,
    width: 22
  },
  infoConvo: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.common.turquoiseColor,
    fontWeight: 'bold',
  },
  infoUtterance: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.common.violettColor,
    fontWeight: '500',
  },
  infoScripting: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.common.greenColor1,
    fontWeight: 'bold',
  },
  infoError: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.common.danger,
    fontWeight: 'bold',
  },
  infoWarning: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.common.warningColor,
    fontWeight: 'bold',
  },
  infoSuccess: {
    color: theme.colors.common.white,
    backgroundColor: theme.colors.common.success,
    fontWeight: 'bold',
  },
  label: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
  },
  labelTruncated: {
    overflowWrap: 'anywhere',
    hyphens: 'auto',
    display: 'inline-block',
    whiteSpace: 'break-spaces',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '5px 8px',
    fontSize: 13,
    fontWeight: 500,
    color: theme.colors.common.primary,
    '-webkit-user-select': 'auto!Important',/* Safari 3.1+ */
    '-moz-user-select': 'auto!Important',/* Firefox 2+ */
    userSelect: 'auto!Important',/* Standard syntax */
    lineHeight: 1.3,
  },
  labelMultiline: {
    whiteSpace: 'break-spaces'
  },
  deleteIcon: {
    height: 20
  },
  infoText: {
    position: 'relative',
    top: 0,
    display: 'block'

  },
  badge: {
    backgroundColor: theme.colors.common.backgroundBadge,
    padding: '2px 5px',
    borderRadius: 6,
    fontSize: 10,
    marginLeft: 5
  },
  infoRootcursorPointer: {
    cursor: 'pointer!Important',
  },
  infoRooticoncolordarkblue: {
    '& svg': {
      color: theme.colors.common.primary,
    }
  },
  infoNobg: {
    background: 'none',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    '& span':{
      color: `${theme.colors.primary}!Important`,
    }
  },
  infoRootNamespace: {
    border: `1px solid ${theme.colors.borderColorhover} !important`,
    color: theme.colors.primaryColor1,
    background: 'none',
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: theme.colors.borderColorhover,
        fontSize: 12
      }
    },
    '&:hover': {
      background: 'none',
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootRegressionType: {
    color: `${theme.colors.common.primary}`,
    backgroundColor: theme.colors.common.primarydark,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: `${theme.colors.common.primary}`,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootE2EType: {
    color: whiteColor,
    backgroundColor: theme.colors.e2etext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '14px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: whiteColor,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootNlpType: {
    color: whiteColor,
    backgroundColor: theme.colors.nlptext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: whiteColor,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootPerformanceType: {
    color: theme.colors.common.primary,
    backgroundColor: theme.colors.performancetext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: theme.colors.common.primary,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootGdprType: {
    color: whiteColor,
    backgroundColor: theme.colors.gdprtext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: whiteColor,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootLogMonitoringType: {
    color: `${theme.colors.common.primary}`,
    backgroundColor: theme.colors.logmonitoringtext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: `${theme.colors.common.primary}`,
        fontSize: 12,
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootFactCheckerType: {
    color: `${theme.colors.common.primary}`,
    backgroundColor: theme.colors.factcheckertext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: `${theme.colors.common.primary}`,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootMisuseCheckerType: {
    color: '#fff',
    backgroundColor: theme.colors.misusecheckertext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: '#fff',
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  },
  infoRootSecurityType: {
    color: whiteColor,
    backgroundColor: theme.colors.securetext,
    padding: '4px 10px',
    marginBottom: 15,
    marginRight: 8,
    minHeight: '15px',
    textWrap: 'wrap',
    '& span': {
      padding: 0,
      '& span': {
        padding: 0,
        color: whiteColor,
        fontSize: 12
      }
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}!Important`,
      background: 'none',
    },
  }
})

export default chipStyle

import React from 'react'
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import ListItem from 'components/List/ListItem/ListItem'
import {Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'

// apollo
import {compose, graphql, Mutation, Query, withApollo} from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import ShowIcon from 'components/Icon/ShowIcon'
import Field from 'components/Form/OptionalField'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ObjectChips from 'components/Chip/ObjectChips'
import {safeGetNamespaceFilteredList} from '../helper'
import ErrorFormat from 'components/Info/ErrorFormat'


import {
  FormActionsToolbar, minValue, parseInteger, renderAutoSuggest, renderIntField,
  renderNamespaceField,
  renderSelect,
  renderTextArea,
  renderTextField,
  required
} from 'components/Form/Form'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import {removeRecentListEntry} from 'actions/activity'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import {
  DELETE_MISUSECHECKERPROJECT,
  MISUSECHECKERPROJECT_QUERY,
  RefetchMisuseCheckerProjectQueries,
  START_MISUSECHECKERPROJECT,
  UPDATE_MISUSECHECKERPROJECT,
} from './gql'
import {DeleteTestSessionListsFromCache, RefetchTestSessionQueries} from '../TestSessions/gql'
import {CHATBOTS_DROPDOWN_QUERY} from '../Chatbots/gql'

import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'
import {
  canReadNamespace,
  canWriteNamespace,
  hasAnyPermission,
  hasPermission
} from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import {Bar, BarChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {TESTSESSIONS_QUERY} from '../TestSessions/gql'

class MisuseCheckerProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newTestSessionCount: 0,
      testSessionProgressKey: 0,
    }
  }

  hasReadPermission() {
    return this.props.hasReadPermissions
  }
  hasWritePermission() {
    return this.props.hasWritePermissions
  }

  renderTestResultsDashboardChart(misusecheckerproject) {
    const where = {
      status_in: ['READY', 'FAILED'],
      misuseCheckerSession: {
        project: {
          id: misusecheckerproject.id
        }
      }
    }

    const orderBy = 'createdAt_DESC'

    return <GridContainer>
        <GridItem md={12}>
          <Query query={TESTSESSIONS_QUERY} 
            variables={{ where, orderBy }} 
            fetchPolicy={'network-only'} 
            notifyOnNetworkStatusChange={true}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingIndicator />
                if (error) return <ErrorFormat err={error} />

                const sessions = data?.testsessions || []
                const chartData = sessions.map((s, i) => {
                  
                  const misuseCheckerChartData = s.misuseCheckerSession.chartData ? JSON.parse(s.misuseCheckerSession.chartData) : {}
                  
                  return {
                    name: s.createdAt,
                    correct: misuseCheckerChartData.correctStatements ? misuseCheckerChartData.correctStatements : 0,
                    high: (misuseCheckerChartData.highSeverity ? misuseCheckerChartData.highSeverity : 0) * -1,
                    medium: (misuseCheckerChartData.mediumSeverity ? misuseCheckerChartData.mediumSeverity : 0) * -1,
                    low: (misuseCheckerChartData.lowSeverity ? misuseCheckerChartData.lowSeverity : 0) * -1,
                  }
                })

                console.log('chartData', chartData)

                return (
          
           <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        width={500}
                        height={300}
                        data={chartData}
                        stackOffset="sign"
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <XAxis dataKey="name" hide={true} />
                        <YAxis tick={{ fontSize: 12 }} />
                        <Tooltip />
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />
                        <Bar dataKey="correct" fill="green" stackId="stack" barSize={40} />
                        <Bar dataKey="low" fill="yellow" stackId="stack" barSize={40} />
                        <Bar dataKey="medium" fill="orange" stackId="stack" barSize={40} />
                        <Bar dataKey="high" fill="red" stackId="stack" barSize={40} />
                      </BarChart>
                    </ResponsiveContainer>
                )
              }}
            </Query>

               
        </GridItem>
      </GridContainer>
  }

  renderChatbotSelector() {
    const { chatbotsData } = this.props

    return <Field
      name="chatbot.id"
      component={renderSelect}
      label="Chatbot"
      data-unique="selMisuseCheckerProjectChatbotId"
      validate={required}
      disabled={!this.hasWritePermission()}
      loading={chatbotsData && chatbotsData.loading}
      error={chatbotsData && chatbotsData.error}
      items={chatbotsData && this.props.misusecheckerproject.chatbot && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace, this.props.misusecheckerproject.chatbot.id).map(c => {
        return {
          key: c.id,
          chatbot: c
        }
      })}
    />
  }

  renderDashboard(misusecheckerproject) {
    const { classes, user, license, setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return (<>
      <GridContainer>
        <GridItem lg={6}>
          <ObjectChips lg={6} chatbot={misusecheckerproject.chatbot}/>
        </GridItem>
        <GridItem md={6} lg={6} right className={classes.dashboardbuttongrid}>
          <Card noBorder cardWidthAuto>
            <CardBody>
              {this.hasWritePermission() && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                <Mutation
                  mutation={START_MISUSECHECKERPROJECT}
                  onCompleted={data => {
                    setAlertSuccessMessage('LLM Misuse Checker Test session started ...')
                    this.setState({ newTestSessionCount: this.state.newTestSessionCount + 1 })
                  }}
                  onError={error => {
                    setAlertErrorMessage('LLM Misuse Checker Test session failed', error)
                  }}
                  refetchQueries={[
                    ...RefetchTestSessionQueries()
                  ]}
                  update={DeleteTestSessionListsFromCache}
                >
                  {(startMisuseCheckerProject, { loading, error }) =>
                  (<React.Fragment>
                    <Button leftRound
                      id="start_test_session"
                      data-unique="btnMisuseCheckerProjectStartTestSession"
                      onClick={() => {
                        startMisuseCheckerProject({
                          variables: { id: misusecheckerproject.id, debug: false },
                        })
                      }}
                    >
                      <ShowIcon icon={misusecheckerproject.lastMisuseCheckerSession ? 'redo' : 'play'}/> {misusecheckerproject.lastMisuseCheckerSession ? 'Repeat Test Session' : 'Start Test Session'}
                    </Button>
                    <DropdownButton
                      rightRound
                      items={[{
                        id: 'start_test_session_logging',
                        name: misusecheckerproject.lastMisuseCheckerSession ? 'Repeat Test Session (Extended Logging)' : 'Start Test Session (Extended Logging)',
                        icon: 'bug',
                        disabled: !license.detailedReporting,
                        dataUnique: 'btnMisuseCheckerProjectStartTestSessionDebug',
                        onClick: () => {
                          startMisuseCheckerProject({
                            variables: { id: misusecheckerproject.id, debug: true },
                          })
                        }
                      }]}
                      data-unique="ddbtnMisuseCheckerProjectStartTestSession"
                    />
                  </React.Fragment>
                  )}
                </Mutation>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
     {/*this.renderTestResultsDashboardChart(misusecheckerproject)*/}
      <GridContainer>
        <GridItem md={12}>
          <Card>
            <CardHeader color="info" >
              <Text header>Recent Test Results</Text>
              <Text subheader>Recent Test Results of this Test Project</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} noPadding>
                  {this.renderTestResultsDashboard(misusecheckerproject)}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </>
    )
  }

  render() {
    const { misusecheckerproject, user } = this.props

    return (
      <CustomTabs
        name={`MisuseCheckerProjectTabs_${misusecheckerproject.id}`}
        headerColor="info"
        tabs={[
          {
            tabName: 'Overview',
            tabIcon: <ShowIcon icon="fa-check-to-slot" />,
            disabled: !hasPermission(user, 'TESTSESSIONS_SELECT'),
            tabContent: hasPermission(user, 'TESTSESSIONS_SELECT') && this.renderDashboard(misusecheckerproject),
            locationPrefix: `/misusecheck/projects/view/${misusecheckerproject.id}/dashboard`,
            dataUnique: 'tabMisuseCheckerProjectDashboard'
          },
          this.hasReadPermission() && hasAnyPermission(user, ['TESTPROJECTS_CREATE', 'TESTPROJECTS_UPDATE', 'TESTPROJECTS_DELETE', 'TESTSESSIONS_CREATE']) && {
            tabName: 'Configuration',
            tabRight: true,
            tabIcon: <ShowIcon icon="wrench" />,
            tabContent: this.renderSettingsForm(misusecheckerproject),
            locationPrefix: `/misusecheck/projects/view/${misusecheckerproject.id}/settings`,
            dataUnique: 'tabMisuseCheckerProjectSettings'
          }
        ].filter(t => t)}
      />
    )
  }


  renderTestResultsDashboard(misusecheckerproject) {
    const { classes } = this.props
    const tableName = `MisuseCheckerProject_Dashboard_TestSessions_${misusecheckerproject.id}`

    return <GridContainer>
      <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
        <TestSessionsEmbeddedTable key={`TestSessions_${this.state.newTestSessionCount}`} variables={{ misuseCheckerSession: { project: { id: misusecheckerproject.id } }, misuseCheckerTesting: true }} disableOrderBy disableFilter skip={0} first={5} name={tableName} hideProjectLink={true} />
      </GridItem>
    </GridContainer>
  }

  renderSettingsFormTab(misusecheckerproject, fnForm, fnLeftButtons, fnRightButtons) {
    const { setAlertSuccessMessage, setAlertErrorMessage } = this.props

    return (<Mutation
      mutation={UPDATE_MISUSECHECKERPROJECT}
      refetchQueries={[
        ...RefetchMisuseCheckerProjectQueries(),
      ]}
    >
      {(updateMisuseCheckerProject, { loading, error }) => (
        <Form
          mutators={{ ...arrayMutators }}
          onSubmit={async (values, form) => {
            const request = {
              variables: {
                id: values.id,
                misuseCheckerProject: {
                  name: values.name,
                  description: values.description || null,
                  namespace: values.namespace || null,
                  chatbotId: values.chatbot.id,
                  configuration: {
                    allowedDomains: { set: values.configuration.allowedDomains || [] },
                    approvedTopics: { set: values.configuration.approvedTopics || [] },
                    confusedSentences: { set: values.configuration.confusedSentences || [] },
                    forbiddenTopics: { set: values.configuration.forbiddenTopics || [] },
                    ignoredSentences: { set: values.configuration.ignoredSentences || [] },
                    distractionTopics: { set: values.configuration.distractionTopics || []},
                    numberOfCycles: values.configuration.numberOfCycles || 1,
                  }
                }
              }
            }
            try {
              const res = await updateMisuseCheckerProject(request)
              form.initialize(res.data.updateMisuseCheckerProject)
              setAlertSuccessMessage('LLM Misuse Checker Project updated')
            } catch (error) {
              setAlertErrorMessage('LLM Misuse Checker Project update failed', error)
            }
          }}
          initialValues={misusecheckerproject}
          render={({
            handleSubmit,
            form,
            submitting,
            invalid,
            values
          }) => {
            return (<form onSubmit={handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem md={12} lg={8}>
                  {fnForm({ form, values, submitting })}
                </GridItem>
                <GridItem md={12} lg={8} largePadding>
                  <FormActionsToolbar
                    leftButtons={<>
                      {fnLeftButtons && fnLeftButtons({ form, values, submitting })}
                    </>}
                    rightButtons={<>
                      {fnRightButtons && fnRightButtons({ form, values, submitting })}
                      {this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnMisuseCheckerProjectSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      }
                    </>}
                  />
                </GridItem>
              </GridContainer>
            </form>
            )
          }
          }
        />
      )}
    </Mutation>)
  }

  renderSettingsForm(misusecheckerproject) {
    const {
      setAlertSuccessMessage,
      setAlertErrorMessage,
      removeRecentListEntry,
      history,
      user,
    } = this.props


    return (<CustomTabsSecondary
      name={`tabMisuseCheckerProjectSettings_${misusecheckerproject.id}`}
      headerColor="info"
      tabs={[
        {
          tabName: 'LLM Misuse Checker Project',
          tabContent: this.renderSettingsFormTab(misusecheckerproject, () => <GridContainer>
            <GridItem md={6}>
              <Field
                name="name"
                component={renderTextField}
                label="LLM Misuse Checker Project Name"
                validate={required}
                disabled={!this.hasWritePermission()}
                data-unique="txtMisuseCheckerProjectName"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="namespace"
                component={renderNamespaceField}
                forWrite
                label="Namespace"
                disabled={!this.hasWritePermission()}
                data-unique="txtTMisuseCheckerProjectNamespace"
              />
            </GridItem>
            <GridItem md={6}>
              {this.renderChatbotSelector()}
            </GridItem>
            <GridItem md={12}>
              <Field
                name="description"
                component={renderTextArea}
                label="Project Description"
                rows={3}
                disabled={!this.hasWritePermission()}
                data-unique="txtMisuseCheckerProjectDescription"
              />
            </GridItem>

          </GridContainer>),
          locationPrefix: `/misusecheck/projects/view/${misusecheckerproject.id}/settings/basic`,
          dataUnique: 'tabMisuseCheckerProjectSettings'
        },
        {
          tabName: 'Configuration',
          tabContent: this.renderSettingsFormTab(misusecheckerproject, () => <GridContainer>
            <GridItem md={6}>
              <Field
                name="configuration.allowedDomains"
                component={renderAutoSuggest}
                disabled={!this.hasWritePermission()}
                label="Chatbot Domains"
                validate={required}
                helperText="A list of primary domain(s) in which the Target Bot is expected to operate, ensuring it maintains focus within its designated areas."
                data-unique="txtMisuseCheckerProjectAllowedDomains"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="configuration.distractionTopics"
                component={renderAutoSuggest}
                disabled={!this.hasWritePermission()}
                label="Distraction Topics"
                validate={required}
                helperText="A list of off-topic subjects designed to test the bot’s ability to maintain focus within its designated domain."
                data-unique="txtMisuseCheckerProjectDistrationTopics"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="configuration.numberOfCycles"
                component={renderIntField}
                disabled={!this.hasWritePermission()}
                label="Number of cycles"
                helperText="The number of interaction cycles in which the bot is tested. The higher the number, the more comprehensive the test."
                parse={parseInteger}
                format={undefined}
                validate={minValue(1)}
                data-unique="intMisuseCheckerProjectNumberOfCycles"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="configuration.approvedTopics"
                component={renderAutoSuggest}
                disabled={!this.hasWritePermission()}
                label="Approved Topics"
                helperText="A list of acceptable off-domain topics that the bot is permitted to discuss without being flagged as violations. "
                data-unique="txtMisuseCheckerProjectApprovedTopics"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="configuration.forbiddenTopics"
                component={renderAutoSuggest}
                disabled={!this.hasWritePermission()}
                label="Forbidden Topics"
                helperText="A list of topics that the bot is strictly prohibited from discussing, even if they fall within its designated domain."
                data-unique="txtMisuseCheckerProjectForbiddenTopics"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="configuration.confusedSentences"
                component={renderAutoSuggest}
                disabled={!this.hasWritePermission()}
                label="Confused Sentences"
                helperText="A list of phrases the bot might use when it encounters uncertainty or confusion, such as “Can you rephrase that?”"
                data-unique="txtMisuseCheckerProjectConfusedSentences"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="configuration.ignoredSentences"
                component={renderAutoSuggest}
                disabled={!this.hasWritePermission()}
                label="Ignored Sentences"
                helperText="A list of specific sentences or phrases to be disregarded during analysis."
                data-unique="txtMisuseCheckerProjectIgnoredSentences"
              />
            </GridItem>
          </GridContainer>),
          locationPrefix: `/misusecheck/projects/view/${misusecheckerproject.id}/settings/configuration`,
          dataUnique: 'tabMisuseCheckerProjectConfiguration'
        },
        {
          tabName: 'Danger Zone',
          tabContent: <GridContainer>
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text danger lg padding><ShowIcon icon="trash" /></Text>
                <GridContainer>
                  <GridItem xs={12}>
                    <Text bold>Delete LLM Misuse Checker Project</Text>
                  </GridItem>
                  <GridItem xs={12}>
                    <Text>Remove this LLM Misuse Checker Project and its configuration settings</Text>
                  </GridItem>
                </GridContainer>
                <Mutation
                  mutation={DELETE_MISUSECHECKERPROJECT}
                  onCompleted={data => {
                    removeRecentListEntry({
                      url: `/misusecheck/projects/view/${misusecheckerproject.id}`
                    })
                    setAlertSuccessMessage('LLM Misuse Checker Project deleted')
                    history.push('/misusecheck/projects')
                  }}
                  onError={error => {
                    setAlertErrorMessage(
                      'LLM Misuse Checker Project deletion failed',
                      error,
                    )
                  }}
                  refetchQueries={[
                    ...RefetchMisuseCheckerProjectQueries()
                  ]}
                >
                  {(deleteMisuseCheckerProject, { loading, error }) => (
                    <ConfirmationButton
                      confirmationText={`When deleting the LLM Misuse Checker Project "${misusecheckerproject.name}", all configuration settings are removed. Test Results won't be deleted. Are you sure you want to delete it ?`}
                      requireCheck={true}
                      danger
                      small
                      minWidth
                      disabled={!hasPermission(user, 'TESTPROJECTS_DELETE')}
                      onClick={() => {
                        deleteMisuseCheckerProject({
                          variables: { id: misusecheckerproject.id },
                        })
                      }}
                      data-unique="btnMisuseCheckerProjectDelete"
                    >
                      Delete
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
          </GridContainer>,
          locationPrefix: `/misusecheck/projects/view/${misusecheckerproject.id}/settings/danger`,
          dataUnique: 'tabMisuseCheckerProjectSettingsDanger'
        }
      ]}
    />)
  }
}

const MisuseCheckerProjectComponent = compose(
  withApollo,
  withStyles(
    (theme) => ({
      ...testprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
    { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  })
  
)(MisuseCheckerProject)

export default connect(state => ({ user: state.token.user }))(function ({ match, user, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.projectId && (
          <Query query={MISUSECHECKERPROJECT_QUERY} variables={{ id: match.params.projectId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="misusecheckerproject" card>{(data) => {
              return <MisuseCheckerProjectComponent
                match={match}
                misusecheckerproject={data.misusecheckerproject}
                hasReadPermissions={hasPermission(user, 'TESTPROJECTS_SELECT') && canReadNamespace(user, user.namespacePermissions, data.misusecheckerproject.namespace)}
                hasWritePermissions={hasAnyPermission(user, ['TESTPROJECTS_CREATE', 'TESTPROJECTS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, data.misusecheckerproject.namespace)}
                {...rest} />
            }}</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
})

import React from 'react'
import {withRouter} from 'react-router-dom'
import {Query} from 'react-apollo'
import moment from 'moment'
import Tooltip from 'components/Tooltip/Tooltip'
// core components/views
import DashboardPage from 'views/Dashboard/Dashboard.jsx'
import QuickSearch from 'views/QuickSearch/QuickSearch.jsx'
import TestSession from 'views/TestSessions/TestSession.jsx'
import SecuritySession from 'views/TestSessions/SecuritySession.jsx'
import FactCheckerSession from 'views/TestSessions/FactCheckerSession.jsx'
import PerformanceTestSession from 'views/PerformanceTestSessions/PerformanceTestSession.jsx'
import GDPRSession from 'views/TestSessions/GDPRSession.jsx'
import Chatbots from 'views/Chatbots/Chatbots.jsx'
import Chatbot from 'views/Chatbots/Chatbot.jsx'
import ChatbotLiveChat from 'views/Chatbots/ChatbotLiveChat.jsx'
import TestSets from 'views/TestSets/TestSets.jsx'
import TestSet from 'views/TestSets/TestSet.jsx'
import TestSetLiveChat from 'views/TestSets/TestSetLiveChat.jsx'
import * as TestSetParaphraserSuggestions from 'views/TestSets/TestSetParaphraserSuggestions.jsx'
import TestSetUploadChatbotSelector from 'views/TestSets/TestSetUploadChatbotSelector.jsx'
import TestSetUpload from 'views/TestSets/TestSetUpload.jsx'
import TestDataWizards from 'views/TestDataWizard/TestDataWizards.jsx'
import AITestDataWizard from 'views/TestDataWizard/AITestDataWizard.jsx'
import AITestDataWizardNlu from 'views/TestDataWizard/AiTestDataWizardNlu.jsx'
import NLPDownloadChatbotSelector from 'views/TestDataWizard/NLPDownloadChatbotSelector.jsx'
import NLPDownloadWizard from 'views/TestDataWizard/NLPDownloadWizard.jsx'
import FileUploadWizard from 'views/TestDataWizard/FileUploadWizard.jsx'
import TranslatorTestSetSelector from 'views/TestDataWizard/TranslatorTestSetSelector.jsx'
import TranslatorWizard from 'views/TestDataWizard/TranslatorWizard.jsx'
import HumanificationTestSetSelector from 'views/TestDataWizard/HumanificationTestSetSelector.jsx'
import HumanificationWizard from 'views/TestDataWizard/HumanificationWizard.jsx'
import ParaphraserTestSetSelector from 'views/TestDataWizard/ParaphraserTestSetSelector.jsx'
import ParaphraserWizard from 'views/TestDataWizard/ParaphraserWizard.jsx'
import ParaphraserWizardSuggestions from 'views/TestDataWizard/ParaphraserWizardSuggestions.jsx'
import SplitterTestSetSelector from 'views/TestDataWizard/SplitterTestSetSelector.jsx'
import SplitterWizard from 'views/TestDataWizard/SplitterWizard.jsx'
import LiveChatChatbotSelector from 'views/TestDataWizard/LiveChatChatbotSelector.jsx'
import LiveChatWizard from 'views/TestDataWizard/LiveChatWizard.jsx'
import VoiceTestSetSelector from 'views/TestDataWizard/VoiceTestSetSelector.jsx'
import VoiceWizard from 'views/TestDataWizard/VoiceWizard.jsx'
import TestProjects from 'views/TestProjects/TestProjects.jsx'
import TestProject from 'views/TestProjects/TestProject.jsx'
import TestProjectLiveChat from 'views/TestProjects/TestProjectLiveChat.jsx'
import CrawlerProjects from 'views/CrawlerProjects/CrawlerProjects.jsx'
import CrawlerSession from 'views/CrawlerProjects/CrawlerSession.jsx'
import CrawlerSessionHistory from 'views/CrawlerProjects/CrawlerSessionHistoryList.jsx'
import CrawlerSessionIcon from '../views/CrawlerProjects/CrawlerSessionIcon'
import LLMprojects from 'views/LLMprojects/LLMprojects.jsx'
import FactCheckerProjects from 'views/LLMprojects/FactCheckerProjects.jsx'
import FactCheckerProject from 'views/LLMprojects/FactCheckerProject.jsx'
import FactSessionProgressIcon from 'views/TestSessions/FactCheckerSessionProgressIcon'
import NamespaceOverview from 'views/Namespaces/Overview'
import Settings from 'views/Settings/Settings.jsx'
import ProfilePage from 'views/User/Profile'
import CoachDashboardEntry from 'views/Coach/Dashboard/DashboardEntry'
import UtteranceInsightsPage from 'views/Coach/Dashboard/UtteranceInsights'
import ShowIcon from 'components/Icon/ShowIcon'
import NavbarHeader from 'components/Header/NavbarHeader'
import QueryStatus from 'components/Info/QueryStatus'
import Chip from 'components/Chip/Chip'
import {NlpSessionProgressIcon, TestSessionRegressionE2ETypeChip} from 'views/TestSessions/helper'
import TestSessionProgressIcon from 'views/TestSessions/TestSessionProgressIcon'
import SecuritySessionProgressIcon from 'views/TestSessions/SecuritySessionProgressIcon'
import GDPRSessionProgressIcon from 'views/TestSessions/GDPRSessionProgressIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import PerformanceTestSessionProgressPolling from 'views/PerformanceTestSessions/PerformanceTestSessionProgressPolling'
import MisuseCheckerProject from 'views/LLMprojects/MisuseCheckerProject'
import MisuseCheckerProjects from 'views/LLMprojects/MisuseCheckerProjects'
import MisuseCheckerSessionProgressIcon from 'views/TestSessions/MisuseCheckerSessionProgressIcon'
import MisuseCheckerSession from 'views/TestSessions/MisuseCheckerSession'
import {TESTSET_QUERY, TESTSETS_COUNT_QUERY } from 'views/TestSets/gql'
import { CHATBOT_QUERY, CHATBOTS_COUNT_QUERY } from 'views/Chatbots/gql'
import { TESTPROJECT_QUERY, } from 'views/TestProjects/gql'
import { TESTSESSION_QUERY } from 'views/TestSessions/gql'
import { PERFORMANCETESTSESSION_QUERY} from 'views/PerformanceTestSessions/gql'
import {
  CRAWLERPROJECT_QUERY,
  CRAWLERPROJECTS_QUERY,
  CRAWLERSESSION_QUERY,
  CRAWLERSESSIONS_QUERY
} from 'views/CrawlerProjects/gql'
import {
  FACTCHECKERPROJECT_QUERY,
  MISUSECHECKERPROJECT_QUERY
} from 'views/LLMprojects/gql'
import coachRoutes from './coach.jsx'

import config from 'config'


const dashboardRoutes = [
  {
    path: '/profile',
    component: ProfilePage,
    navbarName: 'My User Profile'
  },
  {
    path: '/dashboard',
    sidebarName: 'Dashboard',
    uniqueName: 'Home',
    navbarName: 'Dashboard',
    icon: <ShowIcon custom sideMenuIcon icon="menuDashboard" />,
    component: DashboardPage,
    sidebar: true,
    position: 'top',
    title: 'Dashboard'
  },
  {
    path: '/quicksearch/:searchTerm',
    navbarName: withRouter((props) => `Botium Search for "${props.match.params.searchTerm}"`),
    breadcrumb: 'Botium Search',
    component: QuickSearch
  },
  //Test Suite
  {
    path: '/nlp',
    sidebarName: 'NLP',
    navbarName: 'NLP',
    uniqueName: 'NLP',
    icon: <ShowIcon custom sideMenuIcon sideDropDownMenuIcon icon="menuNlp" />,
    component: TestProjects,
    sidebar: true,
    category: 'Test Suite',
    title: 'NLP',
    dropdownName: 'nlp'
  },
  {
    path: '/nlp/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId || props.match.params.testProjectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: (props.match.params.projectId || props.match.params.testProjectId)}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
          <>
          <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/nlp/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
              {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip nlp tooltip={'Test Type'} variant="info" label="NLP"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId || props.match.params.testProjectId) && <Query query={TESTPROJECT_QUERY} variables={{id: (props.match.params.projectId || props.match.params.testProjectId)}}>{(queryResult) => (queryResult.data && queryResult.data.testproject && queryResult.data.testproject.name) || null}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `nlp_projects_view_${props.match && props.match.params && (props.match.params.projectId || props.match.params.testProjectId)}`
  },
  {
    path: '/nlp/projects/view/:projectId/results/:testSessionId',
    component: CoachDashboardEntry,
    checkLicense: (license) => license.coach,
    navbarName: withRouter((props) => (props.match.params.testSessionId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.testSessionId}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/nlp/projects/view/${data.testsession.testProject.id}/results/${data.testsession.id}`, type: 'TestSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <Chip nlp tooltip={'Test Type'} variant="info" label="NLP"/><NlpSessionProgressIcon testSession={data.testsession} withoutNavLink={true} />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.testSessionId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.testSessionId}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    keyFunc: (props) => `nlp_results_view_${props.match && props.match.params && props.match.params.testSessionId}`,
  },
  {
    path: '/nlp/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  {
    href: `${config.api.base}/qbox`,
    checkFeatures: (features) => features && features.qboxLoginBaseHref,
    checkUser: (user) => user && user.roles && user.roles.find(r => r.name !== 'GUEST'),
    sidebarName: 'NLP Advanced',
    navbarName: 'NLP Advanced',
    uniqueName: 'NLP Advanced',
    icon: <ShowIcon sideMenuIcon sideDropDownMenuIcon icon="fa-external-link"/>,
    sidebar: true,
    category: 'Test Suite',
    title: 'NLP Advanced',
    dropdownChild: 'nlp'
  },
  //start E2E
  {
    path: '/e2e',
    sidebarName: 'E2E',
    navbarName: 'E2E',
    uniqueName: 'E2E',
    icon: <ShowIcon custom sideMenuIcon sideDropDownMenuIcon icon="menuE2e" />,
    component: TestProjects,
    sidebar: true,
    category: 'Test Suite',
    title: 'E2E',
  },
  {
    path: '/e2e/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
      <>
        <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/e2e/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
          <GridContainer>
            <GridItem md={12} largePaddingTop>
              {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip e2e tooltip={'Test Type'} variant="info" label="E2E"/>
            </GridItem>
          </GridContainer>
      </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `e2e_projects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/e2e/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/e2e/projects/view/${data.testsession.testProject.id}/results/${data.testsession.id}`, type: 'TestSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <TestSessionRegressionE2ETypeChip ts={data.testsession}/><TestSessionProgressIcon testSession={data.testsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.projectId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: TestSession,
    keyFunc: (props) => `e2e_results_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/e2e/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  // end e2e
  // livechat old. delete it
  /*{
    path: '/testprojects/view/:id/livechat',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.id}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.id}`
  },*/
  // delete it
 /* {
    path: '/testprojects/view/:id',
    navbarName: withRouter((props) => ((props.match.params.id) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.id}}>{(queryResult) => <QueryStatus {...queryResult} query="testproject">{(data) => <><NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/testprojects/view/${data.testproject.id}`, type: 'TestProject' }}/><div>{data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}</div></>}</QueryStatus>}</Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.id) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.id}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `testprojects_view_${props.match && props.match.params && props.match.params.id}`
  },*/
  /*{
    path: '/llm',
    navbarName: 'LLM',
    breadcrumb: 'LLM',
    component: LLMprojects,
    dropdownChild: 'llm',
    dropdown: true,
  },*/
  {
    path: '/factcheck/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={FACTCHECKERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="factcheckerproject">{(data) =>
          <>
            <NavbarHeader text={data.factcheckerproject.name} putRecentArgs={{ name: data.factcheckerproject.name, url: `/factcheck/projects/view/${data.factcheckerproject.id}`, type: 'FactCheckerProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.factcheckerproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.factcheckerproject.namespace}`} label={data.factcheckerproject.namespace} />}<Chip factchecker tooltip={'Test Type'} variant="info" label="FACTCHECK"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={FACTCHECKERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.factcheckerproject ? `${data.factcheckerproject.name}` : 'FactCheck Project'}</Query>) || null),
    component: FactCheckerProject,
    keyFunc: (props) => `factcheckerprojects_view_${props.match && props.match.params && props.match.params.projectId}`
  },
  {
    onclick: ({showFactCheckerPopup}) => {showFactCheckerPopup(true)},
    checkFeatures: (features) => !features || !features.factCheckerEnabled,
    sidebarName: () => <div><span>FactCheck</span></div>,
    navbarName: 'FactCheck',
    breadcrumb: 'FactCheck',
    icon: <ShowIcon custom sideMenuIcon sideDropDownMenuIcon icon="menuFactCheck" />,
    component: FactCheckerProjects,
    checkLicense: (license) => true,
    sidebar: true,
    category: 'AI Trust Suite',
    title: 'FactCheck',
    //dropdownChild: 'llm',
    //dropdown: true,
    keyFunc: (props) => `factcheckerprojects_view_${props.match && props.match.params && props.match.params.id}`

  },
  {
    path: '/factcheck',
    checkFeatures: (features) => features && features.factCheckerEnabled,
    sidebarName: () => <div><span>FactCheck</span></div>,
    navbarName: 'FactCheck',
    breadcrumb: 'FactCheck',
    icon: <ShowIcon custom sideMenuIcon sideDropDownMenuIcon icon="menuFactCheck" />,
    component: FactCheckerProjects,
    checkLicense: (license) => true,
    sidebar: true,
    category: 'AI Trust Suite',
    title: 'FactCheck',
    //dropdownChild: 'llm',
    //dropdown: true,
    keyFunc: (props) => `factcheckerprojects_view_${props.match && props.match.params && props.match.params.id}`

  },
  // alternative mapping for factcheck projects. Show them in regression test mode. Required just as parent breadcrumb of /factcheckregression/projects/view/:projectId/results/:id?
  {
    path: '/factcheckregression/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={FACTCHECKERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="factcheckerproject">{(data) =>
          <>
            <NavbarHeader text={data.factcheckerproject.name} putRecentArgs={{ name: data.factcheckerproject.name, url: `/factcheck/projects/view/${data.factcheckerproject.id}`, type: 'FactCheckerProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.factcheckerproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.factcheckerproject.namespace}`} label={data.factcheckerproject.namespace} />}<Chip factchecker tooltip={'Test Type'} variant="info" label="FACTCHECK"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={FACTCHECKERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.factcheckerproject ? `${data.factcheckerproject.name}` : 'FactCheck Project'}</Query>) || null),
    component: FactCheckerProject,
    keyFunc: (props) => `factcheckerprojects_view_${props.match && props.match.params && props.match.params.projectId}`
  },
  // alternative mapping for results. Show them for factcheck
  {
    path: '/factcheckregression/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) => {
        return <GridContainer>
          <GridItem lg={12}>
          <NavbarHeader text={(data.testsession?.factCheckerSession?.project?.name || 'Fact checker project') + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession?.factCheckerSession?.project?.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/factcheckregression/projects/view/${data.testsession?.factCheckerSession?.project?.id}/results/${data.testsession.id}`, type: 'TestSession'}}/>            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <TestSessionRegressionE2ETypeChip ts={data.testsession}/><TestSessionProgressIcon testSession={data.testsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: TestSession,
    keyFunc: (props) => `regression_results_view_${props.match && props.match.params && props.match.params.id}`
  },

  {
    path: '/misusecheck/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) && <Query query={MISUSECHECKERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) => <QueryStatus {...queryResult} query="misusecheckerproject">{(data) => <><NavbarHeader text={data.misusecheckerproject.name} putRecentArgs={{ name: data.misusecheckerproject.name, url: `/misusecheck/projects/view/${data.misusecheckerproject.id}`, type: 'MisuseCheckerProject' }}/><div>{data.misusecheckerproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.misusecheckerproject.namespace}`} label={data.misusecheckerproject.namespace} />}<Chip misusechecker tooltip={'Test Type'} variant="info" label="MISUSECHECKER"/></div></>}</QueryStatus>}</Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={MISUSECHECKERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.misusecheckerproject ? `${data.misusecheckerproject.name}` : 'LLM Misuse Checker Project'}</Query>) || null),
    component: MisuseCheckerProject,
    keyFunc: (props) => `misusecheckerprojects_view_${props.match && props.match.params && props.match.params.projectId}`
  },
  {
    path: '/misusecheck',
    checkFeatures: (features) => features && features.misuseCheckerEnabled,
    sidebarName: () => <div><span>Misuse Test</span></div>,
    navbarName: 'Misuse Test',
    breadcrumb: 'Misuse Test',
    icon: <ShowIcon custom sideMenuIcon sideDropDownMenuIcon icon="menuMisuseCheck" />,
    component: MisuseCheckerProjects,
    checkLicense: (license) => true,
    sidebar: true,
    category: 'AI Trust Suite',
    title: 'Misuse Test',
    keyFunc: (props) => `misusecheckerprojects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/llm',
    sidebarName: () => <div><span>LLM Projects</span></div>,
    uniqueName: 'LLM',
    navbarName: 'LLM',
    icon: <ShowIcon custom sideMenuIcon sideDropDownMenuIcon icon="menuLlm" />,
    component: LLMprojects,
    //checkLicense: (license) => license.crawler,
    // checkPermission: 'TESTPROJECTS_SELECT',
    sidebar: true,
    checkPermission: 'TESTPROJECTS_SELECT',
    //category: 'Test Suite',
    dropdownName: 'llm',
    title: 'LLM   ',
  },
  // Do not delete it, it is still used from Quicksearch
 /* {
    path: '/testprojects',
    sidebarName: () => <Query query={TESTPROJECTS_COUNT_QUERY}>{({data}) => (data ? <Tooltip title={`${data.testprojectsCount || 0} Test Projects(s)`}><span>Test Projects</span></Tooltip> : 'Test Projects')}</Query>,
    navbarName: 'Test Projects',
    uniqueName: 'TestProjects',
    icon: <ShowIcon icon="infinity" style={{ fontSize: 18 }} />,
    component: TestProjects,
    sidebar: true,
    checkPermission: 'TESTPROJECTS_SELECT',
    //category: 'Test Suite',
    title: 'Test Projects',
  },*/
  // start regression
  {
    path: '/regression',
    sidebarName: 'Regression',
    navbarName: 'Regression',
    uniqueName: 'Regression',
    icon: <ShowIcon custom sideMenuIcon icon="menuRegression" />,
    component: TestProjects,
    sidebar: true,
    checkPermission: '',
    category: 'Test Suite',
    title: 'Regression',
  },
  {
    path: '/regression/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
          <>
            <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/regression/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip regression tooltip={'Test Type'} variant="info" label="REGRESSION"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `e2e_projects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/regression/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/regression/projects/view/${data.testsession.testProject.id}/results/${data.testsession.id}`, type: 'TestSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <TestSessionRegressionE2ETypeChip ts={data.testsession}/><TestSessionProgressIcon testSession={data.testsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: TestSession,
    keyFunc: (props) => `regression_results_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/regression/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  // end regression
  // start performance
  {
    path: '/performance',
    sidebarName: 'Performance',
    navbarName: 'Performance',
    uniqueName: 'Performance',
    icon: <ShowIcon custom sideMenuIcon icon="menuPerformance" />,
    component: TestProjects,
    sidebar: true,
    checkPermission: '',
    category: 'Test Suite',
    title: 'Performance',
  },
  {
    path: '/performance/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
          <>
            <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/performance/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip performance tooltip={'Test Type'} variant="info" label="PERFORMANCE"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `performance_projects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/performance/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={PERFORMANCETESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="performancetestsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.performancetestsession.testProject.name + ' / ' + moment(data.performancetestsession.updatedAt).format('lll')} putRecentArgs={{name: data.performancetestsession.testProject.name + ' / ' + moment(data.performancetestsession.updatedAt).format('lll'), url: `/performance/projects/view/${data.performancetestsession.testSession.testProjectId}/results/${data.performancetestsession.id}`, type: 'PerformanceTestSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.performancetestsession.testSession && data.performancetestsession.testSession.namespace && <Chip namespace href={`/namespaces/${data.performancetestsession.testSession.namespace}`}  tooltip={`Namespaces: ${data.performancetestsession.testSession.namespace}`} variant="info" label={data.performancetestsession.testSession.namespace} />}
                <Chip performance tooltip={'Test Type'} variant="info" label="PERFORMANCE"/><PerformanceTestSessionProgressPolling performancetestsession={data.performancetestsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.projectId && <Query query={PERFORMANCETESTSESSION_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.performancetestsession ? `${data.performancetestsession.name}` : 'Test Results'}</Query>) || null),
    component: PerformanceTestSession,
    keyFunc: (props) => `performancetestsessions_results_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/performance/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  // end perforamnce
  // start security
  {
    path: '/security',
    sidebarName: 'Security',
    navbarName: 'Security',
    uniqueName: 'Security',
    icon: <ShowIcon custom sideMenuIcon icon="menuSecurity" />,
    component: TestProjects,
    sidebar: true,
    checkPermission: '',
    category: 'Test Suite',
    title: 'Security',
  },
  {
    path: '/security/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
          <>
            <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/security/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip security tooltip={'Test Type'} variant="info" label="SECURITY"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `security_projects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/security/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/security/projects/view/${data.testsession.testProject.id}/results/${data.testsession.id}`, type: 'SecuritySession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
              {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
              <Chip security tooltip={'Test Type'} variant="info" label="SECURITY"/><SecuritySessionProgressIcon testSession={data.testsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.projectId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: SecuritySession,
    keyFunc: (props) => `securitysessions_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/security/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  // end security

  // start gdpr
  {
    path: '/gdpr',
    sidebarName: 'GDPR',
    navbarName: 'GDPR',
    uniqueName: 'GDPR',
    icon: <ShowIcon custom sideMenuIcon icon="menuGdpr" />,
    component: TestProjects,
    sidebar: true,
    checkPermission: '',
    category: 'Test Suite',
    title: 'GDPR',
  },
  {
    path: '/gdpr/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
          <>
            <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/gdpr/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip gdpr tooltip={'Test Type'} variant="info" label="GDPR"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `gdpr_projects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/gdpr/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/gdpr/projects/view/${data.testsession.testProject.id}/results/${data.testsession.id}`, type: 'GDPRSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <Chip gdpr tooltip={'Test Type'} variant="info" label="GDPR"/><GDPRSessionProgressIcon testSession={data.testsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.projectId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: GDPRSession,
    keyFunc: (props) => `gdprsessions_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/gdpr/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  // end gdpr
   // start logmonitoring
  {
    path: '/scheduledtests/projects/view/:projectId',
    navbarName: withRouter((props) => ((props.match.params.projectId) &&
      <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) =>
        <QueryStatus {...queryResult} query="testproject">{(data) =>
          <>
            <NavbarHeader text={data.testproject.name} putRecentArgs={{ name: data.testproject.name, url: `/logmonitoring/projects/view/${data.testproject.id}`, type: 'TestProject' }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testproject.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testproject.namespace}`} label={data.testproject.namespace} />}<Chip logmonitoring tooltip={'Test Type'} variant="info" label="SCHEDULED"/>
              </GridItem>
            </GridContainer>
          </>}
        </QueryStatus>}
      </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testproject ? `${data.testproject.name}` : 'Test Project'}</Query>) || null),
    component: TestProject,
    keyFunc: (props) => `scheduledtests_projects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/scheduledtests/projects/view/:projectId/livechat',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={TESTPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => <NavbarHeader text={data && data.testproject ? `${data.testproject.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestProjectLiveChat,
    keyFunc: (props) => `testprojects_livechat_${props.match && props.match.params && props.match.params.projectId}`
  },
  // end logmonitoring
  {
    path: '/factcheck/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.factCheckerSession.project.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.factCheckerSession.project.name + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/factcheck/projects/view/${data.testsession.factCheckerSession.project.id}/results/${data.testsession.id}`, type: 'FactCheckerSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <Chip factchecker tooltip={'Test Type'} variant="info" label="FACTCHECK"/><FactSessionProgressIcon testSession={data.testsession} withoutNavLink={true}/>
              </GridItem>
            </GridContainer>
          </GridItem>

        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.projectId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: FactCheckerSession,
    keyFunc: (props) => `factcheckersessions_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/misusecheck/projects/view/:projectId/results/:id',
    navbarName: withRouter((props) => (props.match.params.id && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="testsession">{(data) =>
        <GridContainer>
          <GridItem lg={12}>
            <NavbarHeader text={data.testsession.misuseCheckerSession.project.name + ' / ' + moment(data.testsession.updatedAt).format('lll')} putRecentArgs={{name: data.testsession.misuseCheckerSession.project.name  + ' / ' + moment(data.testsession.updatedAt).format('lll'), url: `/misuse/projects/view/${data.testsession.misuseCheckerSession.project.id}/results/${data.testsession.id}`, type: 'MisuseCheckerSession'}}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
                <MisuseCheckerSessionProgressIcon testSession={data.testsession} withoutNavLink={true}/> <Chip misusechecker tooltip={'Test Type'} variant="info" label="MISUSECHECKER"/>
              </GridItem>
            </GridContainer>
          </GridItem>

        </GridContainer>}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => (props.match.params.projectId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.testsession ? `${moment(data.testsession.updatedAt).format('lll')}` : 'Test Results'}</Query>) || null),
    component: MisuseCheckerSession,
    keyFunc: (props) => `misusecheckersessions_view_${props.match && props.match.params && props.match.params.id}`
  },
  ...coachRoutes.map(r => ({
    ...r,
    navbarName: r.navbarName || r.path,
    component: CoachDashboardEntry
  })),
  {
    path: '/chatbots/view/:chatbotId/utteranceinsights',
    navbarName: 'Utterance Analytics',
    component: UtteranceInsightsPage
  },
  {
    path: '/chatbots/view/:id/livechat',
    navbarName: 'Live Chat',
    component: ChatbotLiveChat,
    keyFunc: (props) => `chatbots_livechat_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/chatbots/view/:id',
    navbarName: withRouter((props) => ((props.match.params.id || props.match.params.chatbotId) && <Query query={CHATBOT_QUERY} variables={{id: (props.match.params.id || props.match.params.chatbotId)}}>{(queryResult) => <QueryStatus {...queryResult} query="chatbot">{(data) => <><NavbarHeader text={data.chatbot.name} putRecentArgs={{ name: data.chatbot.name, url: `/chatbots/view/${data.chatbot.id}`, type: 'Chatbot' }}/><div>{data.chatbot.namespace && <Chip namespace variant="info" href={`/namespaces/${data.chatbot.namespace}`}  tooltip={`Namespaces: ${data.chatbot.namespace}`} label={data.chatbot.namespace} />}</div></>}</QueryStatus>}</Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.id || props.match.params.chatbotId) && <Query query={CHATBOT_QUERY} variables={{id: (props.match.params.id || props.match.params.chatbotId)}}>{({data}) => data && data.chatbot ? `${data.chatbot.name}` : 'Chatbot'}</Query>) || null),
    component: Chatbot,
    keyFunc: (props) => `chatbots_view_${props.match && props.match.params && (props.match.params.id || props.match.params.chatbotId)}`
  },
  {
    path: '/chatbots',
    sidebarName: () => <Query query={CHATBOTS_COUNT_QUERY}>{({data}) => (data ? <Tooltip title={`${data.chatbotsCount || 0} Chatbot(s)`}><span>Chatbot Connections</span></Tooltip> : 'Chatbots')}</Query>,
    uniqueName: 'Chatbots',
    navbarName: 'Chatbot Connections',
    icon: <ShowIcon custom  sideMenuIcon icon="menuChatbots" />,
    component: Chatbots,
    sidebar: true,
    checkPermission: 'CHATBOTS_SELECT',
    category: 'Botium Tools & Settings',
    title: 'Chatbot Connections'
  },
  {
    path: '/testdatawizards/crawlerprojects/view/:projectId/history/:id',
    navbarName: withRouter((props) => ((props.match.params.id) && <Query query={CRAWLERSESSION_QUERY} variables={{id: props.match.params.id}}>
      {(queryResult) => <QueryStatus {...queryResult} query="crawlersession">{(data) =>
        <GridContainer>
          <GridItem>
            <CrawlerSessionIcon crawlerSession={data.crawlersession}/>
          </GridItem>
          <GridItem lg={11}>
            <NavbarHeader text={data.crawlersession.crawlerProject.name} putRecentArgs={{
              name: data.crawlersession.name,
              url: `/testdatawizards/crawlerprojects/view/${data.crawlersession.crawlerProject.id}/results/${data.crawlersession.id}`,
              type: 'CrawlerSession'
            }}/>
            <GridContainer>
              <GridItem md={12} largePaddingTop>
                {data.crawlersession.crawlerProject.namespace &&
                  <Chip namespace variant="info" href={`/namespaces/${data.crawlersession.crawlerProject.namespace}`}
                        tooltip={`Namespaces: ${data.crawlersession.crawlerProject.namespace}`}
                        label={data.crawlersession.crawlerProject.namespace}/>}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>}
      </QueryStatus>}</Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.id) && <Query query={CRAWLERSESSION_QUERY} variables={{id: props.match.params.id}}>{({data}) => data && data.crawlersession ? `${data.crawlersession.name}` : 'Crawler Project'}</Query>) || null),
    component: CrawlerSession,
    checkLicense: (license) => license.crawler,
    keyFunc: (props) => `crawlersessions_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/testdatawizards/crawlerprojects/view/:projectId/history',
    navbarName: withRouter((props) => ((props.match.params.projectId) && <Query query={CRAWLERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{(queryResult) => <QueryStatus {...queryResult} query="crawlerproject">{(data) => <><NavbarHeader text={`${data.crawlerproject.name} - History`}/><div>{data.crawlerproject.namespace && <Chip namespace variant="info" label={data.crawlerproject.namespace} />}</div></>}</QueryStatus>}</Query>) || null),
    breadcrumb: 'History',
    component: CrawlerSessionHistory,
    checkLicense: (license) => license.crawler,
    keyFunc: (props) => `crawlersessions_history_${props.match && props.match.params && props.match.params.projectId}`
  },
  {
    path: '/testdatawizards/crawlerprojects/view/:projectId',
    navbarName: withRouter((props) => (props.match.params.projectId && <Query query={CRAWLERSESSIONS_QUERY} variables={{crawlerProjectId: props.match.params.projectId, skip: 0, first: 1, orderBy: 'createdAt_DESC'}}>
      {(queryResult) => <QueryStatus {...queryResult} query="crawlersessions">{(data) => {
        if(data && data.crawlersessions && data.crawlersessions[0] && data.crawlersessions[0].crawlerProject) {
          return (<GridContainer>
            <GridItem>
              <CrawlerSessionIcon crawlerSession={data.crawlersessions[0]}/>
            </GridItem>
            <GridItem lg={11}>
              <NavbarHeader text={data.crawlersessions[0].crawlerProject.name} putRecentArgs={{
                name: data.crawlersessions[0].crawlerProject.name,
                url: `/testdatawizards/crawlerprojects/view/${data.crawlersessions[0].crawlerProject.id}`,
                type: 'CrawlerProject'
              }}/>
              <GridContainer>
                <GridItem md={12} largePaddingTop>
                  {data.crawlersessions[0].crawlerProject.namespace &&
                    <Chip namespace variant="info" href={`/namespaces/${data.crawlersessions[0].crawlerProject.namespace}`}
                          tooltip={`Namespaces: ${data.crawlersessions[0].crawlerProject.namespace}`}
                          label={data.crawlersessions[0].crawlerProject.namespace}/>}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>)
        } else {
          return (<Query query={CRAWLERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>
            {(queryResult) => <QueryStatus {...queryResult} query="crawlerproject">{(data) =>
              <GridContainer>
                <GridItem lg={12}>
                  <NavbarHeader text={data.crawlerproject.name} putRecentArgs={{
                    name: data.crawlerproject.name,
                    url: `/testdatawizards/crawlerprojects/view/${data.crawlerproject.id}`,
                    type: 'CrawlerProject'
                  }}/>
                  <GridContainer>
                    <GridItem md={12} largePaddingTop>
                      {data.crawlerproject.namespace &&
                        <Chip namespace variant="info" href={`/namespaces/${data.crawlerproject.namespace}`}
                              tooltip={`Namespaces: ${data.crawlerproject.namespace}`}
                              label={data.crawlerproject.namespace}/>}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>}
            </QueryStatus>}
          </Query>)
        }
        }}
      </QueryStatus>}
    </Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.projectId) && <Query query={CRAWLERPROJECT_QUERY} variables={{id: props.match.params.projectId}}>{({data}) => data && data.crawlerproject ? `${data.crawlerproject.name}` : 'Crawler Project'}</Query>) || null),
    component: CrawlerSession,
    checkLicense: (license) => license.crawler,
    keyFunc: (props) => `crawlerprojects_view_${props.match && props.match.params && props.match.params.id}`
  },
  {
    path: '/testdatawizards/crawlerprojects',
    sidebarName: () => <Query query={CRAWLERPROJECTS_QUERY}>{({data}) => (data && data.crawlerprojects ? <Tooltip title={`${data.crawlerprojects.length} Crawler Projects(s)`}><span>Crawler Projects</span></Tooltip> : 'Crawler Projects')}</Query>,
    navbarName: 'Crawler Projects',
    uniqueName: 'CrawlerProjects',
    icon: <ShowIcon icon="sitemap" style={{ fontSize: 18 }} />,
    component: CrawlerProjects,
    checkLicense: (license) => license.crawler,
    checkPermission: 'CRAWLERPROJECTS_SELECT',
    category: 'remove',
    title: 'Crawler Projects'
  },
  {
    path: '/testsets/view/:testSetId/livechat/:chatbotId',
    navbarName: withRouter((props) => (props.match.params.chatbotId && <Query query={CHATBOT_QUERY} variables={{id: props.match.params.chatbotId}}>{({data}) => <NavbarHeader text={data && data.chatbot ? `${data.chatbot.name}` : 'Live Chat'} />}</Query>) || null),
    component: TestSetLiveChat,
    keyFunc: (props) => `testsets_chatbot_livechat_${props.match && props.match.params && (`${props.match.params.testSetId}_${props.match.params.chatbotId}`)}`
  },
  {
    path: '/testsets/view/:testSetId/utteranceinsights',
    navbarName: 'Utterance Analytics',
    component: UtteranceInsightsPage
  },
  {
    path: '/testsets/view/:id/testcases/upload/:chatbotId',
    navbarName: withRouter((props) => (props.match.params.chatbotId && <Query query={CHATBOT_QUERY} variables={{id: props.match.params.chatbotId}}>{({data}) => <NavbarHeader text={data && data.chatbot ? `${data.chatbot.name}` : 'Chatbot'} />}</Query>) || null),
    component: TestSetUpload
  },
  {
    path: '/testsets/view/:id/testcases/upload',
    navbarName: 'Export Conversation Model',
    component: TestSetUploadChatbotSelector
  },
  {
    path: '/testsets/view/:id/testcases/viewutterance/:scriptId/:scriptName/paraphraser',
    navbarName: 'Paraphraser',
    component: TestSetParaphraserSuggestions.FromUtterance
  },
  {
    path: '/testsets/view/:id/testcases/viewscript/:scriptId/:scriptName/paraphraser',
    navbarName: 'Paraphraser',
    component: TestSetParaphraserSuggestions.FromScript
  },
  {
    path: '/testsets/view/:id',
    navbarName: withRouter((props) => ((props.match.params.id || props.match.params.testSetId) && <Query query={TESTSET_QUERY} variables={{id: (props.match.params.id || props.match.params.testSetId)}}>{(queryResult) => <QueryStatus {...queryResult} query="testset">{(data) => <><NavbarHeader text={data.testset.name} putRecentArgs={{ name: data.testset.name, url: `/testsets/view/${data.testset.id}`, type: 'TestSet' }}/><div>{data.testset.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testset.namespace}`}  tooltip={`Namespaces: ${data.testset.namespace}`} label={data.testset.namespace} />}</div></>}</QueryStatus>}</Query>) || null),
    breadcrumb: withRouter((props) => ((props.match.params.id || props.match.params.testSetId) && <Query query={TESTSET_QUERY} variables={{id: (props.match.params.id || props.match.params.testSetId)}}>{({data}) => data && data.testset ? `${data.testset.name}` : 'Test Set'}</Query>) || null),
    component: TestSet,
    keyFunc: (props) => `testsetss_view_${props.match && props.match.params && (props.match.params.id || props.match.params.testSetId)}`
  },
  {
    path: '/testsets',
    sidebarName: () => <Query query={TESTSETS_COUNT_QUERY}>{({data}) => (data ? <Tooltip title={`${data.testsetsCount || 0} Test Set(s)`}><span>Test Sets</span></Tooltip> : 'Test Sets')}</Query>,
    uniqueName: 'TestSets',
    navbarName: 'Test Sets',
    icon: <ShowIcon custom  sideMenuIcon icon="menuTestsets" />,
    component: TestSets,
    sidebar: true,
    checkPermission: 'TESTSETS_SELECT',
    category: 'Botium Tools & Settings',
    title: 'Test Sets'
  },
  {
    path: '/testdatawizards/aisimple',
    navbarName: 'AI-powered Data Generator',
    component: AITestDataWizard
  },
  {
    path: '/testdatawizards/ainlu',
    navbarName: 'AI-powered Test Generator',
    component: AITestDataWizardNlu
  },
  {
    path: '/testdatawizards/nlpdownload/:chatbotId',
    navbarName: withRouter((props) => (props.match.params.chatbotId && <Query query={CHATBOT_QUERY} variables={{id: props.match.params.chatbotId}}>{({data}) => <NavbarHeader text={data && data.chatbot ? `${data.chatbot.name}` : 'Chatbot'} />}</Query>) || null),
    component: NLPDownloadWizard
  },
  {
    path: '/testdatawizards/nlpdownload',
    navbarName: 'Conversation Model Downloader',
    component: NLPDownloadChatbotSelector
  },
  {
    path: '/testdatawizards/fileupload',
    navbarName: 'Import from File',
    component: FileUploadWizard
  },
  {
    path: '/testdatawizards/translator/:testSetId',
    navbarName: withRouter((props) => (props.match.params.testSetId && <Query query={TESTSET_QUERY} variables={{id: props.match.params.testSetId}}>{({data}) => <NavbarHeader text={data && data.testset ? `${data.testset.name}` : 'Test Set'}/>}</Query>) || null),
    component: TranslatorWizard
  },
  {
    path: '/testdatawizards/translator',
    navbarName: 'Test Set Translator',
    component: TranslatorTestSetSelector
  },
  {
    path: '/testdatawizards/humanification/:testSetId',
    navbarName: withRouter((props) => (props.match.params.testSetId && <Query query={TESTSET_QUERY} variables={{id: props.match.params.testSetId}}>{({data}) => <NavbarHeader text={data && data.testset ? `${data.testset.name}` : 'Test Set'}/>}</Query>) || null),
    component: HumanificationWizard
  },
  {
    path: '/testdatawizards/humanification',
    navbarName: 'Humanification Transformer',
    component: HumanificationTestSetSelector
  },
  {
    path: '/testdatawizards/paraphraser/:testSetId/pview/:scriptId/:scriptName',
    navbarName: withRouter((props) => <NavbarHeader text={props.match.params.scriptName || null} />),
    component: ParaphraserWizardSuggestions
  },
  {
    path: '/testdatawizards/paraphraser/:testSetId/uview/:scriptId/:scriptName',
    component: ParaphraserWizard
  },
  {
    path: '/testdatawizards/paraphraser/:testSetId',
    navbarName: withRouter((props) => (props.match.params.testSetId && <Query query={TESTSET_QUERY} variables={{id: props.match.params.testSetId}}>{({data}) => <NavbarHeader text={data && data.testset ? `${data.testset.name}` : 'Test Set'}/>}</Query>) || null),
    component: ParaphraserWizard
  },
  {
    path: '/testdatawizards/paraphraser',
    navbarName: 'Paraphraser',
    component: ParaphraserTestSetSelector
  },
  {
    path: '/testdatawizards/splitter/:testSetId',
    navbarName: withRouter((props) => (props.match.params.testSetId && <Query query={TESTSET_QUERY} variables={{id: props.match.params.testSetId}}>{({data}) => <NavbarHeader text={data && data.testset ? `${data.testset.name}` : 'Test Set'}/>}</Query>) || null),
    component: SplitterWizard
  },
  {
    path: '/testdatawizards/splitter',
    navbarName: 'Test Set Splitter',
    component: SplitterTestSetSelector
  },
  {
    path: '/testdatawizards/livechat/:chatbotId',
    navbarName: withRouter((props) => (props.match.params.chatbotId && <Query query={CHATBOT_QUERY} variables={{id: props.match.params.chatbotId}}>{({data}) => <NavbarHeader text={data && data.chatbot ? `${data.chatbot.name}` : 'Chatbot'} />}</Query>) || null),
    component: LiveChatWizard
  },
  {
    path: '/testdatawizards/livechat',
    navbarName: 'Live Chat Recorder',
    component: LiveChatChatbotSelector
  },
  {
    path: '/testdatawizards/voice/:testSetId',
    navbarName: withRouter((props) => (props.match.params.testSetId && <Query query={TESTSET_QUERY} variables={{id: props.match.params.testSetId}}>{({data}) => <NavbarHeader text={data && data.testset ? `${data.testset.name}` : 'Test Set'}/>}</Query>) || null),
    component: VoiceWizard
  },
  {
    path: '/testdatawizards/voice',
    navbarName: 'Voice Wizard',
    component: VoiceTestSetSelector
  },
  {
    path: '/testdatawizards',
    sidebarName: 'Test Data Wizard',
    uniqueName: 'TestDataWizard',
    navbarName: 'Test Data Wizard',
    icon: <ShowIcon custom  sideMenuIcon icon="menuTestDataWizard" />,
    component: TestDataWizards,
    sidebar: true,
    category: 'Botium Tools & Settings',
    title: 'Test Data Wizard'
  },
  {
    path: '/settings',
    sidebarName: 'Settings',
    uniqueName: 'Settings',
    navbarName: 'Settings',
    icon: <ShowIcon custom sideMenuIcon icon="menuSettings" />,
    sidebar: true,
    component: Settings,
    category: 'Botium Tools & Settings',
    title: 'Settings',
  },
  {
    href: 'https://support.botium.ai',
    sidebarName: 'Knowledge Center',
    uniqueName: 'Wiki',
    navbarName: 'Knowledge Center',
    icon: <ShowIcon custom  sideMenuIcon icon="menuKnowledge"  />,
    sidebar: true,
    category: 'Botium Tools & Settings',
    title: 'Knowledge Center',
  },
  {
    path: '/logout',
    sidebarName: 'Logout',
    uniqueName: 'Logout',
    navbarName: 'Logout',
    icon: <ShowIcon custom sideMenuIcon icon="menuLogoutIcon" />,
    sidebar: true,
    component: Settings,
    category: 'Botium Tools & Settings',
    title: 'Logout',
  },
  {
    path: '/namespaces/:namespace',
    navbarName: withRouter((props) => ((props.match.params.namespace) && <NavbarHeader text={props.match.params.namespace} putRecentArgs={{ name: props.match.params.namespace, url: `/namespaces/${props.match.params.namespace}`, type: 'Namespace' }}/>) || null),
    component: NamespaceOverview,
    keyFunc: (props) => `namespace_${props.match && props.match.params && props.match.params.namespace}`
  },
  {
    path: '/namespaces',
    navbarName: 'Namespaces',
    component: () => <></>,
  },
  {
    path: '/settings/agents/:id',
    navbarName: 'Botium Agents',
    breadcrumb: false,
    component: Settings
  },
  {
    path: '/settings/agents',
    navbarName: 'Botium Agents',
    component: Settings
  },
  ...[
    '/settings/security/users',
    '/settings/security/userroles',
    '/settings/security/namespacepermissions',
    '/settings/security/apikeys'
  ].reduce((agg, p) => [...agg, p, `${p}/:id`], []).map(p => ({
    path: p,
    breadcrumb: false,
    navbarName: 'Users & Permissions',
    component: Settings
  })),
  {
    path: '/settings/security',
    navbarName: 'Users & Permissions',
    component: Settings
  },
  ...[
    '/settings/components/registeredcomponents',
    '/settings/components/registeredaggregators'
  ].reduce((agg, p) => [...agg, p, `${p}/:id`], []).map(p => ({
    path: p,
    breadcrumb: false,
    navbarName: 'Components',
    component: Settings
  })),
  {
    path: '/settings/components',
    navbarName: 'Components',
    component: Settings
  },
  ...[
    '/settings/devicecloud/deviceproviders',
    '/settings/devicecloud/devicesets'
  ].reduce((agg, p) => [...agg, p, `${p}/:id`], []).map(p => ({
    path: p,
    breadcrumb: false,
    navbarName: 'Browser & Device Cloud',
    component: Settings
  })),
  {
    path: '/settings/devicecloud',
    navbarName: 'Browser & Device Cloud',
    component: Settings
  },
  ...[
    '/settings/voice/speechsynthesis',
    '/settings/voice/speechrecognition',
    '/settings/voice/noise'
  ].reduce((agg, p) => [...agg, p, `${p}/:id`], []).map(p => ({
    path: p,
    breadcrumb: false,
    navbarName: 'Voice Services',
    component: Settings
  })),
  {
    path: '/settings/voice',
    navbarName: 'Voice Services',
    component: Settings
  },
  {
    path: '/settings/filebrowser',
    navbarName: 'File Browser',
    component: Settings
  },
  {
    path: '/settings/system',
    navbarName: 'System Settings',
    component: Settings
  },
  {
    path: '/settings/version',
    navbarName: 'About',
    component: Settings
  },
  { redirect: true, path: '/', to: '/dashboard' }
]

export default dashboardRoutes

import React from 'react'
import {connect} from 'react-redux'
import { Query, withApollo, compose, graphql } from 'react-apollo'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import {renderCell} from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import Chip from 'components/Chip/Chip'

import {UTTERANCES_QUERY, CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_QUERY} from '../gql'
import {
  TESTSETFIRSTCOACHSESSION_QUERY,
  TESTSETCOACHSESSIONSECTION_QUERY,
  TESTSET_COMPILEDCONVOS_QUERY,
  TESTSET_COMPILEDUTTERANCES_QUERY
} from '../../TestSets/gql'
import {TESTSESSION_QUERY} from '../../TestSessions/gql'
import { getCompiledItemIconLink, getCompiledItemLink } from '../../TestSets/helper'
import {renderProgressOrError} from '../../helper'
import {UtteranceKeywords} from './components/UtteranceKeywords'
import {UtteranceSimilarityVisualisation} from './components/UtteranceSimilarityVisualisation'
import {UtterancePrediction} from './components/UtterancePrediction'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { decodeURIComponentWeak } from 'views/helper'

class Utterance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listTestSetIdToEmbeddings: null,
      listTestSetIdToEmbeddingsError: null
    }
  }


  renderValue (val, options) {
    const renderFormattedVal = (val, options) => {
      const normalizedVal = _.isNumber(val) ? val.toFixed(2) : (_.isNil(val) ? 'N/A' : val)
      if (options && options.bold) {
        return <Text info><b>{normalizedVal}</b></Text>
      } else if (options && options.important) {
        return <Text>{normalizedVal}</Text>
      } else {
        return <Text info>{normalizedVal}</Text>
      }
    }

    return <GridItem xs={6} sm={6} md={6}>{renderFormattedVal(val, options)}</GridItem>
  }

  renderOccurencesConvo() {
    const { utterances, testSessionId, classes, testProjectId } = this.props

    let progressOrError = renderProgressOrError(utterances)
    if (progressOrError) {
      return progressOrError
    }

    let list = (utterances ? utterances.trainerUtterances : []).filter(u => (u.testCaseResult && u.testCaseResult.testSetScript && u.testCaseResult.testSetScript.id) )

    if (!list.length) {
      return <Text>Can't determine Test Set Conversations, Test Set might be deleted</Text>
    }
    list = list.filter(u => u.testCaseResult.testSetScript.scriptType === 'SCRIPTING_TYPE_CONVO')
    return (
      <Table
        name={`UtteranceOccurencesConvoTable`}
        tableHeaderColor="primary"
        tableHead={['TestSet', '', 'Test Case Convo', '', 'Source', 'Expected Intent', 'Expected Entity(ies)']}
        tableData={list.map((u, rowIndex) => {
          return <Query
            query={TESTSET_COMPILEDCONVOS_QUERY}
            variables={{
              testSetId: u.testCaseResult.testSet.id,
              testSetScriptId: u.testCaseResult.testSetScript.id
            }}
            fetchPolicy={'network-only'}
            key={rowIndex}
          >
            {({error, data, loading}) => {
              if (error) {
                return [<TableRow key={`TableRowOccurencesConvos${rowIndex}`}><TableCell colSpan={7}><ErrorFormat err={error} suppress/></TableCell></TableRow>]
              }
              if (loading) {
                return []
              }
              if (!data.testsetcompiledconvos || data.testsetcompiledconvos.length !== 1) {
                console.error(`renderOccurencesUtterances Data error in ${JSON.stringify({
                  testSetId: u.testCaseResult.testSet.id,
                  testSetScriptId: u.testCaseResult.testSetScript.id
                })} data: ${JSON.stringify(data.testsetcompiledconvos)}`)
                return [<TableRow key={`TableRowOccurencesConvos${rowIndex}`}><TableCell colSpan={7}><ErrorFormat err={'Data Error (Check Test Set)'} suppress/></TableCell></TableRow>]
              }
              const t = data.testsetcompiledconvos[0]
              return <TableRow key={`TableRowOccurencesConvos${rowIndex}`}>
                {[
                  () => <NavLink to={`/testsets/view/${u.testCaseResult.testSet.id}`}
                           data-unique={`btnUttarancesOccurencesConvoTestSet_${u.testCaseResult.testSet.name}`}>
                    {u.testCaseResult.testSet.name}
                  </NavLink>,
                  () => <>
                    {t.warnings.some(w => w.severity === 'ERROR') && <Chip
                      tooltip={t.warnings.filter(w => w.severity === 'ERROR').map(w => `${w.name}: ${w.description}`).join(' | ')}
                      variant="error"
                      label={<Text danger>{t.warnings.filter(w => w.severity === 'ERROR').length} error(s)</Text>}
                    />}
                    {t.warnings.some(w => w.severity === 'WARNING') && <Chip
                      tooltip={t.warnings.filter(w => w.severity === 'WARNING').map(w => `${w.name}: ${w.description}`).join(' | ')}
                      variant="warning"
                      label={<Text warning>{t.warnings.filter(w => w.severity === 'WARNING').length} warning(s)</Text>}
                    />}
                  </>,
                  getCompiledItemLink(u.testCaseResult.testSet.id, 'convo', t, t.name),
                  getCompiledItemIconLink(u.testCaseResult.testSet.id, 'convo', t),
                  getCompiledItemLink(u.testCaseResult.testSet.id, 'convo', t),
                  {
                    value: u.intent.expected,
                    href: `/nlp/projects/view/${testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(u.intent.expected)}`
                  },
                  u.entity.expectedNames.map(e => ({
                    value: e,
                    href: `/nlp/projects/view/${testProjectId}/results/${testSessionId}/entityconfidence/entityname/${encodeURIComponent(e)}`,
                  }))
                ].map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell + ' ' + classes.advacedTableCellLink} key={key}>
                      {renderCell(prop)}
                    </TableCell>
                  )
                })}
              </TableRow>
            }}
          </Query>
        })}
      />
    )
  }

  renderOccurencesUtterances() {
    const { utterances, testSessionId, classes, testProjectId } = this.props

    let progressOrError = renderProgressOrError(utterances)
    if (progressOrError) {
      return progressOrError
    }

    let list = (utterances ? utterances.trainerUtterances : []).filter(u => (u.testCaseResult && u.testCaseResult.testSetScript && u.testCaseResult.testSetScript.id) )

    if (!list.length) {
      return <Text>Can't determine Test Set Utterances, Test Set might be deleted</Text>
    }
    list = list.filter(u => u.testCaseResult.testSetScript.scriptType === 'SCRIPTING_TYPE_UTTERANCES')
    return (
      <Table
        name={`UtteranceOccurencesUtterancesTable`}
        tableHeaderColor="primary"
        tableHead={['TestSet', '', 'Utterance Name', '', 'Source', 'Expected Intent', 'Expected Entity(ies)']}
        tableData={list.map((u, rowIndex) => {
          return <Query
            query={TESTSET_COMPILEDUTTERANCES_QUERY}
            variables={{
              testSetId: u.testCaseResult.testSet.id,
              testSetScriptId: u.testCaseResult.testSetScript.id
            }}
            fetchPolicy={'network-only'}
            key={rowIndex}
          >

            {({error, data, loading}) => {
              if (error) {
                return [<TableRow key={`TableRowOccurencesUtterances${rowIndex}`}><TableCell colSpan={7}><ErrorFormat err={error} suppress/></TableCell></TableRow>]
              }
              if (loading) {
                return []
              }
              if (!data.testsetcompiledutterances || data.testsetcompiledutterances.length !== 1) {
                console.error(`renderOccurencesUtterances Data error in ${JSON.stringify({
                  testSetId: u.testCaseResult.testSet.id,
                  testSetScriptId: u.testCaseResult.testSetScript.id
                })} data: ${JSON.stringify(data.testsetcompiledconvos)}`)
                return [<TableRow key={`TableRowOccurencesUtterances${rowIndex}`}><TableCell colSpan={7}><ErrorFormat err={new Error('Data Error (Check Test Set)')} suppress/></TableCell></TableRow>]
              }
              const t = data.testsetcompiledutterances[0]

              return <TableRow key={`TableRowOccurencesUtterances${rowIndex}`}>
                {[
                  () => <NavLink to={`/testsets/view/${u.testCaseResult.testSet.id}`}
                                 data-unique={`btnUttarancesOccurencesUtterancesTestSet_${u.testCaseResult.testSet.name}`}>
                    {u.testCaseResult.testSet.name}
                  </NavLink>,
                  () => <>
                    {t.warnings.some(w => w.severity === 'ERROR') && <Chip
                      tooltip={t.warnings.filter(w => w.severity === 'ERROR').map(w => `${w.name}: ${w.description}`).join(' | ')}
                      variant="error"
                      label={<Text danger>{t.warnings.filter(w => w.severity === 'ERROR').length} error(s)</Text>}
                    />}
                    {t.warnings.some(w => w.severity === 'WARNING') && <Chip
                      tooltip={t.warnings.filter(w => w.severity === 'WARNING').map(w => `${w.name}: ${w.description}`).join(' | ')}
                      variant="warning"
                      label={<Text warning>{t.warnings.filter(w => w.severity === 'WARNING').length} warning(s)</Text>}
                    />}
                  </>,
                  getCompiledItemLink(u.testCaseResult.testSet.id, 'utterance', t, t.name),
                  getCompiledItemIconLink(u.testCaseResult.testSet.id, 'convo', t),
                  getCompiledItemLink(u.testCaseResult.testSet.id, 'utterance', t),
                  {
                    value: u.intent.expected,
                    href: `/nlp/projects/view/${testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(u.intent.expected)}`

                  },
                  u.entity.expectedNames.map(e => ({
                    value: e,
                    href: `/nlp/projects/view/${testProjectId}/results/${testSessionId}/entityconfidence/entityname/${encodeURIComponent(e)}`,
                  }))
                ].map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell + ' ' + classes.advacedTableCellLink} key={key}>
                      {renderCell(prop)}
                    </TableCell>
                  )
                })}
              </TableRow>
            }}
          </Query>
        })}
      />
    )
  }

  renderPrediction() {
    const { utterances, testSessionId, testProjectId } = this.props
    const utteranceStruct = utterances && utterances.trainerUtterances && utterances.trainerUtterances[0]
    return <Card>
      <CardBody>
        <Text header>Prediction Statistics</Text>
        {utterances.error && <ErrorFormat err={utterances.error} />}
        {!utterances.error && utterances.loading && <LoadingIndicator />}
        {!utterances.error && !utterances.loading && <UtterancePrediction
          utteranceStruct={utteranceStruct}
          testSessionId={testSessionId}
          testProjectId={testProjectId}
        />}
      </CardBody>
    </Card>
  }

  renderAnalytics() {
    const { mismatchProbability, classes, testSessionId, testProjectId } = this.props
    const list = mismatchProbability && mismatchProbability.trainerChartIntentMismatchProbabilityPerUtterance
    const entry = list && list.length && list[0]
    const bestIntent = entry && entry.actuals && entry.actuals.length && entry.actuals[0]
    const sec = entry && entry.actuals && entry.actuals.length > 1 && entry.actuals[1]

    return <Card>
      <CardBody>
        <Text header>Mismatch Probability</Text>

        {mismatchProbability.error && <ErrorFormat err={mismatchProbability.error} />}
        {!mismatchProbability.error && mismatchProbability.loading && <LoadingIndicator />}
        {!mismatchProbability.error && !mismatchProbability.loading && <>
          <GridContainer>
            {this.renderValue('Value', {important: true})}
            {this.renderValue(entry ? entry.confusionProbability : null, {important: true})}
          </GridContainer>
          &nbsp;
          <GridContainer>
            {this.renderValue('Best Prediction', {important: true})}
            {this.renderValue('')}
            {this.renderValue('Intent')}
            <GridItem xs={6} sm={6}>
              {(!bestIntent || !bestIntent.name) && 'N/A'}
              {!!bestIntent && !!bestIntent.name && <NavLink to={`/nlp/projects/view/${testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(bestIntent.name)}`} data-unique={`btnCoachUtterancePredictionIntent`}>
                <div className={classes.stats}>
                  <Text info>{bestIntent.name}</Text>
                </div>
              </NavLink>}
            </GridItem>
            {this.renderValue('Confidence')}
            {this.renderValue(bestIntent ? bestIntent.confidence : null)}
          </GridContainer>
          &nbsp;
          <GridContainer>
            {this.renderValue('Second Best Prediction', {important: true})}
            {this.renderValue('')}

            {this.renderValue('Intent')}
            <GridItem xs={6} sm={6}>
              {(!sec || !sec.name) && 'N/A'}
              {!!sec && !!sec.name && <NavLink to={`/nlp/projects/view/${testProjectId}/results/${testSessionId}/intentconfidence/intentname/${encodeURIComponent(sec.name)}`} data-unique={`btnCoachUtterancePredictionSecIntent`}>
                <div className={classes.stats}>
                  <Text info>{sec.name}</Text>
                </div>
              </NavLink>}
            </GridItem>
            {this.renderValue('Confidence')}
            {this.renderValue(sec ? sec.confidence : null)}
          </GridContainer>
        </>}
      </CardBody>
    </Card>
  }

  renderKeywords() {
    const { utterances, testSessionId } = this.props
    const { listTestSetIdToEmbeddings, listTestSetIdToEmbeddingsError } = this.state

    const err = listTestSetIdToEmbeddingsError || utterances.error
    const loading = utterances.loading || !listTestSetIdToEmbeddings

    const utterance = utterances && utterances.trainerUtterances && utterances.trainerUtterances[0].utterance
    const intent = utterance && utterance.intent && utterance.intent.actual

    return <Card>
      <CardBody>
        {intent && <Text header>Keywords of the Intent ${intent}</Text>}
        {!intent && <Text header>Keywords</Text>}

        {err && <ErrorFormat err={err} />}
        {!err && loading && <LoadingIndicator />}
        {!err && !loading && <UtteranceKeywords
          listTestSetIdToEmbeddings={listTestSetIdToEmbeddings}
          intent={intent}
          utterance={decodeURIComponentWeak(utterance)}
          testSessionId={testSessionId}
        />}
      </CardBody>
    </Card>

  }

  renderSimilarityVisualization() {
    const { listTestSetIdToEmbeddings, listTestSetIdToEmbeddingsError } = this.state

    return (<UtteranceSimilarityVisualisation
      listTestSetIdToEmbeddings={listTestSetIdToEmbeddings}
      listTestSetIdToEmbeddingsError={listTestSetIdToEmbeddingsError}
      utterance={this.state.utterance}
    />)
  }

  render() {

    return (
      <GridContainer>
        <GridItem md={12} lg={6}>
          {this.renderPrediction()}
        </GridItem>
        <GridItem md={12} lg={6}>
          {this.renderAnalytics()}
        </GridItem>
        <GridItem md={12}>
          {this.renderKeywords()}
        </GridItem>
        <GridItem md={12}>
          <Card>
            <CardBody>
              <Text header>Test Set Embeddings</Text>
              <Text subheader>This chart shows the selected Utterance (largest circle) other Utterances in the same Intent(s)       (small circles, more colors if there are more intents) and utterance of all other intents (x's)</Text>
              {this.renderSimilarityVisualization()}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={12}>
          <Card>
            <CardBody>
              <Text header>Test Set Conversations</Text>
              {this.renderOccurencesConvo()}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={12}>
          <Card>
            <CardBody>
              <Text header>Test Set Utterances</Text>
              {this.renderOccurencesUtterances()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  componentDidMount() {
    this.setState({listTestSetIdToEmbeddings: null, listTestSetIdToEmbeddingsError: null})
    this._loadEmbeddingAsync(this.props.testSession.testsession.testSets || [])
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.testSession && nextProps.testSession.testsession) {
      this.setState({listTestSetIdToEmbeddings: null, listTestSetIdToEmbeddingsError: null})
      this._loadEmbeddingAsync(nextProps.testSession.testsession.testSets || [])
    }
  }

  _loadEmbeddingAsync(testSets) {
    const {client, utterance} = this.props
    if (!testSets.length) {
      console.log('Utterance.jsx, empty testset')
    }
    Promise.all(testSets.map(async ({id, name}) => {
      const { data: sessionData } = await client.query({
        query: TESTSETFIRSTCOACHSESSION_QUERY,
        variables: {
          testSetId: id
        }
      })
      const coachSession = sessionData.testsetcoachsessions && sessionData.testsetcoachsessions.length > 0  && sessionData.testsetcoachsessions[0]

      if (!coachSession || !coachSession.embeddingsReady || coachSession.embeddingsErr) {
        return {testSetId: id, testSetName: name, noCoachSession: true}
      }

      let queryResult
      try {
        queryResult = await client.query({
          query: TESTSETCOACHSESSIONSECTION_QUERY,
          variables: {
            id: coachSession.id,
            section: 'embeddings'
          }
        })

      } catch (err) {
        console.warn(`Utterance.jsx, load empeddings failed: ${err.message}`)
        // better handle this case? Dont catch every exception?
        // Error: GraphQL error: Too less intents and utterances found in Test Set
        return {coachSession, testSetId: id, testSetName: name, noCoachSession: true}
      }
      if (queryResult.error) {
        throw Error(`Error occured while reading embeddings ${queryResult.error}`)
      }
      const { embeddings } = JSON.parse(queryResult.data.testsetcoachsessionsection)
      const utteranceFound = !!embeddings.find(e => e.examples.find(ex => ex.phrase === decodeURIComponentWeak(utterance)))
      try {
        queryResult = await client.query({
          query: TESTSETCOACHSESSIONSECTION_QUERY,
          variables: {
            id: coachSession.id,
            section: 'chi2'
          }
        })

      } catch (err) {
        console.warn(`Utterance.jsx, load empeddings failed: ${err.message}`)
        // better handle this case? Dont catch every exception?
        // Error: GraphQL error: Too less intents and utterances found in Test Set
        return {coachSession, testSetId: id, testSetName: name, noCoachSession: true}
      }
      if (queryResult.error) {
        throw Error(`Error occured while reading embeddings ${queryResult.error}`)
      }
      const { chi2 } = JSON.parse(queryResult.data.testsetcoachsessionsection)
      return {coachSession, testSetId: id, testSetName: name, embeddings, utteranceFound, chi2}
    })).then(listTestSetIdToEmbeddings => {
      this.setState({ listTestSetIdToEmbeddings, listTestSetIdToEmbeddingsError: null })
    }).catch(err => {
      this.setState({ listTestSetIdToEmbeddings: null, listTestSetIdToEmbeddingsError: err.message })
    })
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  withApollo,
  graphql(UTTERANCES_QUERY, {
    props: ({data}) => ({
      utterances: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.testSessionId,
          utterance: decodeURIComponentWeak(props.utterance),
          orderBy: 'testCaseName_ASC'
        }
      }
    }
  }),
  graphql(CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_QUERY, {
    props: ({data}) => ({
      mismatchProbability: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.testSessionId,
          utterance: decodeURIComponentWeak(props.utterance),
          intentFilter: ''
        }
      }
    }
  }),
  graphql(TESTSESSION_QUERY, {
    props: ({data}) => ({
      testSession: data,
    }),
    options: (props) => {
      return {
        variables: {
          id: props.testSessionId,
        }
      }
    }
  })

)(Utterance)
import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'
import { TRAINERSESSION_LIST_FRAGMENT } from '../TrainerSessions/gql'
import { NAMESPACES_QUERY } from '../Settings/gql'
import {TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY} from '../TestProjects/gql'

export const TESTCASERESULT_DETAILS_QUERY = gql`
  query TestCaseResultDetailsQuery($id: ID!) {
    testsessiontestcaseresult(id: $id) {
      id
      testcaseName
      testcaseSource
      success
      err
      errDetails
      testSet {
        id
        name
      }
      testSetScript {
        id
        name
        scriptType
      }
      testSetRepository {
        id
        name
      }
      testSetFolder {
        id
        name
      }
      testSetDownloadLink {
        id
        name
      }
      testSetExcel {
        id
        name
      }
      testSetFilename
      deviceSet {
        id
        name
        provider {
          id
          name
        }
      }
      deviceName
      steps {
        id
        step
        sender
        expected
        not
        actual
        stepDuration
        botDuration
        err
        errDetails
      }
      attachments {
        id
        name
        mimeType
        href
        stepIndex
      }
      testSessionJob {
        id
        jobName
      }
    }
  }
`

export const TESTSESSIONJOB_LOGS_QUERY = gql`
  query TestSessionJobLogsQuery($jobId: ID!, $first: Int!) {
    testsessionjoblogs(
      jobId: $jobId
      orderBy: createdAt_DESC
      skip: 0
      first: $first,
      truncateLines: 750
    ) {
      id
      createdAt
      log
    }
  }
`

export const MISUSECHECKER_SESSIONJOB_LOGS_QUERY = gql`
  query MiuseCheckerSessionJobLogsQuery($jobId: ID!, $first: Int!) {
    misusecheckersessionjoblogs(
      jobId: $jobId
      orderBy: createdAt_DESC
      skip: 0
      first: $first,
      truncateLines: 750
    ) {
      id
      createdAt
      log
    }
  }
`

export const FULL_TESTSESSION_FRAGMENT = gql`
  fragment FullTestSession on TestSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    description
    tags
    securityCheck
    nlpAnalytics
    performanceTesting
    status
    testProject {
      id
      name
      aggregators { id }
    }
    chatbot {
      id
      name
      allowHtmlDisplay
      avatar
      containermode
    }
    testSets {
      id
      name
    }
    deviceSets {
      id
      name
    }
    registeredComponents {
      id
      name
      ref
    }
    agent {
      id
      name
    }
    batchCount
    debug
    status
    chartData
    jobs {
      id
      createdAt
      jobId
      jobName
      progressPercent
      totalCount
      failedCount
      successCount
      status
      err
      started
      finished
    }
    aggregatedResults {
      id
      aggregatorName
      aggregatedValue
      label
      details
      success
      err
      errDetails
    }
    gdprTesting
    gdprTestSessionAudit {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      formValues
      language
      fallbackIntents { id intent messageText }
      status
      issueCount
    }
    factCheckerTesting
    factCheckerSession {
      id
      createdAt
      updatedAt
      createdBy { id name }
      updatedBy { id name }
      project { id name }
      totalCount
      failedCount
      correctCount
      incorrectCount
      unknownCount
    }
    misuseCheckerTesting
    misuseCheckerSession {
      id
      createdAt
      updatedAt
      chartData
      createdBy { id name }
      updatedBy { id name }
      project { id name }
      configuration {
        allowedDomains
        approvedTopics
        confusedSentences
        forbiddenTopics
        ignoredSentences
        distractionTopics
        numberOfCycles
      }
    }
  }
`

export const LIST_TESTSESSION_FRAGMENT = gql`
  fragment ListTestSession on TestSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    nlpAnalytics
    status
    testProject {
      id
      name
      namespace
      cronExpression
      monitoring
    }
    chatbot {
      id
      name
      namespace
      allowHtmlDisplay
      avatar
      containermode
    }
    testSets {
      id
      name
      namespace
    }
    chartData
    securityCheck
    securityAlerts {
      id
      method
      alert
      evidence
      risk
      confidence
      solution
      references
    }
    jobs {
      id
      createdAt
      jobId
      jobName
      progressPercent
      totalCount
      failedCount
      successCount
      status
      err
      started
      finished
    }
    performanceTesting
    performanceTestSession {
      id
      name
      jobs {
        id
        status
        err
      }
    }
    gdprTesting
    gdprTestSessionAudit {
      id
      language
      status
      issueCount
    }
    factCheckerTesting
    factCheckerSession {
      id
      project { id name }
      totalCount
      failedCount
      correctCount
      incorrectCount
      unknownCount
    }
    misuseCheckerTesting
    misuseCheckerSession {
      id
      chartData
      project { id name }
    }
    tags
  }
`

export const CREATED_TESTSESSION_FRAGMENT = gql`
  fragment CreatedTestSession on TestSessionCreated {
    id
    testSets {
      id
    }
    testProject {
      id
    }
  }
`

export const FULL_TESTSESSION_PROGRESS_FRAGMENT = gql`
  fragment FullTestSessionProgress on TestSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    namespace
    status
    chartData
    nlpAnalytics
    jobs {
      id
      createdAt
      jobId
      jobName
      progressPercent
      totalCount
      failedCount
      successCount
      status
      err
      started
      finished
    }
    trainerSession {
      id
      status
      cleanedUp
      err
    }
    securityCheck
    securityAlerts {
      id
      risk
    }
    gdprTesting
    gdprTestSessionAudit {
      id
      language
      status
      issueCount
    }
    factCheckerTesting
    factCheckerSession {
      id
      project { id name }
      totalCount
      failedCount
      correctCount
      incorrectCount
      unknownCount
    }
    misuseCheckerTesting
    misuseCheckerSession {
      id
      project { id name }
    }
    aggregatedResults {
      id
      aggregatorName
      aggregatedValue
      label
      details
      success
      err
      errDetails
    }
  }
`

export const FULL_TESTSESSION_RESULTS_FRAGMENT = gql`
  fragment FullTestSessionResults on TestSessionTestCaseResult {
    id
    createdAt
    testcaseName
    success
    err
    errDetails
    duration
    testSet {
      id
      name
      namespace
    }
    deviceSet {
      id
      name
      provider {
        id
        name
      }
    }
    testSessionJob {
      id
      jobName
    }
    deviceName
  }
`

export const FULL_MISUSECHECKER_SESSION_RESULTS_FRAGMENT = gql`
  fragment FullMisuseCheckerSessionResults on MisuseCheckerSessionResult {
    id
    createdAt
    distractionTopic
    transcript {
      step
      sender
      actual
    }
    violations {
      statement
      severity
      category
    }
  }
`

export const TESTSESSION_HISTORY_RESULTS_FRAGMENT = gql`
  fragment TestSessionHistoryResults on TestSessionTestCaseResult {
    id
    testcaseName
    success
    testSession {
      id
    }
  }
`

export const TESTSESSION_QUERY = gql`
  query TestSessionQuery($id: ID!) {
    testsession(id: $id) {
      ...FullTestSession
      trainerSession {
        id
        status
        cleanedUp
        err
      }
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const TESTSESSIONS_QUERY = gql`
  query TestSessionsQuery($where: TestSessionWhereInput, $orderBy: TestSessionOrderByInput, $skip: Int, $first: Int) {
    testsessions(where: $where, orderBy: $orderBy, skip: $skip, first: $first) @connection(key: "testsessions", filter: ["where", "orderBy"]) {
      ...ListTestSession
      trainerSession {
        ...TrainerSessionList
      }
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
  ${TRAINERSESSION_LIST_FRAGMENT}
`

export const TESTSESSIONS_PAGINATED_QUERY = gql`
  query TestSessionsQuery($where: TestSessionWhereInput, $orderBy: TestSessionOrderByInput, $skip: Int, $first: Int) {
    testsessions(where: $where, orderBy: $orderBy, skip: $skip, first: $first) @connection(key: "testsessionspaginated", filter: ["where", "orderBy"]) {
      ...ListTestSession
      trainerSession {
        ...TrainerSessionList
      }
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
  ${TRAINERSESSION_LIST_FRAGMENT}
`

export const TESTSESSIONS_COUNT_QUERY = gql`
  query TestSessionsCountQuery($where: TestSessionWhereInput) {
    testsessionsCount(where: $where)
  }
`

export const TESTSESSIONS_COUNT_PAGINATED_QUERY = gql`
  query TestSessionsCountQuery($where: TestSessionWhereInput) {
    testsessionsCount(where: $where)
  }
`

export const TESTSESSION_RESULTS_QUERY = gql`
  query TestSessionResultsQuery($id: ID!, $where: TestSessionTestCaseResultWhereInput!, $orderBy: TestSessionTestCaseResultOrderByInput!, $skip: Int, $first: Int) {
    testsessiontestcaseresults(id: $id, where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullTestSessionResults
    }
  }
  ${FULL_TESTSESSION_RESULTS_FRAGMENT}
`

export const TESTSESSION_RESULTCOUNT_QUERY = gql`
  query TestSessionResultCountQuery($id: ID!, $where: TestSessionTestCaseResultWhereInput!) {
    testsessiontestcaseresultcount(id: $id, where: $where)
  }
`

export const TESTSESSION_RESULTS_SUBSCRIPTION = gql`
  subscription TestSessionResultsSubscription($id: ID!, $where: TestSessionTestCaseResultWhereInput!, $orderBy: TestSessionTestCaseResultOrderByInput!, $skip: Int, $first: Int) {
    testSessionProgressResults(testSessionId: $id, where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullTestSessionResults
    }
  }
  ${FULL_TESTSESSION_RESULTS_FRAGMENT}
`

export const TESTSESSION_RESULTCOUNT_SUBSCRIPTION = gql`
  subscription TestSessionResultCountSubscription($id: ID!, $where: TestSessionTestCaseResultWhereInput!) {
    testSessionProgressResultCount(testSessionId: $id, where: $where)
  }
`

export const TESTSESSION_PROGRESS_QUERY = gql`
  query TestSessionProgressQuery($id: ID!) {
    testsession(id: $id) {
      ...FullTestSessionProgress
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const TESTSESSIONPROGRESS_SUBSCRIPTION = gql`
  subscription TestSessionProgressSubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      ...FullTestSessionProgress
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const TESTSESSIONPROGRESS_GDPR_QUERY = gql`
  query TestSessionProgressGDPRQuery($id: ID!) {
    testsession(id: $id) {
      id
      status
      gdprTestSessionAudit {
        id
        language
        status
        issueCount
      }
    }
  }
`

export const TESTSESSIONPROGRESS_GDPR_SUBSCRIPTION = gql`
  subscription TestSessionProgressGDPRSubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      id
      status
      gdprTestSessionAudit {
        id
        language
        status
        issueCount
      }
    }
  }
`

export const TESTSESSION_PROGRESS_COACH_QUERY = gql`
  query TestSessionProgressCoachQuery($id: ID!) {
    testsession(id: $id) {
      id
      chartData
      status
      trainerSession {
        ...TrainerSessionList
      }
    }
  }
  ${TRAINERSESSION_LIST_FRAGMENT}
`

export const TESTSESSIONPROGRESS_COACH_SUBSCRIPTION = gql`
  subscription TestSessionProgressCoachSubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      id
      trainerSession {
        ...TrainerSessionList
      }
    }
  }
  ${TRAINERSESSION_LIST_FRAGMENT}
`

export const TESTSESSION_PROGRESS_SECURITY_QUERY = gql`
  query TestSessionProgressSecurityQuery($id: ID!) {
    testsession(id: $id) {
      ...FullTestSessionProgress
      securityAlerts { id method confidence risk alert evidence solution references }
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const TESTSESSIONPROGRESS_SECURITY_SUBSCRIPTION = gql`
  subscription TestSessionProgressSecuritySubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      ...FullTestSessionProgress
      securityAlerts { id method confidence risk alert evidence solution references }
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const TESTSESSION_PROGRESS_FACTCHECKER_QUERY = gql`
  query TestSessionProgressFactCheckerQuery($id: ID!) {
    testsession(id: $id) {
      ...FullTestSessionProgress
      factCheckerSession {
        id
        totalCount
        failedCount
        correctCount
        incorrectCount
        unknownCount
        queries {
          id
          query
          answer
          result
          status
          err
          errDetails
        }
        project { id name }
      }
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const TESTSESSIONPROGRESS_FACTCHECKER_SUBSCRIPTION = gql`
  subscription TestSessionProgresssFactCheckerSubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      ...FullTestSessionProgress
      factCheckerSession {
        id
        totalCount
        failedCount
        correctCount
        incorrectCount
        unknownCount
        queries {
          id
          query
          answer
          result
          status
          err
          errDetails
        }
        project { id name }
      }
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const RETRY_TESTSESSION_FACTCHECKER = gql`
  mutation RetryTestSessionFactChecker($id: ID!) {
    retryTestSessionFactChecker(id: $id) {
      ...FullTestSession
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const TESTSESSION_PROGRESS_MISUSECHECKER_QUERY = gql`
  query TestSessionProgressMisuseCheckerQuery($id: ID!) {
    testsession(id: $id) {
      ...FullTestSessionProgress
      misuseCheckerSession {
        id
        chartData
        project { id name }
        results {
          id
          distractionTopic
          violations {
            id
            statement
            severity
            category
            reason
          }
        }
        jobs {
          id
          status
          started
          finished
        }
      }
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION = gql`
  subscription TestSessionProgresssMisuseCheckerSubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      ...FullTestSessionProgress
      misuseCheckerSession {
        id
        chartData
        project { id name }
        results {
          id
          distractionTopic
          violations {
            id
            statement
            severity
            category
            reason
          }
        }
        jobs {
          id
          status
          started
          finished
        }
      }
    }
  }
  ${FULL_TESTSESSION_PROGRESS_FRAGMENT}
`

export const RETRY_TESTSESSION_MISUSECHECKER = gql`
  mutation RetryTestSessionMisuseChecker($id: ID!) {
    retryTestSessionMisuseChecker(id: $id) {
      ...FullTestSession
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const MISUSECHECKER_SESSION_RESULT_QUERY = gql`
  query MisuseCheckerSessionResultQuery($id: ID!) {
    misusecheckersessionresult(id: $id) {
      ...FullMisuseCheckerSessionResults
      job {
        id
        createdAt
      }
    }
  }
  ${FULL_MISUSECHECKER_SESSION_RESULTS_FRAGMENT}
`

export const MISUSECHECKER_SESSION_RESULTS_QUERY = gql`
  query MisuseCheckerSessionResultsQuery($id: ID!, $where: MisuseCheckerSessionResultWhereInput!, $orderBy: MisuseCheckerSessionResultOrderByInput!, $skip: Int, $first: Int) {
    misusecheckersessionresults(testSessionId: $id, where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullMisuseCheckerSessionResults
    }
  }
  ${FULL_MISUSECHECKER_SESSION_RESULTS_FRAGMENT}
`

export const MISUSECHECKER_SESSION_RESULTCOUNT_QUERY = gql`
  query MisuseCheckerSessionResultCount($id: ID!, $where: MisuseCheckerSessionResultWhereInput!) {
    misusecheckersessionresultcount(testSessionId: $id, where: $where)
  }
`

export const MISUSECHECKER_SESSION_RESULTS_SUBSCRIPTION = gql`
  subscription misuseCheckerSessionProgressResults($id: ID!, $where: MisuseCheckerSessionResultWhereInput!, $orderBy: MisuseCheckerSessionResultOrderByInput!, $skip: Int, $first: Int) {
    misuseCheckerSessionProgressResults(testSessionId: $id, where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullMisuseCheckerSessionResults
    }
  }
  ${FULL_MISUSECHECKER_SESSION_RESULTS_FRAGMENT}
`

export const MISUSECHECKER_SESSION_RESULTCOUNT_SUBSCRIPTION = gql`
  subscription MisuseCheckerSessionProgressResultCount($id: ID!, $where: MisuseCheckerSessionResultWhereInput!) {
    misuseCheckerSessionProgressResultCount(testSessionId: $id, where: $where)
  }
`

export const TESTSESSIONTREND_QUERY = gql`
  query TestSessionTrendQuery($id: ID!) {
    testsessiontrend(id: $id) {
      testSessionId
      testSessionName
      testSessionTotalDuration
      testSessionRunTimeDuration
      testSessionAverageDuration
      testSessionSuccessCount
      testSessionFailedCount
      testSessionNLPScore
      previousTestSessionId
      previousTestSessionName
      previousTestSessionTotalDuration
      previousTestSessionRunTimeDuration
      previousTestSessionAverageDuration
      previousTestSessionSuccessCount
      previousTestSessionFailedCount
      previousTestSessionNLPScore
      testResultsStatusChanged { id testcaseName success }
      nextTestSessionId
      nextTestSessionName
      nextTestSessionTotalDuration
      nextTestSessionRunTimeDuration
      nextTestSessionAverageDuration
      nextTestSessionSuccessCount
      nextTestSessionFailedCount
      nextTestSessionNLPScore
    }
  }
`

export const CREATE_TESTSESSION = gql`
  mutation CreateTestSession($testSession: TestSessionCreateInput!) {
    createTestSession(testSession: $testSession) {
      ...FullTestSession
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const RETRY_TESTSESSION = gql`
  mutation RetryTestSession($id: ID!, $allTestCases: Boolean) {
    retryTestSession(id: $id, allTestCases: $allTestCases) {
      ...FullTestSession
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const DELETE_TESTSESSION = gql`
  mutation DeleteTestSession($id: ID!) {
    deleteTestSession(id: $id)
  }
`

export const CANCEL_TESTSESSION = gql`
  mutation CancelTestSession($id: ID!) {
    cancelTestSession(id: $id)
  }
`

export const TESTSESSION_REPORT_QUERY_GDPR = gql`
  query TestSessionReportQueryGdpr($id: ID!) {
    testsessiontestcaseresults(id: $id, where: {}, skip: 0, first: 10000) {
      id
      testcaseName
      success
      err
      steps {
        id
        step
        sender
        actual
        err
      }
    }
  }
`

export const RETRY_TESTSESSION_GDPR = gql`
  mutation RetryTestSessionGdpr($id: ID!) {
    retryTestSessionGdpr(id: $id) {
      ...FullTestSession
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const UPDATE_TESTSESSION_GDPRAUDIT = gql`
  mutation UpdateTestSessionGdprAudit($id: ID!, $formValues: String!, $status: String!, $language: String!, $issueCount: Int!) {
    updateTestSessionGdprAudit(id: $id, formValues: $formValues, status: $status, language: $language, issueCount: $issueCount) {
      ...FullTestSession
    }
  }
  ${FULL_TESTSESSION_FRAGMENT}
`

export const DeleteTestSessionListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^testsessions/)
}

export const RefetchTestSessionQueries = (testSessionId) => [
  testSessionId && {
    query: TESTSESSIONTREND_QUERY,
    variables: { id: testSessionId }
  },
  testSessionId && {
    query: TESTSESSION_PROGRESS_QUERY,
    variables: { id: testSessionId }
  },
  testSessionId && {
    query: TESTSESSION_QUERY,
    variables: { id: testSessionId }
  }
].filter(s => s)

export const RefetchTestSessionsQueries = (variables) => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: TESTSESSIONS_PAGINATED_QUERY,
    variables
  },
  {
    query: TESTSESSIONS_COUNT_PAGINATED_QUERY
  },
  {
    query: TESTPROJECTS_WITH_LAST_TEST_SESSION_AND_STATUS_PAGINATED_QUERY
  },
].filter(s => s)

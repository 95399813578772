import { SET_ALERT, CLEAR_ALERT, SHOW_FEATURE_UPGRADE, SHOW_SUPPORT_POPUP, SHOW_FACT_CHECKER_POPUP } from '../constants'

export function clearAlert() {
  return {
    type: CLEAR_ALERT,
  }
}

export function setAlert({ color, message, autoHideDuration, err }) {
  return {
    type: SET_ALERT,
    payload: {
      color,
      message,
      autoHideDuration,
      err,
    },
  }
}

export function setAlertSuccessMessage(message) {
  return {
    type: SET_ALERT,
    payload: {
      color: 'success',
      message,
      autoHideDuration: 5000,
      dataUnique: 'msgAlertSuccess'
    },
  }
}

export function setAlertErrorMessage(message, err) {
  return {
    type: SET_ALERT,
    payload: {
      color: 'danger',
      message,
      err,
      dataUnique: 'msgAlertError'
    },
  }
}

export function showFeatureUpgrade(show) {
  return {
    type: SHOW_FEATURE_UPGRADE,
    payload: {
      show
    },
  }
}

export function showFactCheckerPopup(show) {
  return {
    type: SHOW_FACT_CHECKER_POPUP,
    payload: {
      show
    },
  }
}

export function showSupportPopup(show) {
  return {
    type: SHOW_SUPPORT_POPUP,
    payload: {
      show
    },
  }
}

import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'
import classNames from 'classnames'
import ShowIcon from 'components/Icon/ShowIcon'

function Text({ ...props }) {
  const {
    classes,
    children,
    isHtml,
    color,
    primary,
    muted,
    danger,
    quote,
    warning,
    success,
    white,
    pre,
    center,
    info,
    important,
    bold,
    bold500,
    regular,
    italic,
    extralg,
    mlg,
    lg,
    md,
    sm,
    header,
    subheader,
    navHeader,
    icon,
    inline,
    inlineTable,
    linebreak,
    padding,
    flex,
    common,
    paddingBottom,
    ellipsis,
    helperText,
    bggrey,
    bgradius,
    topMargin,
    topMarginmd,
    topMarginsm,
    topMarginxs,
    bottomMargin,
    bottomMarginsm,
    rightMarginmd,
    rightMarginsm,
    rightMarginxs,
    leftMarginxs,
    labelText,
    dropdownLabel,
    horizontalCenter,
    noUnderline,
    contentCenter,
    wordBreak,
    paddingLeftRight,
    smLineHeight,
    linkUnderline,
    dangerBg,
    lowSeverityBg,
    lowSeverity,
    warningBg,
    successBg,
    iconRotation,
    marginRight,
    displayInline,
    displayBlock,
    noWrap,
    ...rest
  } = props

  const classnames = classNames({
    [classes.defaultFontStyle]: true,
    [classes.primaryText]: primary || header || subheader,
    [classes.primaryCommonText]: primary && common,
    [classes.mutedText]: muted,
    [classes.dangerText]: danger || color === 'danger',
    [classes.dangerTextBg]: dangerBg,
    [classes.successText]: success || color === 'success',
    [classes.successTextBg]: successBg,
    [classes.quoteText]: quote,
    [classes.warningText]: warning || color === 'warning',
    [classes.warningTextBg]: warningBg,
    [classes.lowSeverityTextBg]: lowSeverityBg,
    [classes.lowSeverityText]: lowSeverity,
    [classes.whiteText]: white,
    [classes.preText]: pre,
    [classes.centerText]: center,
    [classes.infoText]: info || color === 'info',
    [classes.infoCommonText]: info && common,
    [classes.importantText]: important,
    [classes.bold]: bold || header,
    [classes.bold500]: bold500 || header,
    [classes.regular]: regular || header,
    [classes.italic]: italic,
    [classes.extralg]: extralg,
    [classes.mlg]: mlg,
    [classes.lg]: lg || header,
    [classes.md]: md || subheader,
    [classes.sm]: sm,
    [classes.inline]: inline,
    [classes.inlineTable]: inlineTable,
    [classes.linebreak]: linebreak,
    [classes.padding]: padding,
    [classes.flex]: flex,
    [classes.paddingBottom]: paddingBottom,
    [classes.ellipsis]: ellipsis,
    [classes.helperText]: helperText,
    [classes.bggrey]: bggrey,
    [classes.bgradius]: bgradius,
    [classes.topMargin]: topMargin,
    [classes.topMarginmd]: topMarginmd,
    [classes.topMarginsm]: topMarginsm,
    [classes.topMarginxs]: topMarginxs,
    [classes.rightMarginsm]: rightMarginsm,
    [classes.rightMarginxs]: rightMarginxs,
    [classes.leftMarginxs]: leftMarginxs,
    [classes.bottomMargin]: bottomMargin,
    [classes.bottomMarginsm]: bottomMarginsm,
    [classes.rightMarginmd]: rightMarginmd,
    [classes.labelText]: labelText,
    [classes.dropdownLabel]: dropdownLabel,
    [classes.horizontalCenter]: horizontalCenter,
    [classes.noUnderline]: noUnderline,
    [classes.contentCenter]: contentCenter,
    [classes.wordBreak]: wordBreak,
    [classes.paddingLeftRight]: paddingLeftRight,
    [classes.smLineHeight]: smLineHeight,
    [classes.linkUnderline]: linkUnderline,
    [classes.iconRotation]: iconRotation,
    [classes.marginRight]: marginRight,
    [classes.displayInline]: displayInline,
    [classes.displayBlock]: displayBlock,
    [classes.navHeader]: navHeader,
    [classes.noWrap]: noWrap,
  })

  if (inline || inlineTable) {
    if (isHtml) {
      return <span className={classnames} dangerouslySetInnerHTML={{ __html: children }} {...rest} />
    } else {
      return <span className={classnames} {...rest}>
        {icon && <ShowIcon icon={icon} />}
        {children}
      </span>
    }
  } else {
    if (isHtml) {
      return (
        <div className={classnames} dangerouslySetInnerHTML={{ __html: children }} {...rest} />
      )
    } else {
      return (
        <div className={classnames} {...rest}>
          {icon && <ShowIcon icon={icon} />}
          {children}
        </div>
      )
    }
  }
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
  isHtml: PropTypes.bool
}

export default withStyles(typographyStyle)(Text)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Text from 'components/Typography/Text'
import Button from 'components/Button/Button'
import { setAlertSuccessMessage, showFactCheckerPopup, setAlertErrorMessage } from 'actions/alert'
//imgages
import imgFactChecker from 'assets/img/llm/llmfactchecker.png'
import {SEND_FACTCHECKER_FEATURE_REQUEST} from './gql'

const FactCheckerPopupStyle = {
  // Define your styles here
}

class FactCheckerPopup extends Component {
    render() {
        const { showFactCheckerPopup, setAlertErrorMessage, setAlertSuccessMessage, show, email } = this.props

        return (<ConfirmationDialog
          open={show}
          cancelText="Cancel"
          onCancel={() => {
            showFactCheckerPopup(false)
          }}
          title="FactCheck Feature Request"
          data-unique="dlgFactCheckerPopup"
        >
          <GridContainer style={{lineHeight: '36px'}} alignItems="center" justify="center">
            <GridItem xs={12} sm={5} align="center">
              <img src={imgFactChecker} alt="FactCheck" />
            </GridItem>
            <GridItem xs={12} sm={5} align="center">
              <Text lg>
                FactCheck is not included in your existing Botium package. Need access? Click here to notify your Cyara representative!
              </Text>
              <Mutation
                mutation={SEND_FACTCHECKER_FEATURE_REQUEST}
                onCompleted={data => {
                  showFactCheckerPopup(false)
                  setAlertSuccessMessage('Thank you! Your request has been sent to the support team.')
                }}
                onError={error => {
                  showFactCheckerPopup(false)
                  setAlertErrorMessage('error')
                }}
              >
                {(sendFactCheckerFeatureRequestEmail, { loading, error }) => (
                    <Button minWidth small marginBottomTop color="primary" onClick={() => {
                      sendFactCheckerFeatureRequestEmail({ variables: { email } })
                    }} >Notify</Button>
                )}
              </Mutation>
              </GridItem>
          </GridContainer>
        </ConfirmationDialog>)
      }
    }

    const FactCheckerPopupState = connect(
      state => ({
        show: (state.factcheckerpopup && state.factcheckerpopup.show) || false,
        license: state.settings.license,
        email: state.token.user.email
      }),
      { showFactCheckerPopup, setAlertSuccessMessage, setAlertErrorMessage }
    )(withStyles(FactCheckerPopupStyle)(withRouter(FactCheckerPopup)))

    export default FactCheckerPopupState

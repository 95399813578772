import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import download from 'downloadjs'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import arrayMutators from 'final-form-arrays'
import setFieldTouched from 'final-form-set-field-touched'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Query, Mutation, compose, graphql } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import DropdownButton from 'components/Button/DropdownButton'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardHeaderActions from 'components/Card/CardHeaderActions.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import Text from 'components/Typography/Text.jsx'
import AvatarSelector from 'components/Avatar/AvatarSelector'
import AvatarImage from 'components/Avatar/AvatarImage'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import StatsText from 'components/Stats/StatsText.jsx'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import {
  renderTextField,
  renderTextArea,
  renderIntField,
  renderTagField,
  renderNamespaceField,
  renderAutoSuggest,
  renderCheckbox,
  renderFileUpload,
  required,
  conditional,
  regex,
  minValue,
  composeValidators,
  parseInteger,
  FormActionsToolbar,
  renderSelect
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import { getConnector } from 'actions/settings'
import { formatCreatedByAndLastChange } from 'helper/authHelper'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'
import Divider from 'components/Divider/Divider.js'

import {
  ConnectorSelector,
  ConnectorSwitch,
  SourcesSwitch,
  EnvsSwitch,
  ConnectorPrepareForm,
  ConnectorPrepareCaps,
  CapabilitiesToGql,
  EnvironmentVariablesToGql,
  ImportBotiumJson,
  ExportBotiumJson
} from 'components/Capability/Helper'

import chatbotsStyle from 'assets/jss/material-dashboard-react/views/chatbotsStyle.jsx'

import {
  RefetchChatbotQueries,
  CHATBOT_QUERY,
  UPDATE_CHATBOT,
  DELETE_CHATBOT,
  RESET_CHATBOT_RATES,
  CHATBOT_TESTPROJECTS_QUERY,
  RefetchChatbotQueriesOnNewTestSession
} from './gql'
import { CAPABILITYSETS_DROPDOWN_QUERY, CAPABILITYSETS_QUERY, TAGS_QUERY } from '../Settings/gql'
import { START_TESTPROJECT, RefetchTestProjectQueriesOnNewTestSession } from '../TestProjects/gql'
import { RefetchTestSessionQueries, DeleteTestSessionListsFromCache } from '../TestSessions/gql'
import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'
import TestProjectsEmbeddedTable from '../TestProjects/TestProjectsEmbeddedTable.jsx'
import SayHello from './SayHello'
import { getDefaultTestTypes, getTechnologyTypeIcon } from 'views/QuickStart/Helper'

import { importPostwoman, importPostman } from './helper'

import { hasPermission, hasAnyPermission, canWriteNamespace, canReadNamespace } from 'botium-box-shared/security/permissions'
import ListItem from 'components/List/ListItem/ListItem'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'

class Chatbot extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newTestSessionCount: 0,
      wizardExportExpanded: false,
      wizardTestSetId: null,
      connectorSettingsAdvancedMode: false,
      showAvatarImage: true,
      hasReadPermissions: hasPermission(props.user, 'CHATBOTS_SELECT') && canReadNamespace(props.user, props.user.namespacePermissions, props.chatbot.namespace),
      hasWritePermission: hasAnyPermission(props.user, ['CHATBOTS_CREATE', 'CHATBOTS_UPDATE']) && canWriteNamespace(props.user, props.user.namespacePermissions, props.chatbot.namespace)
    }

    if (props.location && props.location.search) {
      const query = queryString.parse(props.location.search)
      if (query.tse) {
        this.state.wizardTestSetId = query.tse
        this.state.wizardExportExpanded = true
      } else if (query.tsi) {
        this.state.wizardTestSetId = query.tsi
        this.state.wizardImportExpanded = true
      } else if (query.crawl) {
        this.state.wizardCrawlExpanded = true
      }
    }
  }

  hasReadPermission() {
    return this.state.hasReadPermissions
  }
  hasWritePermission() {
    return this.state.hasWritePermission
  }

  renderDashboard(chatbot) {
    const { classes, user, settings, history, license } = this.props

    const connector = settings && settings.allconnectors.find(c => (
      c.name === chatbot.containermode
    ))

    return (<>
      <GridContainer>
        <GridItem xs={12}>
          <Card secondary>
            <CardBody>
              <GridContainer>
                <GridItem md={4} lg={6}>
                  <Text header>Chatbot Overview</Text>
                  <Text subheader>A short Overview of this Chatbot</Text>
                </GridItem>
                <GridItem md={8} lg={6} right>
                  {this.hasReadPermission() && connector && connector.features && connector.features.intentResolution &&
                    <Button spaceRight className={classes.dashboardbutton}
                      disabled={!license.coach}
                      data-unique="btnChatbotUtteranceInsights"
                      onClick={() => history.push(`/chatbots/view/${chatbot.id}/utteranceinsights`)}
                      link
                    >
                      <ShowIcon icon="chart-line" />
                      Utterance Analytics
                    </Button>
                  }
                  {this.hasReadPermission() && hasPermission(user, 'CHATBOTS_LIVECHAT') && chatbot.id &&
                    <Button className={classes.dashboardbutton}
                      data-unique="btnChatbotLiveChat"
                      onClick={() => history.push(`/chatbots/view/${chatbot.id}/livechat`)}
                      link
                    >
                      <ShowIcon icon="comments" />
                      Live Chat
                    </Button>
                  }
                </GridItem>
                <GridItem md={12}>
                  <GridContainer classes={{ grid: classes.leftDatacards }}>
                    <GridItem md={1} bottom>
                      <StatsText primaryText={<AvatarImage variant="medium" avatar={chatbot.avatar} containermode={chatbot.containermode} chatbotId={chatbot.id} />} secondaryText="Chatbot" />
                    </GridItem>
                    <GridItem md={3} lg={2} bottom>
                      <StatsText primarySuffix={connector ? (connector.description || connector.name) : 'Custom Connector'} secondaryText="Connector / Chatbot Technology" />
                    </GridItem>
                    <GridItem md lg bottom>
                      <StatsText primarySuffix={getDefaultTestTypes(license, connector).map(type => {
                        return getTechnologyTypeIcon(type)
                      })} secondaryText="Supported Test Type" /> 
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          {hasPermission(user, 'TESTSESSIONS_SELECT') &&
            <Card>
              <CardHeader color="info">
                <CardHeaderActions>
                  {hasPermission(user, 'TESTSESSIONS_CREATE') && <Query query={CHATBOT_TESTPROJECTS_QUERY} variables={{ chatbotId: chatbot.id }}>
                    {(queryResult) => <QueryStatus {...queryResult} query="testprojects">{() => {
                      const { data } = queryResult

                      let startedTestProjectId = null

                      const startableTestProjects = data.testprojects.filter(t => canWriteNamespace(user, user.namespacePermissions, t.namespace))

                      if (startableTestProjects.length > 0) {
                        return (<Mutation
                          mutation={START_TESTPROJECT}
                          onCompleted={data => {
                            setAlertSuccessMessage('Test session started ...')
                            this.setState({ newTestSessionCount: this.state.newTestSessionCount + 1 })
                          }}
                          onError={error => {
                            setAlertErrorMessage('Test session failed', error)
                          }}
                          refetchQueries={[
                            ...(startedTestProjectId ? RefetchTestProjectQueriesOnNewTestSession(startedTestProjectId) : []),
                            ...RefetchChatbotQueriesOnNewTestSession(chatbot.id),
                            ...RefetchTestSessionQueries()
                          ]}
                          update={DeleteTestSessionListsFromCache}
                        >
                          {(startTestProject, { loading, error }) => (
                            <DropdownButton
                              primaryMini
                              data-unique="ddbtnChatbotStartTestSession"
                              items={startableTestProjects.map(t => ({
                                id: t.id,
                                name: `... ${t.name}`,
                                onClick: () => {
                                  startedTestProjectId = t.id
                                  startTestProject({ variables: { id: t.id, debug: false } })
                                }
                              }))
                              }
                            >
                              <ShowIcon icon="play-circle" />
                              Select and Start New Test Session
                            </DropdownButton>
                          )}
                        </Mutation>)
                      }
                      return null
                    }}
                    </QueryStatus>}</Query>}
                </CardHeaderActions>
                <Text header>Recent Test Results</Text>
                <Text subheader>Recent Test Session Results of this Chatbot</Text>
              </CardHeader>
              <CardBody noPadding>
                <GridContainer>
                  <GridItem xs={12} className={classes.chatbotTestSessionsEmbeddedTable}>
                    <TestSessionsEmbeddedTable key={`TestSessions_${this.state.newTestSessionCount}`} variables={{ chatbot: { id: chatbot.id } }} disableOrderBy skip={0} name={`Chatbot_${chatbot.id}_DashboardTestSessions`} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          }
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info">
              <Text header>Test Projects</Text>
              <Text subheader>Available Test Projects for this Chatbot</Text>
            </CardHeader>
            <CardBody noPadding>
              <GridContainer>
                <GridItem xs={12} className={classes.embeddedTableNamespace}>
                  <TestProjectsEmbeddedTable
                    variables={{ chatbot: { id: chatbot.id } }}
                    name={`Chatbot_${chatbot.id}_TestProjects`}
                    refetchQueriesForTestSession={() => RefetchChatbotQueriesOnNewTestSession(chatbot.id)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>)
  }

  validateSettingsForm(sectionExpanded, values) {
    const result = {}
    if (sectionExpanded === 'redo' && values.retryUserSaysNumRetries > 0) {
      result.retryUserSaysFactor = minValue(1)(values.retryUserSaysFactor)
      result.retryUserSaysMinTimeout = minValue(0)(values.retryUserSaysMinTimeout)
      result.retryUserSaysOnErrorRegexp = composeValidators(regex)(values.retryUserSaysOnErrorRegexp)
    }
    if (sectionExpanded === 'redo' && values.retryConvoNumRetries > 0) {
      result.retryConvoFactor = minValue(1)(values.retryConvoFactor)
      result.retryConvoMinTimeout = minValue(0)(values.retryConvoMinTimeout)
      result.retryConvoOnErrorRegexp = composeValidators(regex)(values.retryConvoOnErrorRegexp)
    }
    if (sectionExpanded === 'redo' && values.retryStartNumRetries > 0) {
      result.retryStartFactor = minValue(1)(values.retryStartFactor)
      result.retryStartMinTimeout = minValue(0)(values.retryStartMinTimeout)
      result.retryStartOnErrorRegexp = composeValidators(regex)(values.retryStartOnErrorRegexp)
    }
    return result
  }

  renderSettingsFormTab(chatbot, sectionExpanded, fnForm, fnLeftButtons, fnRightButtons) {
    const { connectorSettingsAdvancedMode } = this.state
    const { getConnector, setAlertSuccessMessage, setAlertErrorMessage } = this.props

    const origCapabilities = [...(chatbot.capabilities || [])]
    const origSources = [...(chatbot.sources || [])]
    const origEnvs = [...(chatbot.envs || [])]

    return (<Mutation
      mutation={UPDATE_CHATBOT}
      refetchQueries={[
        ...RefetchChatbotQueries(chatbot.id),
        {
          query: TAGS_QUERY
        },
        {
          query: CAPABILITYSETS_DROPDOWN_QUERY
        }
      ]}
    >
      {(mutateChatbot, { loading, error }) => (
        <Form
          mutators={{ ...arrayMutators, setFieldTouched }}
          onSubmit={async (values, form) => {
            ConnectorPrepareCaps(
              getConnector(values.connector) || { name: values.connector },
              values,
              values.capabilities,
              connectorSettingsAdvancedMode
            )

            const capabilities = CapabilitiesToGql(
              values.capabilities,
              origCapabilities,
            )

            const sources = CapabilitiesToGql(values.sources, origSources)
            const envs = EnvironmentVariablesToGql(values.envs, origEnvs)

            try {
              await mutateChatbot({
                variables: {
                  id: values.id,
                  chatbot: {
                    name: values.name,
                    description: values.description || null,
                    tags: {
                      set: values.tags,
                    },
                    namespace: values.namespace || null,
                    avatar: values.avatar,
                    allowHtmlDisplay: values.allowHtmlDisplay,
                    retryUserSaysNumRetries: values.retryUserSaysNumRetries,
                    retryUserSaysFactor: values.retryUserSaysFactor,
                    retryUserSaysMinTimeout: values.retryUserSaysMinTimeout,
                    retryUserSaysOnErrorRegexp: {
                      set: values.retryUserSaysOnErrorRegexp || []
                    },
                    retryConvoNumRetries: values.retryConvoNumRetries,
                    retryConvoFactor: values.retryConvoFactor,
                    retryConvoMinTimeout: values.retryConvoMinTimeout,
                    retryConvoOnErrorRegexp: {
                      set: values.retryConvoOnErrorRegexp || []
                    },
                    retryStartNumRetries: values.retryStartNumRetries,
                    retryStartFactor: values.retryStartFactor,
                    retryStartMinTimeout: values.retryStartMinTimeout,
                    retryStartOnErrorRegexp: {
                      set: values.retryStartOnErrorRegexp || []
                    },
                    rateLimitUserSaysPerSecond: values.rateLimitUserSaysPerSecond || null,
                    rateLimitUserSaysPerMinute: values.rateLimitUserSaysPerMinute || null,
                    rateLimitUserSaysPerHour: values.rateLimitUserSaysPerHour || null,
                    rateLimitGroup: values.rateLimitGroup,
                    waitForBotTimeout: values.waitForBotTimeout,
                    mediaDownload: values.mediaDownload,
                    capabilities,
                    capabilitySets: {
                      connect: values.capabilitySets.map(cs => {
                        return { id: cs.id }
                      }),
                      disconnect: _.difference(chatbot.capabilitySets.map(cs => cs.id), values.capabilitySets.map(cs => cs.id)).map(csId => ({ id: csId }))
                    },
                    sources,
                    envs,
                  },
                },
              })
              setAlertSuccessMessage('Chatbot updated')
            } catch (error) {
              setAlertErrorMessage(`Chatbot update failed`, error)
            }
          }}
          initialValues={chatbot}
          validate={(values) => this.validateSettingsForm(sectionExpanded, values)}
          render={({
            handleSubmit,
            form,
            values,
            submitting,
            invalid
          }) => (
            <form onSubmit={handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem md={12} lg={8}>
                  {fnForm({ form, values, submitting })}
                </GridItem>
                <GridItem md={12} lg={8} largePadding>
                  <FormActionsToolbar
                    leftButtons={<>
                      {fnLeftButtons && fnLeftButtons({ form, values, submitting, invalid })}
                    </>}
                    rightButtons={<>
                      {fnRightButtons && fnRightButtons({ form, values, submitting, invalid })}
                      {this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnChatbotSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      }
                    </>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )}
        />
      )}
    </Mutation>)
  }

  renderSettingsForm(chatbot) {
    const { connectorSettingsAdvancedMode } = this.state
    const { history, user, settings, license, getConnector, setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry, mutateResetChatbotRates } = this.props


    const setConnectorSettingsAdvancedMode = (connectorSettingsAdvancedMode) => {
      this.setState({ connectorSettingsAdvancedMode })
    }

    chatbot.connector = chatbot.containermode || 'other'
    ConnectorPrepareForm(settings.allconnectors, chatbot, chatbot.capabilities)

    return (<CustomTabsSecondary
      name={`tabChatbotSettings_${chatbot.id}`}
      headerColor="info"
      tabs={[
        {
          tabName: 'Connector Settings',
          tabContent: this.renderSettingsFormTab(chatbot, 'connector', ({ form: { change, mutators: { push, pop } }, values }) => {

            const capabilitySetCaps = []
            values && values.capabilitySets && values.capabilitySets.forEach(cs => {
              cs.capabilities.forEach(c => {
                capabilitySetCaps.push(c.name)
              })
            })

            return <GridContainer>
              <GridItem xs={12}>
                <ConnectorSelector
                  advanced
                  forcedConnector={chatbot.connector}
                  validate={required}
                  disabled={!this.hasWritePermission()}
                  setconnectorsettingsadvancedmode={setConnectorSettingsAdvancedMode}
                  connectorsettingsadvancedmode={connectorSettingsAdvancedMode}
                />
              </GridItem>
              <GridItem xs={6}>
                <Query query={CAPABILITYSETS_QUERY}>
                  {({ loading, error, data }) => {
                    return <Field
                      name="capabilitySets"
                      component={renderSelect}
                      label="Use Capability Set(s)"
                      helperText={<>To create / edit Capability Sets click <NavLink to="/settings/capabilitysets" data-unique="btnChatbotCapabilitySetsLink">here</NavLink></>}
                      data-unique="selChatbotCapabilitySet"
                      loading={loading}
                      error={error}
                      multiple
                      valueKeyMap={d => d.id}
                      items={data && data.capabilitySets && data.capabilitySets.map(cs => {
                        return {
                          key: cs.id,
                          label: cs.name,
                          value: cs
                        }
                      })}
                    />
                  }}
                </Query>
              </GridItem>
              <GridItem xs={12}>
                <Divider />
              </GridItem>
              <GridItem xs={12}>
                <ConnectorSwitch
                  push={push}
                  pop={pop}
                  advanced
                  disabled={!this.hasWritePermission()}
                  change={change}
                  values={values}
                  connectorsettingsadvancedmode={connectorSettingsAdvancedMode}
                  capSetCapNames={capabilitySetCaps}
                />
              </GridItem>
            </GridContainer>
          },
            ({ submitting, invalid }) => hasPermission(user, 'CHATBOTS_LIVECHAT') && <>
              <Button data-unique="btnChatbotStartLiveChat" secondary Border leftRound onClick={() => history.push(`/chatbots/view/${chatbot.id}/livechat`)} disabled={submitting || invalid}>
                <ShowIcon icon="play-circle" />
                Live Chat
              </Button>
              <DropdownButton
                aria-label="Actions"
                noMargin
                rightRound
                marginUnset
                secondary
                Border
                disabled={submitting || invalid}
                items={[{
                  id: 'livechat_open_new_tab',
                  name: 'Open Live Chat in a new Tab',
                  icon: 'external-link',
                  dataUnique: 'btnChatbotStartLiveChatOpenInNewTab',
                  onClick: () => {
                    window.open(`/chatbots/view/${chatbot.id}/livechat`)
                  }
                }]}
                data-unique="btnChatbotStartLiveChatDropdown"
              />
              <SayHello chatbotId={chatbot.id} allowHtmlDisplay={chatbot.allowHtmlDisplay} avatar={chatbot.avatar} containermode={chatbot.containermode} buttonProps={{ 'data-unique': 'btnChatbotSayHello', disabled: submitting || invalid }} />
            </>
          ),
          locationPrefix: `/chatbots/view/${chatbot.id}/settings/connector`,
          dataUnique: 'tabChatbotSettingsConnector'
        },
        {
          tabName: 'Botium Settings',
          tabContent: this.renderSettingsFormTab(chatbot, 'basic', ({ form: { change }, values }) => <GridContainer>
            <GridItem xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label="Chatbot Name"
                validate={required}
                disabled={!this.hasWritePermission()}
                data-unique="txtChatbotName"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="namespace"
                component={renderNamespaceField}
                forWrite
                label="Namespace"
                disabled={!this.hasWritePermission()}
                data-unique="txtChatbotNamespace"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="tags"
                component={renderTagField}
                label="Tags"
                disabled={!this.hasWritePermission()}
                data-unique="tagChatbotTags"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="description"
                component={renderTextArea}
                label="Chatbot Description"
                rows={3}
                disabled={!this.hasWritePermission()}
                data-unique="txtChatbotDescription"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <AvatarSelector
                label="Chatbot Avatar"
                helperText="Select a picture used as Avatar for this chatbot"
                values={values}
                change={change}
                disabled={!this.hasWritePermission()}
                data-unique="selChatbotAvatar"
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name="allowHtmlDisplay"
                component={renderCheckbox}
                label="Allow Markdown and HTML Display"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                helperText="Enable this option to render Markdown and HTML content in live chat and transcript, otherwise it will be rendered as plain text."
                data-unique="chkChatbotAllowHtmlDisplay"
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name="mediaDownload"
                component={renderCheckbox}
                label="Download media attachment files to be processed"
                type="checkbox"
                disabled={!this.hasWritePermission()}
                data-unique="chkChatbotMediaDownload"
              />
            </GridItem>
            <GridItem xs={12}>
              <Text muted>{formatCreatedByAndLastChange(chatbot)}</Text>
            </GridItem>
          </GridContainer>),
          locationPrefix: `/chatbots/view/${chatbot.id}/settings/basic`,
          dataUnique: 'tabChatbotSettingsBasic'
        },
        {
          tabName: 'Retry & Quotas',
          tabContent: this.renderSettingsFormTab(chatbot, 'redo', ({ form: { change }, values }) => <GridContainer>
            <GridItem xs={12}>
              <Text header>Retry Test Step</Text>
            </GridItem>
            <GridItem xs={12}>
              <Text muted>Configure retry behaviour when a test step is failing</Text>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryUserSaysNumRetries"
                component={renderIntField}
                label="Retry Count"
                parse={parseInteger}
                format={undefined}
                validate={minValue(0)}
                disabled={!this.hasWritePermission()}
                data-unique="intChatbotRetryUserSaysNumRetries"
                helperText="Number of retries in case a retry-able error has been identified when sending user input"
              />
            </GridItem>
            <OnChange name="retryUserSaysNumRetries">
              {(value, previous) => {
                if (!value || value === 0) {
                  change('retryUserSaysFactor', undefined)
                  change('retryUserSaysMinTimeout', undefined)
                  change('retryUserSaysOnErrorRegexp', undefined)
                }
              }}
            </OnChange>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryUserSaysFactor"
                component={renderIntField}
                label="Exponential increase of retries"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission() || !values.retryUserSaysNumRetries}
                data-unique="intChatbotRetryUserSaysFactor"
                helperText="You can decide to increase wait times between retries by applying a factor higher than 1 for calculating the time to wait for the next retry"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryUserSaysMinTimeout"
                component={renderIntField}
                label="Timeout to start the next retry (ms)"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission() || !values.retryUserSaysNumRetries}
                data-unique="intChatbotRetryUserSaysMinTimeout"
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="retryUserSaysOnErrorRegexp"
                component={renderAutoSuggest}
                validate={conditional}
                label="Error messages to retry (regexp)"
                disabled={!this.hasWritePermission() || !values.retryUserSaysNumRetries}
                data-unique="asChatbotRetryUserSaysOnErrorRegexp"
                helperText="Configure some error texts (or regular expressions) to trigger the retry behaviour - a simple substring matching on the error message will work in most cases"
              />
            </GridItem>
            <GridItem xs={12}>
              <Text header>Retry Test Case on Progress</Text>
            </GridItem>
            <GridItem xs={12}>
              <Text muted>Configure retry behaviour when a Test Case fails during the progress</Text>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryConvoNumRetries"
                component={renderIntField}
                label="Retry Count"
                parse={parseInteger}
                format={undefined}
                validate={minValue(0)}
                disabled={!this.hasWritePermission()}
                data-unique="intChatbotConvoNumRetries"
                helperText="Number of retries in case a retry-able error has been identified when sending user input"
              />
            </GridItem>
            <OnChange name="retryConvoNumRetries">
              {(value, previous) => {
                if (!value || value === 0) {
                  change('retryConvoFactor', undefined)
                  change('retryConvoOnErrorRegexp', undefined)
                }
              }}
            </OnChange>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryConvoFactor"
                component={renderIntField}
                label="Exponential increase of retries"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission() || !values.retryConvoNumRetries}
                data-unique="intChatbotRetryConvoFactor"
                helperText="You can decide to increase wait times between retries by applying a factor higher than 1 for calculating the time to wait for the next retry"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryConvoMinTimeout"
                component={renderIntField}
                label="Timeout to start the next retry (ms)"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission() || !values.retryConvoNumRetries}
                data-unique="intChatbotRetryConvoMinTimeout"
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="retryConvoOnErrorRegexp"
                component={renderAutoSuggest}
                validate={conditional}
                label="Error messages to retry (regexp)"
                disabled={!this.hasWritePermission() || !values.retryConvoNumRetries}
                data-unique="asChatbotRetryConvoOnErrorRegexp"
                helperText="Configure some error texts (or regular expressions) to trigger the retry behaviour - a simple substring matching on the error message will work in most cases"
              />
            </GridItem>
            <GridItem xs={12}>
              <Text header>Retry Test Case on Start</Text>
            </GridItem>
            <GridItem xs={12}>
              <Text muted>Configure retry behaviour when a Test Case fails on start</Text>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryStartNumRetries"
                component={renderIntField}
                label="Retry Count"
                parse={parseInteger}
                format={undefined}
                validate={minValue(0)}
                disabled={!this.hasWritePermission()}
                data-unique="intChatbotStartNumRetries"
                helperText="Number of retries in case a retry-able error has been identified when sending user input"
              />
            </GridItem>
            <OnChange name="retryStartNumRetries">
              {(value, previous) => {
                if (!value || value === 0) {
                  change('retryStartFactor', undefined)
                  change('retryStartOnErrorRegexp', undefined)
                }
              }}
            </OnChange>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryStartFactor"
                component={renderIntField}
                label="Exponential increase of retries"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission() || !values.retryStartNumRetries}
                data-unique="intChatbotRetryStartFactor"
                helperText="You can decide to increase wait times between retries by applying a factor higher than 1 for calculating the time to wait for the next retry"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="retryStartMinTimeout"
                component={renderIntField}
                label="Timeout to start the next retry (ms)"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission() || !values.retryStartNumRetries}
                data-unique="intChatbotRetryStartMinTimeout"
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="retryStartOnErrorRegexp"
                component={renderAutoSuggest}
                validate={conditional}
                label="Error messages to retry (regexp)"
                disabled={!this.hasWritePermission() || !values.retryStartNumRetries}
                data-unique="asChatbotRetryStartOnErrorRegexp"
                helperText="Configure some error texts (or regular expressions) to trigger the retry behaviour - a simple substring matching on the error message will work in most cases"
              />
            </GridItem>
            <GridItem xs={12}>
              <Text header>Quotas</Text>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="rateLimitUserSaysPerSecond"
                component={renderIntField}
                label="Maximum Usage Quota (per Second)"
                parse={parseInteger}
                format={undefined}
                validate={composeValidators(minValue(1))}
                disabled={!this.hasWritePermission()}
                data-unique="intRateLimitUserSaysPerSecond"
                helperText="Maximum number of user inputs (or other API calls) per second sent by Botium"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="rateLimitUserSaysPerMinute"
                component={renderIntField}
                label="Maximum Usage Quota (per Minute)"
                parse={parseInteger}
                format={undefined}
                validate={composeValidators(minValue(1))}
                disabled={!this.hasWritePermission()}
                data-unique="intRateLimitUserSaysPerMinute"
                helperText="Maximum number of user inputs (or other API calls) per minute sent by Botium"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Field
                name="rateLimitUserSaysPerHour"
                component={renderIntField}
                label="Maximum Usage Quota (per Hour)"
                parse={parseInteger}
                format={undefined}
                validate={composeValidators(minValue(1))}
                disabled={!this.hasWritePermission()}
                data-unique="intRateLimitUserSaysPerHour"
                helperText="Maximum number of user inputs (or other API calls) per hour sent by Botium"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="rateLimitGroup"
                component={renderTextField}
                label="Usage Quota Group"
                disabled={!this.hasWritePermission()}
                data-unique="txtRateLimitGroup"
                helperText="The usage quota is applied for this chatbot only (shared across test sessions), or you can group it to enforce the quota with other chatbots."
              />
            </GridItem>
            <GridItem xs={12}>
              <Text header>Bot Response</Text>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="waitForBotTimeout"
                component={renderIntField}
                label="Bot Response timeout (ms)"
                parse={parseInteger}
                format={undefined}
                disabled={!this.hasWritePermission()}
                data-unique="intChatbotWaitForBotTimeout"
                helperText="Time to wait for bot to answer - without any response within this time period, the test case fails"
              />
            </GridItem>
          </GridContainer>,
            () => <>
              <Button secondary
                onClick={() => mutateResetChatbotRates({ variables: { id: chatbot.id } })}
                data-unique="btnResetChatbotRates"
              >
                <ShowIcon icon="ban" />
                Reset Usage Quota Counters
              </Button>
            </>),
          locationPrefix: `/chatbots/view/${chatbot.id}/settings/redo`,
          dataUnique: 'tabChatbotSettingsRedo'
        },
        !license.shared && {
          tabName: 'Environment Settings',
          tabContent: this.renderSettingsFormTab(chatbot, 'advanced', ({ form: { mutators: { push, pop } } }) => <GridContainer>
            <GridItem xs={12}>
              <Text header>System Environment Variables</Text>
            </GridItem>
            <GridItem xs={12}>
              <EnvsSwitch push={push} pop={pop} disabled={!this.hasWritePermission()} />
            </GridItem>
            <GridItem xs={12} largeMarginTop>
              <Text header>Botium Sources Settings</Text>
            </GridItem>
            <GridItem xs={12}>
              <SourcesSwitch push={push} pop={pop} disabled={!this.hasWritePermission()} />
            </GridItem>
          </GridContainer>),
          locationPrefix: `/chatbots/view/${chatbot.id}/settings/advanced`,
          dataUnique: 'tabChatbotSettingsAdvanced'
        },
        {
          tabName: 'Import/Export',
          tabContent: this.renderSettingsFormTab(chatbot, 'import', ({ form: { change, mutators: { setFieldTouched } }, values }) => <GridContainer>
            <GridItem xs={12}>
              <Text header>Import/Export Connector Settings</Text>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <Field
                    name="fileuploadbotiumjson"
                    component={renderFileUpload}
                    accept=".json"
                    values={values}
                    change={change}
                    label="Select/Drop existing Botium Core botium.json file to import"
                    data-unique="fileChatbotBotiumJson"
                    onFileLoaded={(filename, filecontent) => {
                      try {
                        const asJson = JSON.parse(atob(filecontent))

                        const changeField = (name, value) => {
                          change(name, value)
                          setFieldTouched(name, true)
                        }

                        const { capabilities, sources, envs } = ImportBotiumJson(asJson)
                        const capsFiltered = capabilities.filter(cap => cap.name !== 'PROJECTNAME' && cap.name !== 'TESTSESSIONNAME')
                        changeField('capabilities', capsFiltered)
                        changeField('sources', sources)
                        changeField('envs', envs)

                        changeField('connector', (capabilities.find(cap => cap.name === 'CONTAINERMODE') || { stringValue: '' }).stringValue)

                        ConnectorPrepareForm(settings.allconnectors, values, capsFiltered, changeField)

                        const projectName = capabilities.find(cap => cap.name === 'PROJECTNAME')
                        if (projectName && !values.name) {
                          changeField('name', projectName.stringValue)
                        }

                        setAlertSuccessMessage(`Read capabilities from file ${filename}`)
                      } catch (err) {
                        setAlertErrorMessage(`Reading capabilities from file ${filename} failed`, err)
                      }
                      change('fileuploadbotiumjson', null)
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            {values.connector === 'simplerest' &&
              <GridItem xs={12}>
                <Field
                  name="fileuploadpostwoman"
                  component={renderFileUpload}
                  data-unique="fileChatbotPostWoman"
                  accept=".json"
                  values={values}
                  change={change}
                  label="Select/Drop existing Postwoman collection file to import"
                  helperText="Save the Postwoman request to a collection and export it. The first request from the root folder of the collection will be imported."
                  onFileLoaded={(filename, filecontent) => {
                    try {
                      const asJson = JSON.parse(atob(filecontent))
                      const fields = importPostwoman(asJson)

                      for (const name of Object.keys(fields)) {
                        change(name, fields[name])
                        setFieldTouched(name, true)
                      }

                      setAlertSuccessMessage(`Read Postwoman request from file ${filename}`)
                    } catch (err) {
                      setAlertErrorMessage(`Reading Postwoman request from file ${filename} failed`, err)
                    }
                    change('fileuploadpostwoman', null)
                  }}
                />
              </GridItem>
            }
            {values.connector === 'simplerest' &&
              <GridItem xs={12}>
                <Field
                  name="fileuploadpostman"
                  component={renderFileUpload}
                  data-unique="fileChatbotPostMan"
                  accept=".json"
                  values={values}
                  change={change}
                  label="Select/Drop existing Postman collection file to import"
                  helperText="Save the Postman request to a collection and export it. The first request from the root folder of the collection will be imported."
                  onFileLoaded={(filename, filecontent) => {
                    try {
                      const asJson = JSON.parse(atob(filecontent))

                      const fields = importPostman(asJson)

                      for (const name of Object.keys(fields)) {
                        change(name, fields[name])
                        setFieldTouched(name, true)
                      }

                      setAlertSuccessMessage(`Read Postman request from file ${filename}`)
                    } catch (err) {
                      setAlertErrorMessage(`Reading Postman request from file ${filename} failed`, err)
                    }
                    change('fileuploadpostman', null)
                  }}
                />

              </GridItem>
            }
          </GridContainer>,
            ({ values }) => <>
              <Button
                secondary
                onClick={() => {
                  ConnectorPrepareCaps(getConnector(values.connector) || { name: values.connector }, values, values.capabilities, connectorSettingsAdvancedMode)
                  const botiumJson = ExportBotiumJson({ capabilities: [{ name: 'PROJECTNAME', stringValue: values.name }, ...values.capabilities], sources: values.sources, envs: values.envs })
                  download(JSON.stringify(botiumJson, null, 2), 'botium.json', 'application/json')
                  setAlertSuccessMessage(`botium.json exported`)
                }}
                data-unique="btnChatbotExportJson"
              >
                <ShowIcon icon="cloud-download-alt" />
                Export botium.json to use with Botium Core
              </Button>
            </>
          ),
          locationPrefix: `/chatbots/view/${chatbot.id}/settings/import`,
          dataUnique: 'tabChatbotSettingsImport'
        },
        {
          tabName: 'Danger Zone',
          tabContent: <GridContainer>
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text danger lg padding><ShowIcon icon="trash" /></Text>
                <GridContainer>
                  <GridItem xs={12}>
                    <Text bold>Delete Chatbot</Text>
                  </GridItem>
                  <GridItem xs={12}>
                    <Text>Remove this Chatbot and its configuration settings</Text>
                  </GridItem>
                </GridContainer>
                <Mutation
                  mutation={DELETE_CHATBOT}
                  onCompleted={data => {
                    removeRecentListEntry({
                      url: `/chatbots/view/${chatbot.id}`
                    })
                    setAlertSuccessMessage('Chatbot deleted')
                    this.props.history.push('/chatbots')
                  }}
                  onError={error => {
                    setAlertErrorMessage(
                      `Chatbot deletion failed`,
                      error,
                    )
                  }}
                  refetchQueries={[
                    ...RefetchChatbotQueries()
                  ]}
                >
                  {(deleteChatbot, { loading, error }) => (
                    <ConfirmationButton
                      confirmationText={`When deleting the Chatbot "${chatbot.name}", all configuration settings are removed. If it has been used in Test Sessions or Test Projects already, those will miss configuration as well. Are you sure you want to delete it ?`}
                      requireCheck={true}
                      small
                      minWidth
                      disabled={!hasPermission(user, 'CHATBOTS_DELETE')}
                      danger
                      onClick={() => {
                        deleteChatbot({
                          variables: { id: chatbot.id },
                        })
                      }}
                      data-unique="btnChatbotDelete"
                    >
                      Delete
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
          </GridContainer>,
          locationPrefix: `/chatbots/view/${chatbot.id}/settings/danger`,
          dataUnique: 'tabChatbotSettingsDanger'
        }
      ].filter(s => s)}
    />)
  }

  render() {
    const { chatbot, user } = this.props

    return (<CustomTabs
      name={`tabChatbot_${chatbot.id}`}
      headerColor="info"
      tabs={[
        {
          tabName: 'Overview',
          tabIcon: <ShowIcon icon="robot" />,
          tabContent: this.renderDashboard(chatbot),
          locationPrefix: `/chatbots/view/${chatbot.id}/dashboard`,
          dataUnique: 'tabChatbotDashboard'
        },
        this.hasReadPermission() && hasAnyPermission(user, ['CHATBOTS_CREATE', 'CHATBOTS_UPDATE', 'CHATBOTS_DELETE']) && {
          tabName: 'Configuration',
          tabRight: true,
          tabIcon: <ShowIcon icon="wrench" />,
          locationPrefix: `/chatbots/view/${chatbot.id}/settings`,
          tabContent: this.renderSettingsForm(chatbot),
          dataUnique: 'tabChatbotSettings'
        }
      ].filter(t => t)}
    />)
  }
}

const ChatbotComponent = compose(
  withStyles(chatbotsStyle),
  connect(
    state => ({
      user: state.token.user,
      settings: state.settings,
      license: state.settings.license,
    }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry }
  ),
  graphql(RESET_CHATBOT_RATES, {
    props: ({ mutate }) => ({
      mutateResetChatbotRates: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('Usage Quota Counters reset')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Usage Quota Counters resetting failed', error)
      }
    })
  })
)(Chatbot)

export default function ({ match, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.id && (
          <Query query={CHATBOT_QUERY} variables={{ id: match.params.id }}>
            {(queryResult) => <QueryStatus {...queryResult} query="chatbot" card>{(data) =>
              <ChatbotComponent match={match} chatbot={data.chatbot} {...rest} />
            }</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
}
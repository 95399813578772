import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import { CustomTextField, DangerSpan } from 'components/Form/Form'
import MenuItem from 'components/Menu/MenuItem'
import Button from 'components/Button/Button'
import ShowIcon from 'components/Icon/ShowIcon'
import { FormSpy } from 'react-final-form'

import selectStyle from 'assets/jss/material-dashboard-react/components/selectStyle.jsx'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import Text from 'components/Typography/Text'
import AvatarImage from 'components/Avatar/AvatarImage'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import FlagIcon from 'components/Icon/FlagIcon'
import ErrorFormat from 'components/Info/ErrorFormat'
import AgentStatusIcon from 'components/Icon/AgentStatusIcon'
import ImageIcon from 'components/Icon/ImageIcon'
import classNames from 'classnames'
import Divider from 'components/Divider/Divider'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import PerfectScrollbar from 'perfect-scrollbar'

class Selector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      inputValue: '',
      value: null,
    }
    this.handleClose = this.handleClose.bind()
    this.filterInputRef = React.createRef()
    this.filterInputWrapperRef = React.createRef()
  }

  handleOpen = () => {
    const { multiple, items } = this.props
    this.setState({
      open: true,
      inputValue: ''
    })
    setTimeout(() => this.filterInputRef && this.filterInputRef.current && this.filterInputRef.current.focus(), 100)
    setTimeout(() => {
      const listBoxEl = document.querySelector('ul[role="listbox"]')
      new PerfectScrollbar(listBoxEl.parentElement, { suppressScrollX: true })
      if (!multiple) {
        const valElement = this.state.value && document.querySelector(`[data-value="${this.state.value}"]`)
        if(valElement) {
          const itemIndex = items.findIndex(item => item.key === this.state.value)
          valElement.parentElement.parentElement.scrollTop = itemIndex * 40
        }
      }
    }
    , 100)
  }

  handleClose = (event) => { 
    if (event.key && event.key === 'Escape') {
      this.setState({
        open: false
      })
    } else if (event.target && (event.target.ariaHidden || (event.target.attributes && 'aria-hidden' in event.target.attributes)) && this.state.open) {
      this.setState({
        open: false
      })
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleClose)
    document.addEventListener('mousedown', this.handleClose)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleClose)
    document.removeEventListener('mousedown', this.handleClose)
  }

  render() {
      const {
        input: { name, value, onChange, multiple, ...restInput },
        meta,
        helperText,
        label,
        formControlProps,
        valueKeyMap,
        children,
        onOpen,
        onClose,
        items,
        dispatch,
        classes,
        filterable,
        loading,
        form,
        error,
        disabled,
        defaultValueInit,
        hideClearButton,
        ...rest
      } = this.props

    const showError = meta && ((meta.submitError && !meta.dirtySinceLastSubmit) || (meta.error && meta.touched))
    const showFilter = (!items || items.length < 10) ? false : filterable

    const filterInput = (<MenuItem
      disableTouchRipple={true}
      key={'filterInput'}
      value={''}
      id={name}
      className={classes.inputposition}
    >
      <CustomTextField      
        input={{
          ref: this.filterInputRef,
          name: 'selectorFilter',
          value: this.state.inputValue,
          onChange: (e) => {
            this.setState({ inputValue: e.target.value.toLowerCase() })
          },
          onKeyDown: e => {
            if (e.key === 'ArrowDown') {
              this.filterInputRef.current.parentElement.parentElement.parentElement.focus()
            }
          },
          onClick: (e) => {
            this.setState({
              open: true
            })
          }
        }}
        disableBorder
        placeholder="Filter..."
        data-unique={`${rest['data-unique']}FilterInput`}
        className={classes.inputFilter}
      />
    </MenuItem>)

    const filteredItems = () => {
      if (items) {
        if (showFilter && this.state.inputValue && this.state.inputValue.length > 0) {
          return items.filter(c => (c.label || (c.chatbot && (c.chatbot.description || c.chatbot.name)) || (c.agent && c.agent.name) || c.key).toLowerCase().includes(this.state.inputValue))
        }
        return items
      }
      return []
    }

    const _itemToString = (item) => item ? (item.label || (item.chatbot && (item.chatbot.description || item.chatbot.name)) || (item.agent && item.agent.name) || item.key) : null
    const _itemToValue = (item) => item ? (item.value || item.key) : null

    const renderItem = (item, keyPrefix) => (
      <MenuItem key={keyPrefix ? `${keyPrefix}_${item.key}` : item.key} value={item.key} className={classNames({
        [classes.selectMenuItem]: true,
        [classes.selectMenuItemWithImage]: item.chatbot || item.icon || item.flagIcon || item.image || item.agent
      })}>
        {!loading && !error && <GridContainer autoHeight noMargin nounset>
          {(item.chatbot || item.icon || item.flagIcon || item.image || item.agent) &&
            <GridItem middle>
              {item.chatbot && <AvatarImage avatar={item.chatbot.avatar} containermode={item.chatbot.containermode} chatbotId={item.chatbot.id} />}
              {item.icon && <ShowIcon icon={item.icon} />}
              {item.flagIcon && <FlagIcon code={item.flagIcon} />}
              {item.image && <ImageIcon sm src={item.image} alt={_itemToString(item)} />}
              {item.agent && <AgentStatusIcon agent={item.agent} />}
            </GridItem>
          }
          <GridItem middle style={(_.isNil(item.chatbot) && _.isNil(item.icon) && _.isNil(item.flagIcon) && _.isNil(item.image) && _.isNil(item.agent) ? { width: 'calc(100% - 16px)' } : { width: 'calc(100% - 50px)' } )}>
            {!item.divider && <Text primary bold500 ellipsis>{_itemToString(item)}</Text>}
            {item.divider && <Divider />}
          </GridItem>
        </GridContainer>
        }
        {error && <GridContainer autoHeight noMargin>
          <GridItem middle>
            <ErrorFormat err={error} />
          </GridItem>
        </GridContainer>
        }
        {loading && <GridContainer autoHeight noMargin>
          <GridItem middle>
            <LoadingIndicator />
          </GridItem>
        </GridContainer>
        }
      </MenuItem>
    )

    const renderItems = () => {
      const items = filteredItems()
      if (multiple) {
        const inValues = valItem => (value || []).filter(item => _.isEqual(item, _itemToValue(valItem))).length === 1
        const selectedItems = items.filter(item => inValues(item))
        if (selectedItems.length > 0) {
          return [
            <Text padding bold>Selected items:</Text>,
            ...selectedItems.map(item => renderItem(item, 'selected')),
            <Divider small />,
            ...items.map(item => renderItem(item)),
          ]
        }
      }
      return items.map(item => renderItem(item))
    }

    let renderValue = null
    if (multiple) {
      renderValue = (selected) => {
        return <div className={classes.multipleSelectInputText}>{`${selected.length} selected`}</div>
      }
    }

    const _toSelectValue = (value) => {
      if (multiple) {
        return (value || []).map(v => valueKeyMap ? (_.isFunction(valueKeyMap) ? valueKeyMap(v) : valueKeyMap[v]) : v)
      } else {
        return _.isNil(value) ? null : (valueKeyMap ? (_.isFunction(valueKeyMap) ? valueKeyMap(value) : valueKeyMap[value]) : value)
      }
    }

    return (
      <FormControl
        {...formControlProps}
        error={!!showError}
        fullWidth
        margin="normal"
      >
        {label &&
          <InputLabel htmlFor={name} id="demo" disabled={disabled || _.isUndefined(items) || items.length === 0} shrink={true} classes={{ root: classes.inputLabelRoot, shrink: classes.inputLabelShrink, formControl: classes.inputLabelformControl }} FormLabelClasses={{ disabled: classes.inputLabeldisabled }}>
            {label}
          </InputLabel>
        }
        <Select
          {...rest}
          MenuProps={{
            classes: {
              paper: classes.paper,
            }
          }}
          classes={{
            select: classNames({
              [classes.filterableSelect]: true,
              [classes.filterableSelectMultiple]: multiple,
              [classes.filterableSelectdisabled]: disabled || _.isUndefined(items) || items.length === 0
            }),
            root: classNames({
              [classes.filterableSelectRoot]: true,
              [classes.disabled]: disabled || _.isUndefined(items) || items.length === 0,
              [classes.filterableSelectRootdisabled]: disabled || _.isUndefined(items) || items.length === 0
            }),
            icon: classes.filterableSelectIcon
          }}
          data-loading-state={!_.isUndefined(items) && items.length > 0 ? 'READY' : 'EMPTY'}
          children={showFilter ? [filterInput, ...renderItems()] : [...renderItems()]}
          name={name}
          open={this.state.open}
          disabled={disabled || _.isUndefined(items) || items.length === 0}
          onChange={(e) => {
            if (disabled) return
            if (!multiple && e.target.value !== this.state.inputValue) {
              this.setState({
                open: false
              })
            }
            this.setState({ value: e.target.value })
            if (valueKeyMap) {
              if (multiple) {
                e.target.value = (e.target.value || []).map(key => items.find(i => i.key === key)).filter(i => i).map(i => _itemToValue(i))
              } else {
                e.target.value = _itemToValue(items.find(i => i.key === e.target.value))
                this.setState({ value: _itemToValue(items.find(i => i.key === e.target.value)) })
              }
            }
            onChange(e)
          }}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          inputProps={{ 'data-unique': `${rest['data-unique']}Input`, ...restInput }}
          value={ _toSelectValue(value)}
          title={name}
          id={name}
          multiple={multiple}
          renderValue={renderValue || null}
          className={classes.underline}
        />
        {!disabled && form && <FormSpy subscription={{ values: true, form: true }} render={({ values, form: { change } }) => {
          const value = _.get(values, name)
          if(!hideClearButton) {
            if (_.isString(value) && value.length > 0) {
              return <Button aria-label="Clear" title="Clear" className={value === 'new' ? classes.clearButtonDesabled : classes.filterableSelectClearButtonString} justIcon  onClick={() => change(name, _.isNil(defaultValueInit)? '' : defaultValueInit)} ><ShowIcon className={classes.filterableSelectClearButtonIcon} icon="times" /></Button>
            } else if (_.isArray(value) && value.length > 0) {
              return <Button aria-label="Clear" title="Clear" justIcon onClick={() => change(name, _.isNil(defaultValueInit)? [] : defaultValueInit)} className={classes.filterableSelectClearButtonArray}><ShowIcon className={classes.filterableSelectClearButtonIcon} icon="times" /></Button>
            } 
          }  
          return null
        }} />}
        {(_.isUndefined(items) || items.length === 0) && <div className={classes.noEntriesText}>No entries</div>}
        {showError && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}><DangerSpan>{meta.error || meta.submitError}</DangerSpan></FormHelperText>}
        {helperText && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{helperText}</FormHelperText>}
      </FormControl>)
  }
}

Selector.defaultProps = {
  filterable: true
}

export default withStyles(selectStyle)(Selector)

import React from 'react'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Badge from 'components/Chip/Badge'
import tileStyle from 'assets/jss/material-dashboard-react/components/tileStyle'

//imgages
import imgFactChecker from 'assets/img/llm/llmfactchecker.png'
import imgMisuseChecker from 'assets/img/llm/llmmisusechecker.png'
import imgSecurityChecker from 'assets/img/llm/llmsecuritychecker.png'
import imgBiasChecker from 'assets/img/llm/llmbiaschecker.png'
import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import { showFactCheckerPopup } from 'actions/alert'


class LLMProjectTypes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }


  hasTestProjectPermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTPROJECTS_SELECT'])
  }

  render() {
    const { classes, history, showFactCheckerPopup, features } = this.props
    const tiles = [
      {
        unique: 'FactCheck',
        link: '/factcheck',
        active: this.hasTestProjectPermission(),
        header: 'FactCheck',
        text: 'Reveal the accuracy of an LLM powered bot against a source of truth. Discover reasons why an LLM powered bot may provide an inaccurate answer.',
        onClick: () => {
          if(features.factCheckerEnabled) {
            history.push('/factcheck')
          } else {
            showFactCheckerPopup(true)
          }
        },
        badge: '',
        img: imgFactChecker
      },
      {
        unique: 'llmMisuseChecker',
        link: '/misusecheck',
        active: false,
        header: 'Misuse Test',
        text: 'Prevent potential misuse of LLM powered bots. Safeguard your enterprise by identifying sensitive questions LLM powered bots may answer.',
        onClick: () => {history.push('/misusecheck')},
        badge: 'COMING SOON',
        img: imgMisuseChecker
      },
      {
        unique: 'llmSecurityChecker',
        link: '/securitycheck',
        active: false,
        header: 'Privacy, Security and Regulation',
        text: 'Navigate the complexities of LLM powered bots with confidence. Ensures compliance by detecting inadvertent leaks and mitigating risks.',
        badge: 'COMING SOON',
        img: imgSecurityChecker
      },
      {
        unique: 'llmBiasChecker',
        link: '/biascheck',
        active: false,
        header: 'Biases',
        text: 'Uncover the hidden biases within LLM powered bots with ease. Exposes any inherent biases present in training data, promoting ethical usage.',
        badge: 'COMING SOON',
        img: imgBiasChecker
      }
    ]

    const renderCard = (t) => {
      return (
        <Card onClick={t.onClick} className={t.active ? classes.CardCard : classes.CardCardDisabled} tabIndex={0}>
          <CardBody className={classes.CardBody}>
            {t.badge === 'FREE BETA' && <CornerBadgeBeta badgeContent={t.badge}></CornerBadgeBeta>}
            {t.badge === 'COMING SOON' && <CornerBadgeSoon badgeContent={t.badge}></CornerBadgeSoon>}
            {t.badge !== 'FREE BETA' && t.badge !== 'COMING SOON' && <div></div>}
            <GridContainer classes={{
                grid: classes.tileCard
              }}>
              <GridItem xs={12}><img alt={t.header} src={t.img} className={classNames({ [classes.tileCardImage]: true, [classes.tileCardImageInactive]: !t.active })}/></GridItem>
              <GridItem xs={12}>
                <div className={classNames({ [classes.tileHeader + ' ' + classes.tileHeaderimgCard]: true, [classes.tileSubHeaderimgCardInactive]: !t.active})}>{t.header}</div>
              </GridItem>
              <GridItem xs={12} className={classNames({ [classes.tileSubHeaderimgCard]: true, [classes.tileSubHeaderimgCardInactive]: !t.active})}>{t.text}</GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      )
    }

    return (<>
      <GridContainer>
        {tiles.map((t, index) => (<GridItem middle key={index} md={6} lg={3}>
          <div data-unique={t.unique}>
            {t.active && renderCard(t)}
            {!t.active && renderCard(t)}
          </div>
        </GridItem>))}
      </GridContainer>
      </>)
  }
}
const CornerBadgeBeta = withStyles(theme => {
  return {
    root: {
      position: 'absolute',
      right: -5,
      top: -5,
      zIndex: 1,
      overflow: 'hidden',
      width: 96,
      height: 100,
      textAlign: 'center',
    },
    badge: {
      fontSize: 10,
      fontWeight: 'bold',
      color: theme.colors.common.white,
      textTransform: 'uppercase',
      background: theme.colors.common.llmBlue,
      textAlign: 'center',
      lineHeight: 1.6,
      transform: 'rotate(45deg)',
      width: 160,
      display: 'block',
      position: 'absolute',
      paddingLeft: 50,
      top: 12,
      right: -45,
    }
  }})(Badge)

  const CornerBadgeSoon = withStyles(theme => {
    return {
      root: {
        position: 'absolute',
        right: -5,
        top: -5,
        zIndex: 1,
        overflow: 'hidden',
        width: 96,
        height: 100,
        textAlign: 'center',
      },
      badge: {
        fontSize: 10,
        fontWeight: 'bold',
        color: theme.colors.common.primary,
        textTransform: 'uppercase',
        background: theme.colors.common.warning,
        textAlign: 'center',
        lineHeight: 1.6,
        transform: 'rotate(45deg)',
        width: 160,
        display: 'block',
        position: 'absolute',
        paddingLeft: 50,
        top: 12,
        right: -45,
      }
    }})(Badge)
export default connect(
  state => ({ user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { showFactCheckerPopup }

)(withStyles(tileStyle)(withRouter(LLMProjectTypes)))

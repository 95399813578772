import React from 'react'
import { connect } from 'react-redux'
import {Query, compose, graphql, Mutation} from 'react-apollo'
import { NavLink, Switch, Route, withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import config from 'config'
import _ from 'lodash'
import moment from 'moment'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import Divider from 'components/Divider/Divider.js'
import Tooltip from 'components/Tooltip/Tooltip'
import SimpleTable from 'components/Table/Table.jsx'

// core components
import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'
import SimpleBar from 'components/Stats/Charts/SimpleBar'
import Button from 'components/Button/Button'
import LinkButton from 'components/Button/LinkButton'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import DateFormat from 'components/Info/DateFormat'
import { CustomTextField, CustomCheckbox } from 'components/Form/Form'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import CustomTabs from 'components/Tabs/CustomTabs'
import AvatarImage from 'components/Avatar/AvatarImage'
import Chip from 'components/Chip/Chip'
import MessageBox from 'components/Info/MessageBox'
import ObjectChips from 'components/Chip/ObjectChips'


import ShowIcon from 'components/Icon/ShowIcon'

import { downloadfile } from 'helper/downloadHelper'

import { clearDashboardSettings, setDashboardSettings } from 'actions/dashboard'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import { renderConfusionMatrix } from './components/ConfusionMatrix'
import { renderConfusionMatrixUtteranceList } from './components/ConfusionMatrixUtteranceList'
import { renderConfidenceThresholdChart } from './components/ConfidenceThresholdChart'
import { renderAdvices } from './components/Advices'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'

import {
  TRAINER_SESSION_ROOT_QUERY,

  CHART_INTENT_CONFIDENCE_DISTRIBUTION_QUERY,
  CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_QUERY,
  CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_SECONDARY_QUERY,
  CHART_INTENT_QUERY,
  CHART_INTENT_SECONDARY_QUERY,
  CHART_ENTITY_QUERY,
  CHART_ENTITY_SECONDARY_QUERY,

  OVERVIEW_INTENT_COMPARE_QUERY,
  OVERVIEW_ENTITY_COMPARE_QUERY,
  LIST_UNEXPECTED_INTENT_QUERY,
  LIST_UNEXPECTED_INTENT_SECONDARY_QUERY,
  LIST_UNEXPECTED_INTENT_BY_ALTERNATIVE_LIST_QUERY,
  LIST_UNEXPECTED_INTENT_BY_ALTERNATIVE_LIST_SECONDARY_QUERY,
  PER_ACTUAL_INTENTS_QUERY,
  TRAINER_SESSION_ROOT_SUBSCRIPTION
} from '../gql'

import {
  MINIMUM_UTTERANCES_PER_INTENT,
  MINIMUM_UTTERANCES_PER_ENTITY,
  mergeArraysDeep,
  mergeArrays
} from 'botium-box-shared/trainer/helper'
import { hasConfusionMatrix, hasIncomprehensionScore, getIncomprehensionScore } from 'botium-box-shared/trainer/score'

import { renderProgressOrError } from '../../helper'
import RadarChart from 'components/Stats/Charts/RadarChart'
import BarChart from 'components/Stats/Charts/BarChartAdvanced'
import {
  renderTrending,
  renderTrendingIcon,
  renderTrendingLabel,
  renderTrendingText,
  renderTrendingColor,
  INCOMPREHENSION_IS_BAD,
  toFixedSafe
} from './helper'

import TestProjectDialog from './TestProjectDialog'

import dashboardRoutes from 'routes/coach.jsx'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import ListItem from 'components/List/ListItem/ListItem'
import TestSessionProgress from '../../TestSessions/TestSessionProgress'
import {canWriteNamespace, hasAnyPermission, hasPermission} from 'botium-box-shared/security/permissions'
import {CANCEL_TESTSESSION, DELETE_TESTSESSION, DeleteTestSessionListsFromCache} from '../../TestSessions/gql'
import {removeRecentListEntry} from 'actions/activity'

const testsessionLabel = (testsession) => {
  if (testsession) {
    return testsession.testProject.name + ' / ' + moment(testsession.updatedAt).format('lll')
  }
  return null
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    const chartDef = {
      hideSecondary: false
    }
    this.state = {
      toolbarExpanded: false,
      showPrimarySelectDialog: false,
      showSecondarySelectDialog: false,
      basicExpanded: true,
      downloadsExpanded: false,
      intentConfidenceRisksChart: chartDef,
      entityConfidenceChart: chartDef,
      intentConfidenceDistributionChart: chartDef,
      intentConfidenceDeviationChart: chartDef,
      entityConfidenceDeviationChart: chartDef,
      intentUtteranceDistributionChart: chartDef,
      entityUtteranceDistributionChart: chartDef,
      intentMismatchProbabilityPerUtteranceChart: chartDef,
      testSessionId: props.testSessionId,
      secondaryTestSessionId: props.secondaryTestSessionId
    }
  }

  getRootPath = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return '/' + path[0] + '/' + path[1] + '/' + path[2] + '/' + path[3]
  }

  getTestProjectId = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return path[3]
  }

  hasWritePermission(user, testsession) {
    return hasAnyPermission(user, ['TESTSESSIONS_CREATE', 'TESTSESSIONS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, testsession.namespace)
  }

  hasValue(data, name) {
    for (const e of data) {
      if (_.isNumber(_.get(e, name))) {
        return true
      }
    }

    return false
  }

  sum(array, field) {
    let result = 0
    for (const e of array) {
      result += e[field] || 0
    }

    return result
  }

  avg(array, field) {
    let result = 0
    for (const e of array) {
      result += e[field] || 0
    }

    return result / array.length
  }

  renderOptionalIntentConfidenceSupportWarning(overallStat, useWarning = false) {
    return this.renderOptionalSupportWarning(overallStat, 'intentConfidenceSupported', 'Intent confidence score is not supported', 'No intent confidence identified in test session', useWarning)
  }

  renderOptionalIntentResolutionSupportWarning(overallStat, useWarning = false) {
    return this.renderOptionalSupportWarning(overallStat, 'intentResolutionSupported', 'Intent resolution is not supported', 'No intent resolution in test session', useWarning)
  }

  renderOptionalEntityConfidenceSupportWarning(overallStat, useWarning = false) {
    return this.renderOptionalSupportWarning(overallStat, 'entityConfidenceSupported', 'Entity confidence score is not supported', 'No entity confidence identified in test session', useWarning)
  }

  renderOptionalEntityResolutionSupportWarning(overallStat, useWarning = false) {
    return this.renderOptionalSupportWarning(overallStat, 'entityResolutionSupported', 'Entity resolution is not supported', 'No entity resolution in test session', useWarning)
  }

  renderOptionalSupportWarning(overallStat, field, msgFalse, msgNull, useWarning = false) {
    let message
    if (overallStat[field] === false) {
      message = msgFalse
    } else if (_.isNil(overallStat[field])) {
      message = msgNull
    } else {
      return null
    }

    if (message) {
      if (useWarning) {
        return (
          <Text warning>
            {message}
          </Text>
        )
      } else {
        return (
          <Text info>
            {message}
          </Text>
        )
      }
    }
  }

  renderTabs() {
    const { testSessionId, secondaryTestSessionId, testSessionOverallStat, testSessionSwitch, user, history, license, testSessionDataTemp } = this.props
    const originalTestSessionId = !testSessionSwitch ? testSessionId : secondaryTestSessionId
    const testsession = testSessionDataTemp?.testsession

    return (<CustomTabs
      name={`CoachTabs_${originalTestSessionId}`}
      headerColor="info"
      tabs={[
        {
          tabName: 'Overview',
          tabIcon: <ShowIcon icon="tachometer-alt" />,
          tabContent: this.renderMainTab(),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/dashboard`
        },
        {
          tabName: 'Confusion Matrix',
          tabIcon: <ShowIcon icon="border-none" />,
          // if has intent asserter
          tabContent: testSessionOverallStat.stepsWithIntent ? renderConfusionMatrix(testSessionId, this.getTestProjectId()) : renderConfusionMatrixUtteranceList(testSessionId, this.getTestProjectId()),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/confusionmatrix`
        },
        {
          tabName: 'Suggestions',
          tabIcon: <ShowIcon icon="lightbulb" />,
          tabContent: this.renderAdvices(),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/advice`
        },
        {
          tabName: 'Training Progress',
          tabIcon: <ShowIcon icon="chart-line" />,
          tabContent: this.renderTrainingProgress(),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/progress`
        },
        {
          tabName: 'Mismatch Probability Risks',
          tabIcon: <ShowIcon icon="puzzle-piece" />,
          tabContent: this.renderMismatchProbabilityRisksTab(),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/mismatchprobability`
        },
        {
          tabName: 'Confidence Threshold',
          tabIcon: <ShowIcon icon="wave-square" />,
          tabContent: renderConfidenceThresholdChart(testSessionId, testSessionOverallStat),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/confidencetreshold`
        },
        {
          tabName: 'Download Results',
          tabIcon: <ShowIcon icon="cloud-download-alt" />,
          tabContent: this.renderDownloads(),
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/downloads`
        },
        this.hasWritePermission(user, testsession) && hasPermission(user, 'TESTSESSIONS_DELETE') && {
          tabName: 'Danger Zone',
          tabRightMd: true,
          tabIcon: <ShowIcon icon="exclamation-triangle" />,
          locationPrefix: `${this.getRootPath()}/results/${testSessionId}/danger`,
          dataUnique: 'btnTestSessionNavigationDangerZone',
          tabContent: (
            <GridContainer key="danger">
              {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') &&
                <React.Fragment>
                  <GridItem md={8} lg={4}>
                    <ListItem>
                      <Text lg danger padding><ShowIcon icon="trash" /></Text>

                      <GridContainer nounset>
                        <GridItem md={12}><Text bold>Delete Test Session</Text></GridItem>
                        <GridItem md={12}><Text>This removes the Test Session and its results</Text></GridItem>
                      </GridContainer>
                      <Mutation
                        mutation={DELETE_TESTSESSION}
                        onCompleted={data => {
                          removeRecentListEntry({
                            url: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}`
                          })
                          setAlertSuccessMessage('Test Session deleted')
                          history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}`)
                        }}
                        onError={error => {
                          setAlertErrorMessage('Test session deletion failed', error)
                        }}
                        update={DeleteTestSessionListsFromCache}
                      >
                        {(
                          deleteTestSession,
                          { loading, error },
                        ) => (
                          <ConfirmationButton
                            confirmationText={`When deleting this Test Session, all it's test results are lost. You have to start another Test Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                            requireCheck={true}
                            danger
                            small
                            minWidth
                            onClick={() => {
                              deleteTestSession({
                                variables: { id: testsession.id },
                              })
                            }}
                            data-unique="btnTestSessionDelete"
                          >

                            Delete
                          </ConfirmationButton>
                        )}
                      </Mutation>
                    </ListItem>
                  </GridItem>
                  <GridItem md={8}></GridItem>
                </React.Fragment>
              }
              {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                <React.Fragment>
                  <GridItem md={8} lg={4}><Divider dense /></GridItem>
                  <GridItem md={12} lg={8}></GridItem>
                </React.Fragment>
              }
              {hasPermission(user, 'TESTSESSIONS_CREATE') &&
                <React.Fragment>
                  <GridItem md={8} lg={4}>
                    <ListItem>
                      <Text lg danger padding><ShowIcon icon="power-off" /></Text>
                      <GridContainer nounset>
                        <GridItem md={12}><Text bold>Send Cancellation Request</Text></GridItem>
                        <GridItem md={12}><Text>Send cancellation request to Test Session</Text></GridItem>
                      </GridContainer>
                      <Mutation
                        mutation={CANCEL_TESTSESSION}
                        onCompleted={data => {
                          setAlertSuccessMessage('Sent cancellation request to Test Session')
                          this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                        }}
                        onError={error => {
                          setAlertErrorMessage('Sending cancellation request to Test Session failed', error)
                        }}
                      >
                        {(
                          cancelTestSession,
                          { loading, error },
                        ) => (
                          <TestSessionProgress testSession={testsession}>
                            {({ testSessionProgress }) => (
                              <ConfirmationButton
                                confirmationText={`When cancelling a Test Session, all background processing will be stopped and the test results are not complete. Are you sure you want to cancel it ?`}
                                requireCheck={true}
                                danger
                                small
                                minWidth
                                disabled={testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED'}
                                onClick={() => {
                                  cancelTestSession({
                                    variables: { id: testsession.id },
                                  })
                                }}
                                data-unique="btnTestSessionCancel"
                              >
                                Send
                              </ConfirmationButton>
                            )}
                          </TestSessionProgress>
                        )}
                      </Mutation>
                    </ListItem>
                  </GridItem>
                  <GridItem xs={8}></GridItem>
                </React.Fragment>
              }
              {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                <React.Fragment>
                  <GridItem xs={4}><Divider dense /></GridItem>
                  <GridItem xs={8}></GridItem>
                </React.Fragment>
              }
            </GridContainer>
          ),
        }
      ].filter(t => t)}
    />)
  }

  renderMismatchProbabilityRisksTab() {
    const {
      testSessionHeader
    } = this.props


    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            {testSessionHeader && this.renderIntentMismatchProbabilityPerUtteranceChart()}
          </GridItem>
          <GridItem xs={12}>
            {testSessionHeader && this.renderUnexpectedIntentList()}
          </GridItem>
          <GridItem xs={12}>
            {testSessionHeader && this.renderUnexpectedIntentByAlternativeListList()}
          </GridItem>
        </GridContainer>
      </>
    )
  }

  renderMainTab() {
    const {
      testSessionOverallStat
    } = this.props

    const displayIntentEntityAnalyze =
      testSessionOverallStat.intentResolutionSupported ||
      testSessionOverallStat.intentConfidenceSupported ||
      testSessionOverallStat.entityResolutionSupported ||
      testSessionOverallStat.entityConfidenceSupported

    return (
      <>
        <GridContainer>
          <GridItem xs={12} right>
            {this.renderToolBar()}
          </GridItem>
          <GridItem xs={12}>
            {this.renderStatistics()}
          </GridItem>
          <GridItem xs={12}>
            {this.renderObjectChips()}
          </GridItem>
          {displayIntentEntityAnalyze && <>
            <GridItem xs={12} >
              {this.renderIntentConfidenceDistributionChart()}
            </GridItem>
            <GridItem xs={6}>
              {this.renderIntentProgressTable(true)}
            </GridItem>
            <GridItem xs={6}>
              {this.renderIntentProgressTable(false)}
            </GridItem>
            <GridItem xs={6} marginTopBottom>
              {this.renderIntentConfidenceChart()}
            </GridItem>
            <GridItem xs={6} marginTopBottom>
              {this.renderEntityConfidenceChart()}
            </GridItem>
            <GridItem xs={6} marginTopBottom>
              {this.renderIntentConfidenceDeviationChart()}
            </GridItem>
            <GridItem xs={6} marginTopBottom>
              {this.renderEntityConfidenceDeviationChart()}
            </GridItem>
            <GridItem xs={6} marginTopBottom>
              {this.renderIntentUtteranceDistributionChart()}
            </GridItem>
            <GridItem xs={6} marginTopBottom>
              {this.renderEntityUtteranceDistributionChart()}
            </GridItem>
          </>}
        </GridContainer>
      </>
    )
  }

  renderIntentConfidenceChart() {
    const {
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
      history,
      showDataByExpected,
      classes,
    } = this.props

    const STATE_KEY = 'intentConfidenceRisksChart'

    const onClick = (e) => {
      history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidence/intentname/${encodeURIComponent(e.name)}`)
    }

    let showMoreLinkTo = `${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidencerisks/${showDataByExpected ? 'expected' : 'actual' }`
    if (intentFilter) {
      showMoreLinkTo += `/intentFilter/${intentFilter}`
    }
    if (secondaryTestSessionAvailable) {
      showMoreLinkTo += `/secondaryTestSessionId/${secondaryTestSessionHeader.id}`
    }

    const optionalSupportWarning = this.renderOptionalIntentResolutionSupportWarning(testSessionOverallStat) || this.renderOptionalIntentConfidenceSupportWarning(testSessionOverallStat)

    const renderCard = (chart, hasData, data, hasMore, valueFn, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardIntentConfidence" fullheight >
        <CardHeader>
            <GridContainer>
              <GridItem md={10}>
                  <GridContainer>
                    <GridItem md={1}><Text icon="square" header inline color={optionalSupportWarning ? 'info' : data.length > 0 ? 'warning' : 'success'}>&nbsp;</Text></GridItem>
                    <GridItem md={11}>
                      <Text header inline>Top 10 Intent Confidence Risks</Text>
                      <Text subheader>Shows intents of your test set with weakest average confidence score</Text>
                      <Text>Weak intent confidence is an indicator for the lack of strong training data.</Text>
                    </GridItem>
                  </GridContainer>
              </GridItem>
              <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
                <a
                  tabIndex={0}
                  className={classes.cardHeaderIcon}
                  aria-label="Help"
                  title="Help"
                  data-unique="btnCoachDashboardIntentConfidenceRisksWiki"
                  href="https://support.botium.ai/hc/en-us/articles/4973861647375-Intent-Confidence-Risks"
                  target="blank"
                >
                   <Tooltip title="Help">
                    <Text lg><ShowIcon icon="question-circle" /></Text>
                  </Tooltip>
                </a>
              </GridItem>
            </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          <GridContainer>
            <GridItem xs={12}>
              {!optionalSupportWarning && data.length === 0 && <Text inline>No risks detected</Text>}
              {!optionalSupportWarning && hasData && chart}
            </GridItem>
            {!optionalSupportWarning && data.length > 0 &&
              <GridItem xs={12}>
                <Table disableFooter disableFilter
                    tableHeaderColor="primary"
                    tableHead={['Name', 'Average Confidence']}
                    tableData={data.map((e, i) => {
                      return [
                        () => <><NavLink to={showMoreLinkTo} data-unique={`btnCoachDashboardIntentConfidenceChart`}>{e.name}</NavLink></>,
                        () => <><NumberFormat value={Math.floor(valueFn(e) * 100)} displayType={'text'} /></>,
                      ]
                    })}
                  />
                {hasMore && <GridItem xs={12} center paddingTop>
                  <NavLink
                    to={showMoreLinkTo}
                    data-unique={`btnCoachDashboardIntentConfidenceChartShowMore`}>
                    <Text>Show more...</Text>
                  </NavLink>
                </GridItem>}
              </GridItem>
            }
          </GridContainer>
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, null, null, null, null, optionalSupportWarning)
    }
    return <Query query={CHART_INTENT_QUERY}
      variables={{
        testSessionId: testSessionId,
        intentFilter,
        showDataByExpected,
        orderBy: 'avg_ASC',
        incomprehensionIsBad: INCOMPREHENSION_IS_BAD
      }}>
      {({ loading, error, data: intents }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const intentNames = intents.trainerChartIntent.map(e => e.name)
        return <Query query={CHART_INTENT_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            showDataByExpected,
            intentNames
          }}
          skip={!secondaryTestSessionAvailable || !intentNames || !intentNames.length || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: intentsSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }

            if (intentsSecondary) {
              const idToPerExpectedIntent = mergeArrays(intents.trainerChartIntent, intentsSecondary && intentsSecondary.trainerChartIntentSecondary, 'name')
              let data = Object.values(idToPerExpectedIntent)
              if (testSessionHeader.testProject && testSessionHeader.testProject.minimumAverageConfidence) {
                data = data.filter((e) => e.primary && e.primary.avg < testSessionHeader.testProject.minimumAverageConfidence)
              }
              data = data.map((e) => {
                return {
                  name: e.primary ? e.primary.name : e.secondary.name,
                  primary: e.primary ? e.primary.avg : null,
                  secondary: e.secondary ? e.secondary.avg : null
                }
              })
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              // negating average confidence to error percent
              data.forEach((e) => {
                e.primaryErrorPercent = (1 - e.primary)
                e.secondaryErrorPercent = (1 - e.secondary)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && (this.hasValue(data, 'primary') || this.hasValue(data, 'secondary'))

              return renderCard(
                <RadarChart
                  data={data}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  label2={testsessionLabel(secondaryTestSessionHeader)}
                  valueField1="primary"
                  valueField2="secondary"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="secondaryErrorPercent"
                  reversed={true}
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.primary, null)
            } else {
              let data = intents.trainerChartIntent
              if (testSessionHeader.testProject && testSessionHeader.testProject.minimumAverageConfidence) {
                data = data.filter((e) => e.avg < testSessionHeader.testProject.minimumAverageConfidence)
              }
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              // negating average confidence to error percent
              data.forEach((e) => {
                e.errorPercent = (1 - e.avg)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && this.hasValue(data, 'avg')

              const transformedData = data.map(item => ({
                ...item,
                avg: Math.floor(item.avg * 100),
              }))

              return renderCard(
                <RadarChart
                  data={transformedData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="avg"
                  errorPercent1="errorPercent"
                  reversed={true}
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.avg)
            }
          }}
        </Query>
      }}
    </Query>
  }

  renderEntityConfidenceChart() {
    const {
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      entityFilter,
      history,
      classes,
    } = this.props

    const STATE_KEY = 'entityConfidenceChart'

    const onClick = (e) => {
      if (e.id) {
        history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/entityconfidence/actualentityid/${e.id}`)
      }
    }

    let showMoreLinkTo = `${this.getRootPath()}/results/${testSessionHeader.id}/entityconfidencerisks`
    if (entityFilter) {
      showMoreLinkTo += `/entityFilter/${entityFilter}`
    }
    if (secondaryTestSessionAvailable) {
      showMoreLinkTo += `/secondaryTestSessionId/${secondaryTestSessionHeader.id}`
    }

    const optionalSupportWarning = this.renderOptionalEntityResolutionSupportWarning(testSessionOverallStat) || this.renderOptionalEntityConfidenceSupportWarning(testSessionOverallStat)

    const renderCard = (chart, hasData, data, hasMore, valueFn, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardEntityConfidence" fullheight>
        <CardHeader>
          <GridContainer>
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={1}>
                  <Text icon="square" header inline color={optionalSupportWarning ? 'info' : data.length > 0 ? 'warning' : 'success'}>&nbsp;</Text>
                </GridItem>
                <GridItem md={11}>
                  <Text header inline>Top 10 Entity Confidence Risks</Text>
                  <Text subheader>Shows entities of your test set with weakest average confidence score</Text>
                  <Text>Weak entity confidence is an indicator for the lack of strong training data.</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardEntityConfidenceRisksWiki"
                href="https://support.botium.ai/hc/en-us/articles/4973835302159-Entity-Confidence-Risks"
                target="blank"
              >
                <Tooltip title="Help">
                  <Text lg><ShowIcon icon="question-circle" /></Text>
                </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          <GridContainer>
            <GridItem xs={12}>
              {!optionalSupportWarning && data.length === 0 && <Text >No risks detected</Text>}
              {!optionalSupportWarning && hasData && chart}
            </GridItem>
            {!optionalSupportWarning && data.length > 0 &&
              <GridItem xs={12}>
                <Table disableFooter disableFilter
                    tableHeaderColor="primary"
                    tableHead={['Name', 'Average Confidence']}
                    tableData={data.map((e, i) => {
                      return [
                        () => <><NavLink to={showMoreLinkTo} data-unique={`btnCoachDashboardIntentConfidenceChart`}>{e.name}</NavLink></>,
                        () => <><NumberFormat value={Math.floor(valueFn(e) * 100)} displayType={'text'} /></>,
                      ]
                    })}
                  />
                {hasMore && <GridItem xs={12} center paddingTop>
                  <NavLink data-unique={`btnCoachDashboardEntityConfidenceChartShowMore`}
                    to={showMoreLinkTo}>
                    <Text>Show more...</Text>
                  </NavLink>
                </GridItem>}
              </GridItem>
            }
          </GridContainer>
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, null, null, null, null, optionalSupportWarning)
    }

    return <Query query={CHART_ENTITY_QUERY}
      variables={{ testSessionId, entityFilter, orderBy: 'avg_ASC', first: 11 }}>
      {({ loading, error, data: entities }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const entityNames = entities.trainerChartEntity.map(e => e.name)
        return <Query query={CHART_ENTITY_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            entityNames
          }}
          skip={!secondaryTestSessionAvailable || !entityNames || !entityNames.length || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: entitiesSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }
            if (entitiesSecondary) {
              const idToPerExpectedEntity = mergeArrays(entities.trainerChartEntity, entitiesSecondary.trainerChartEntitySecondary, 'name')

              let data = Object.values(idToPerExpectedEntity)
              if (testSessionHeader.testProject && testSessionHeader.testProject.minimumAverageConfidence) {
                data = data.filter((e) => e.primary && e.primary.avg < testSessionHeader.testProject.minimumAverageConfidence)
              }
              data = data.map((e) => {
                return {
                  id: e.primary ? e.primary.id : null,
                  name: e.primary ? e.primary.name : e.secondary.name,
                  primary: e.primary ? e.primary.avg : null,
                  secondary: e.secondary ? e.secondary.avg : null
                }
              })
              data = _.sortBy(data, (result) => result.primary)
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              data.forEach((e) => {
                e.primaryErrorPercent = (1 - e.primary || 0)
                e.secondaryErrorPercent = (1 - e.secondary || 0)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && (this.hasValue(data, 'primary') || this.hasValue(data, 'secondary'))

              return renderCard(
                <RadarChart
                  data={data}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  label2={testsessionLabel(secondaryTestSessionHeader)}
                  valueField1="primary"
                  valueField2="secondary"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="secondaryErrorPercent"
                  reversed={true}
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.primary)
            } else {
              let data = entities.trainerChartEntity
              if (testSessionHeader.testProject && testSessionHeader.testProject.minimumAverageConfidence) {
                data = data.filter((e) => e.avg < testSessionHeader.testProject.minimumAverageConfidence)
              }
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              data.forEach((e) => {
                e.errorPercent = (1 - e.avg)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && this.hasValue(data, 'avg')

              const transformedData = data.map(item => ({
                ...item,
                avg: Math.floor(item.avg * 100),
              }))

              return renderCard(
                <RadarChart
                  data={transformedData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="avg"
                  reversed={true}
                  errorPercent1="errorPercent"
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.avg)
            }
          }}
        </Query>
      }}
    </Query>
  }

  renderIntentConfidenceDistributionChart() {
    const {
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
      history,
      showDataByExpected,
      classes,
    } = this.props

    const STATE_KEY = 'intentConfidenceDistributionChart'

    const onClick = (e) => {
      history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidencedistribution/${e.from}/${e.to}/${showDataByExpected ? 'expected' : 'actual'}`)
    }

    const optionalSupportWarning = this.renderOptionalIntentResolutionSupportWarning(testSessionOverallStat) || this.renderOptionalIntentConfidenceSupportWarning(testSessionOverallStat)

    const renderCard = (chart, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardIntentConfidenceDistribution" >
        <CardHeader color="info">
          <GridContainer>
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={12}>
                  <Text header inline>Intent Confidence Distribution</Text>
                  <Text subheader>Shows average intent confidence score distribution - click in the
            chart to show details!</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardIntentConfidenceDistributionWiki"
                href="https://support.botium.ai/hc/en-us/articles/4973827726863-Intent-Confidence-Distribution"
                target="blank"
              >
                <Tooltip title="Help">
                  <Text lg><ShowIcon icon="question-circle" /></Text>
                </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          {!optionalSupportWarning && chart}
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, optionalSupportWarning)
    }

    return <Query query={CHART_INTENT_CONFIDENCE_DISTRIBUTION_QUERY}
      variables={{ testSessionId, intentFilter, showDataByExpected }}>
      {({ loading, error, data: confidenceDistribution }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        return <Query query={CHART_INTENT_CONFIDENCE_DISTRIBUTION_QUERY}
          variables={{ testSessionId: secondaryTestSessionId, intentFilter, showDataByExpected }}
          skip={!secondaryTestSessionAvailable || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: confidenceDistributionSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }
            let chartData = []
            for (let i = 0; i < 10; i++) {
              const from = i * 10
              const to = (i + 1) * 10
              chartData.push({
                name: `${from}-${to}`,
                from,
                to,
                primary: (confidenceDistribution.trainerChartIntentConfidenceDistribution.find(e => e.stepIndex === i) || { count: 0 }).count,
                primaryErrorPercent: 1 - (i / 9),
                secondary: ((confidenceDistributionSecondary && confidenceDistributionSecondary.trainerChartIntentConfidenceDistribution.find(e => e.stepIndex === i)) || { count: 0 }).count
              })
            }
            if (confidenceDistributionSecondary) {
              return renderCard(
                <BarChart
                  data={chartData}
                  labelField="name"
                  label1={`Primary: ${testsessionLabel(testSessionHeader)}`}
                  label2={`Secondary: ${testsessionLabel(secondaryTestSessionHeader)}`}
                  valueField1="primary"
                  valueField2="secondary"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="primaryErrorPercent"
                  fill="red"
                  onClick={onClick}

                  tooltipFormatter={(value) => {
                    return  _.isNil(value) ? 'N/A' : <div>
                      Utterances: <NumberFormat value={value} displayType={'text'} decimalScale={4}/>
                      </div>
                  }}


                />)
            } else {
              return renderCard(
                <BarChart
                  data={chartData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="primary"
                  errorPercent1="primaryErrorPercent"
                  onClick={onClick}

                  tooltipFormatter={(value) => {
                    return  _.isNil(value) ? 'N/A' : <div>
                      Utterances: <NumberFormat value={value} displayType={'text'} decimalScale={4}/>
                      </div>
                  }}

                />
              )
            }

          }}
        </Query>
      }}
    </Query>
  }

  renderIntentMismatchProbabilityPerUtteranceChart() {
    const {
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
      history,
      classes,
    } = this.props

    const STATE_KEY = 'intentMismatchProbabilityPerUtteranceChart'

    const onClick = (e) => {
      history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/intentmismatchprobability/${encodeURIComponent(e.utteranceKey)}`)
    }

    const intentListSupported = testSessionOverallStat.intentListSupported
    const renderCard = (chart, data, hasData, hasMore) => (
      <Card data-unique="chartCoachDashboardIntentMismatchProbabilityPerUtterance">
        <CardHeader>
          <GridContainer>
            <GridItem md={11}>
              <GridContainer>
                <GridItem>
                  <Text icon="square" header inline color={!intentListSupported ? 'info' : data.length > 0 ? 'warning' : 'success'}>&nbsp;</Text>
                </GridItem>
                <GridItem md={11}>
                  <Text header inline>Top 10 Intent Mismatch Probability Risks, per utterance</Text>
                  <Text subheader>Shows utterances with high confusion probability based on confidence
            score - click in the chart to show details!</Text>
                  <Text>Utterances with high confusion probability are indicators for the lack of strong training data for the
                  involved intents</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={1} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardIntentMismatchProbabilityPerUtteranceChartIntentMismatchProbabilityRisksWiki"
                href="https://support.botium.ai/hc/en-us/articles/10754225308943-Intent-Mismatch-Probability-Risks"
                target="blank"
              >
              <Tooltip title="Help">
                <Text lg><ShowIcon icon="question-circle" /></Text>
              </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {!intentListSupported && <Text info>Alternative intent prediction is not supported</Text>}
          <GridContainer>
            <GridItem md={12} lg={6} middle>
              {intentListSupported && data.length === 0 && <Text>No risks detected</Text>}
              {intentListSupported && hasData && chart}
            </GridItem>
            {intentListSupported && data.length > 0 &&
              <GridItem md={12} lg={6}>
                <Table disableFooter disableFilter
                    tableHeaderColor="primary"
                    tableHead={['Name', 'Average Confidence']}
                    tableData={data.map((e, i) => {
                      return [
                        () => <><NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/intentmismatchprobabilities/${encodeURIComponent(intentFilter)}${secondaryTestSessionHeader ? ('/' + secondaryTestSessionHeader.id) : ''}`} data-unique={`btnCoachDashboardIntentConfidenceChart`}>{e.name}</NavLink></>,
                        () => <><NumberFormat value={Math.floor(e.primary * 100)} displayType={'text'} /></>,
                      ]
                    })}
                  />
                {hasMore && <GridItem xs={12} center paddingTop>
                <NavLink
                      to={`${this.getRootPath()}/results/${testSessionHeader.id}/intentmismatchprobabilities/${encodeURIComponent(intentFilter)}${secondaryTestSessionHeader ? ('/' + secondaryTestSessionHeader.id) : ''}`}
                      data-unique={`btnCoachDashboardIntentMismatchProbabilityPerUtteranceChart_${testSessionHeader.id}_${encodeURIComponent(intentFilter)}`}
                    >
                      <Text>Show more...</Text>
                    </NavLink>
                </GridItem>}
              </GridItem>
            
            }
          </GridContainer>
        </CardBody>
      </Card>
    )

    if (!intentListSupported) {
      return renderCard(null, false)
    }

    return <Query query={CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_QUERY}
      variables={{ testSessionId: testSessionHeader.id, intentFilter }}>
      {({ loading, error, data: mismatchProbability }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }

        const utteranceKeys = mismatchProbability.trainerChartIntentMismatchProbabilityPerUtterance.map(e => e.trainerUtterance.utteranceKey)
        return <Query query={CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            utteranceKeys
          }}
          skip={!secondaryTestSessionAvailable || !utteranceKeys || !utteranceKeys.length || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: mismatchProbabilitySecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }

            let data = []
            let hasData = false
            let hasMore
            const tooltipFormatter = (value, name, props) => {
              return props.payload[props.dataKey + 'Tooltip']
            }
            const updateData = (perUtterance, valueField, tooltipField) => {
              for (const entry of perUtterance) {
                if (!data[entry.trainerUtterance.utteranceKey]) {
                  data[entry.trainerUtterance.utteranceKey] = {
                    name: entry.trainerUtterance.utterance,
                    utteranceKey: entry.trainerUtterance.utteranceKey
                  }
                }
                data[entry.trainerUtterance.utteranceKey][valueField] = entry.confusionProbability
                data[entry.trainerUtterance.utteranceKey][tooltipField] = valueField === 'secondary' ?
                  <NumberFormat value={entry.confusionProbability} displayType={'text'} decimalScale={4} /> : (
                    <>
                      {entry.confusionProbability ? Math.floor(entry.confusionProbability * 100) : 'N/A'}
                      <div>
                        <Text muted>
                          <p>Best Prediction
                            <br />
                            <b>Intent</b>: {entry.actuals[0].name}
                            <br />
                            <b>Confidence</b>: <NumberFormat value={entry.actuals[0].confidence} displayType={'text'}
                              decimalScale={4} />
                          </p>
                          <p>Second Best Prediction
                            <br />
                            <b>Intent</b>: {entry.actuals.length > 1 && entry.actuals[1].name}
                            <br />
                            <b>Confidence</b>: {entry.actuals.length > 1 &&
                              <NumberFormat value={entry.actuals[1].confidence} displayType={'text'}
                                decimalScale={4} />}
                          </p>
                        </Text>
                      </div>
                    </>
                  )
              }
            }
            updateData(
              mismatchProbability.trainerChartIntentMismatchProbabilityPerUtterance,
              'primary',
              'primaryTooltip'
            )
            if (mismatchProbabilitySecondary) {
              updateData(mismatchProbabilitySecondary.trainerChartIntentMismatchProbabilityPerUtteranceSecondary,
                'secondary',
                'secondaryTooltip'
              )
              data = _.sortBy(Object.values(data), (result) => -result.primary)
              if (testSessionHeader.testProject && testSessionHeader.testProject.minimumConfidenceDiff) {
                data = data.filter((e) => e.primary > testSessionHeader.testProject.minimumConfidenceDiff)
              }
              hasMore = data.length > 10
              data = data.slice(0, 10)
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              hasData = data.length >= 3 && (this.hasValue(data, 'primary') || this.hasValue(data, 'secondary'))
              return renderCard(hasData && <RadarChart
                data={data}
                labelField="name"
                label1={testsessionLabel(testSessionHeader)}
                label2={testsessionLabel(secondaryTestSessionHeader)}
                valueField1="primary"
                valueField2="secondary"
                errorPercent1="primary"
                errorPercent2="secondary"
                tooltipFormatter={tooltipFormatter}
                onClick={onClick}
              />, data, hasData, hasMore)
            } else {
              data = _.sortBy(Object.values(data), (result) => -result.primary)
              if (testSessionHeader.testProject && testSessionHeader.testProject.minimumConfidenceDiff) {
                data = data.filter((e) => e.primary > testSessionHeader.testProject.minimumConfidenceDiff)
              }
              hasMore = data.length > 10
              data = data.slice(0, 10)
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              hasData = data.length >= 3 && this.hasValue(data, 'primary')

              const transformedData = data.map(item => ({
                ...item,
                primary: Math.floor(item.primary * 100),
              }))

              return renderCard(hasData && <RadarChart
                data={transformedData}
                labelField="name"
                label1={testsessionLabel(testSessionHeader)}
                valueField1="primary"
                errorPercent1="primary"
                tooltipFormatter={tooltipFormatter}
                onClick={onClick}
              />, data, hasData, hasMore)
            }
          }}
        </Query>
      }}
    </Query>

  }

  renderIntentConfidenceDeviationChart() {
    const {
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
      history,
      showDataByExpected,
      classes,
    } = this.props

    const STATE_KEY = 'intentConfidenceDeviationChart'

    const onClick = (e) => {
      history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidencedeviation/${encodeURIComponent(e.name)}`)
    }

    const optionalSupportWarning = this.renderOptionalIntentResolutionSupportWarning(testSessionOverallStat) || this.renderOptionalIntentConfidenceSupportWarning(testSessionOverallStat)

    const renderCard = (chart, hasData, data, hasMore, valueFn, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardIntentConfidenceDeviation" fullheight >
        <CardHeader>
          <GridContainer>
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={1}>
                  <Text icon="square" header inline color={optionalSupportWarning ? 'info' : data.length > 0 ? 'warning' : 'success'}>&nbsp;</Text>
                </GridItem>
                <GridItem md={11}>
                  <Text header inline>Top 10 Intent Confidence Deviation Risks</Text>
                  <Text subheader>Shows predicted intents with high average confidence score deviation
            - click in the chart to show details!</Text>
                <Text>High deviation of confidence score for the same predicted intents may be an indicator for the lack of
                strong training data.</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardIntentConfidenceDeviationChartIntentConfidenceDeviationWiki"
                href="https://support.botium.ai/hc/en-us/articles/10736136624271-Intent-Confidence-Deviation-Risks"
                target="blank"
              >
              <Tooltip title="Help">
                <Text lg><ShowIcon icon="question-circle" /></Text>
              </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          <GridContainer>
            <GridItem xs={12}>
              {!optionalSupportWarning && data.length === 0 && <Text>No risks detected</Text>}
              {!optionalSupportWarning && hasData && chart}
            </GridItem>
            {!optionalSupportWarning && data.length > 0 &&
              <GridItem xs={12}>
                <Table disableFooter disableFilter
                    tableHeaderColor="primary"
                    tableHead={['Name', 'Average Confidence Deviation']}
                    tableData={data.map((e, i) => {
                      return [
                        () => <><NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/intents/deviation_DESC`} data-unique={`btnCoachDashboardIntentConfidenceDeviationChart`}>{e.name}</NavLink></>,
                        () => <><NumberFormat value={Math.floor(valueFn(e) * 100)} displayType={'text'} /></>,
                      ]
                    })}
                  />
                {hasMore && <GridItem center paddingTop>
                  <NavLink
                    to={`${this.getRootPath()}/results/${testSessionHeader.id}/intents/deviation_DESC`}
                    data-unique={`btnCoachDashboardIntentConfidenceDeviationChartShowMore`}>
                    <Text>Show more...</Text>
                  </NavLink>
                </GridItem>}
              </GridItem>
            }
          </GridContainer>
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, null, null, null, null, optionalSupportWarning)
    }

    return <Query query={CHART_INTENT_QUERY}
      variables={{
        testSessionId: testSessionId,
        intentFilter,
        showDataByExpected,
        orderBy: 'deviation_DESC',
        first: 10,
        incomprehensionIsBad: INCOMPREHENSION_IS_BAD
      }}>
      {({ loading, error, data: intents }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const intentNames = intents.trainerChartIntent.map(e => e.name)
        return <Query query={CHART_INTENT_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            showDataByExpected,
            intentNames
          }}
          skip={!secondaryTestSessionAvailable || !intentNames || !intentNames.length || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: intentsSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }

            if (intentsSecondary) {
              let data = mergeArraysDeep(
                intents.trainerChartIntent,
                intentsSecondary.trainerChartIntentSecondary,
                'deviation',
                'name')
              if (testSessionHeader.testProject && testSessionHeader.testProject.maximumConfidenceDeviation) {
                data = data.filter((e) => e.primary > testSessionHeader.testProject.maximumConfidenceDeviation)
              }
              data = _.sortBy(data, (result) => -result.primary)
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              data.forEach((e) => {
                e.primaryErrorPercent = (e.primary / 0.3)
                e.secondaryErrorPercent = (e.secondary / 0.3)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && (this.hasValue(data, 'primary') || this.hasValue(data, 'secondary'))

              return renderCard(
                <RadarChart
                  data={data}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  label2={testsessionLabel(secondaryTestSessionHeader)}
                  valueField1="primary"
                  valueField2="secondary"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="secondaryErrorPercent"
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.primary)
            } else {
              let data = intents.trainerChartIntent
              if (testSessionHeader.testProject && testSessionHeader.testProject.maximumConfidenceDeviation) {
                data = data.filter((e) => e.deviation > testSessionHeader.testProject.maximumConfidenceDeviation)
              }
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              data.forEach((e) => {
                e.errorPercent = (e.deviation / 0.3)
              })

              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && this.hasValue(data, 'avg')

              const transformedData = data.map(item => ({
                ...item,
                deviation: Math.floor(item.deviation * 100),
              }))
              
              return renderCard(
                <RadarChart
                  data={transformedData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="deviation"
                  errorPercent1="errorPercent"
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.deviation)
            }
          }}
        </Query>
      }}
    </Query>
  }

  renderEntityConfidenceDeviationChart() {
    const {
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      entityFilter,
      history,
      classes,
    } = this.props

    const STATE_KEY = 'entityConfidenceDeviationChart'

    const onClick = (e) => {
      if (e.id) {
        history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/entityconfidence/actualentityid/${e.id}`)
      } else if (e.primary) {
        history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/entityconfidence/actualentityid/${e.primary.id}`)
      } else {
        history.push(`${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/entityconfidence/actualentityid/${e.secondary.id}`)
      }
    }

    const optionalSupportWarning = this.renderOptionalEntityResolutionSupportWarning(testSessionOverallStat) || this.renderOptionalEntityConfidenceSupportWarning(testSessionOverallStat)
    const renderCard = (chart, hasData, data, hasMore, valueFn, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardEntityConfidenceDeviation" fullheight >
        <CardHeader>
          <GridContainer>
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={1}>
                  <Text icon="square" header inline color={optionalSupportWarning ? 'info' : data.length > 0 ? 'warning' : 'success'}>&nbsp;</Text>
                </GridItem>
                <GridItem md={11}>
                  <Text header inline>Top 10 Entity Confidence Deviation Risks</Text>
                  <Text subheader>Shows predicted entities with high average confidence score deviation
            - click in the chart to show details!</Text>
                  <Text>
                    High deviation of confidence score for the same predicted entities may be an indicator for the lack of
                    strong training data.</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardEntityConfidenceDeviationChartEntityConfidenceDeviationRisksWiki"
                href="https://support.botium.ai/hc/en-us/articles/10736161089295-Entity-Confidence-Deviation-Risks"
                target="blank"
              >
              <Tooltip title="Help">
                <Text lg><ShowIcon icon="question-circle" /></Text>
              </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          <GridContainer>
            <GridItem xs={12}>
              {!optionalSupportWarning && data.length === 0 && <Text>No risks detected</Text>}
              {!optionalSupportWarning && hasData && chart}
            </GridItem>
            {!optionalSupportWarning && data.length > 0 &&
              <GridItem xs={12}>
                <Table disableFooter disableFilter
                    tableHeaderColor="primary"
                    tableHead={['Name', 'Average Confidence Deviation']}
                    tableData={data.map((e, i) => {
                      return [
                        () => <><NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/intents/deviation_DESC`} data-unique={`btnCoachDashboardIntentConfidenceDeviationChart`}>{e.name}</NavLink></>,
                        () => <><NumberFormat value={Math.floor(valueFn(e) * 100)} displayType={'text'} /></>,
                      ]
                    })}
                  />
                {hasMore && <GridItem center paddingTop>
                  <NavLink
                    to={`${this.getRootPath()}/results/${testSessionHeader.id}/intents/deviation_DESC`}
                    data-unique={`btnCoachDashboardIntentConfidenceDeviationChartShowMore`}>
                    <Text>Show more...</Text>
                  </NavLink>
                </GridItem>}
              </GridItem>
            }
          </GridContainer>
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, null, null, null, null, optionalSupportWarning)
    }

    return <Query query={CHART_ENTITY_QUERY}
      variables={{ testSessionId, entityFilter, orderBy: 'deviation_DESC' }}>
      {({ loading, error, data: entities }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const entityNames = entities.trainerChartEntity.map(e => e.name)
        return <Query query={CHART_ENTITY_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            entityNames
          }}
          skip={!secondaryTestSessionAvailable || !entityNames || !entityNames.length}
        >
          {({ loading, error, data: entitiesSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }
            if (entitiesSecondary) {
              let data = Object.values(mergeArrays(
                entities.trainerChartEntity,
                entitiesSecondary.trainerChartEntitySecondary,
                'name'
              ))
              if (testSessionHeader.testProject && testSessionHeader.testProject.maximumConfidenceDeviation) {
                data = data.filter((e) => e.primary.deviation > testSessionHeader.testProject.maximumConfidenceDeviation)
              }
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              data.forEach((e) => {
                e.primaryErrorPercent = (e.primary ? e.primary.deviation / 0.3 : 0)
                e.secondaryErrorPercent = (e.secondary ? e.secondary.deviation / 0.3 : 0)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && (this.hasValue(data, 'primary.deviation') || this.hasValue(data, 'secondary.deviation'))

              return renderCard(
                <RadarChart
                  data={data}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  label2={testsessionLabel(secondaryTestSessionHeader)}
                  valueField1="primary.deviation"
                  valueField2="secondary.deviation"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="secondaryErrorPercent"
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.primary ? d.primary.deviation : 0)
            } else {
              let data = entities.trainerChartEntity
              if (testSessionHeader.testProject && testSessionHeader.testProject.maximumConfidenceDeviation) {
                data = data.filter((e) => e.deviation > testSessionHeader.testProject.maximumConfidenceDeviation)
              }
              const hasMore = data.length > 10
              data = data.slice(0, 10)
              data.forEach((e) => {
                e.errorPercent = (e.deviation / 0.3)
              })
              // hasValue: if every value field is null, then chart is not able to render it
              // (Warning: Received NaN for the `y2` attribute. If this is expected, cast the value to a string.)
              const hasData = data.length >= 3 && this.hasValue(data, 'deviation')

              const transformedData = data.map(item => ({
                ...item,
                deviation: Math.floor(item.deviation * 100),
              }))

              return renderCard(
                <RadarChart
                  data={transformedData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="deviation"
                  errorPercent1="errorPercent"
                  onClick={onClick}
                />,
                hasData, data, hasMore, d => d.deviation)
            }
          }}
        </Query>
      }}
    </Query>
  }

  renderIntentProgressTable(up) {
    const {
      testSessionId,
      testSessionHeader,
      secondaryTestSessionId,
      classes
    } = this.props

    if (!testSessionId || !secondaryTestSessionId) return null
    if (testSessionHeader && !testSessionHeader.trainerSession.overallStat.confusionMatrixStrength) return null

    return <Query query={PER_ACTUAL_INTENTS_QUERY}
      variables={{ testSessionId: testSessionId, incomprehensionIsBad: INCOMPREHENSION_IS_BAD }}>
      {({ loading, error, data: intentsData }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        return <Query query={PER_ACTUAL_INTENTS_QUERY}
          variables={{ testSessionId: secondaryTestSessionId, incomprehensionIsBad: INCOMPREHENSION_IS_BAD }}>
          {({ loading, error, data: secondaryIntentsData }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }

            let data = intentsData.trainerPerActualIntents.map(i => ({
              primary: i,
              secondary: secondaryIntentsData.trainerPerActualIntents.find(s => s.name === i.name),
            })).map(i => {
              if (i.secondary) {
                if (_.isNumber(i.primary.F1) && _.isNumber(i.secondary.F1)) {
                  i.diff = i.primary.F1 - i.secondary.F1
                } else if (_.isNumber(i.primary.F1) && !_.isNumber(i.secondary.F1)) {
                  i.diff = 1
                } else if (!_.isNumber(i.primary.F1) && !_.isNumber(i.secondary.F1)) {
                  i.diff = 0
                } else {
                  i.diff = -1
                }
              } else {
                if (_.isNumber(i.primary.F1)) i.diff = i.primary.F1
                else i.diff = -1
              }
              return i
            })
            let color = 'info'
            let text = ''
            if (up) {
              data = _.orderBy(data.filter(i => i.diff > 0), ['diff'], 'desc').slice(0, 5)
              if (data.length > 0) {
                color = 'success'
                text = <Text info>Congratulations, there are improvements - keep up the good work!</Text>
              } else {
                color = 'info'
                text = <Text info>No improvements identified.</Text>
              }
            } else {
              data = _.orderBy(data.filter(i => i.diff < 0), ['diff'], 'asc').slice(0, 10)
              if (data.length > 0) {
                color = 'danger'
                text = <Text info>Identified some deteriorations.</Text>
              } else {
                color = 'success'
                text = <Text info>Congratulations, there are no deteriorations - keep up the good work!</Text>
              }
            }

            return (<Card>
              <CardHeader>
                {up &&
                  <><Text header color={color} inline><TrendingUpIcon /></Text> <Text header inline>Uptrending Intent Performance</Text></>
                }
                {!up &&
                  <><Text header color={color} inline><TrendingDownIcon /></Text> <Text header inline>Downtrending Intent Performance</Text></>
                }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <GridItem xs={12}>
                      <h5>{text}</h5>
                    </GridItem>
                    {data.length > 0 &&
                      <Table
                        disableHeader
                        disableFooter
                        tableHeaderColor="primary"
                        tableHead={['Predicted Intent', 'F1-Score', 'Diff']}
                        tableData={data.map(entry => {
                          return [
                            () => <NavLink
                              to={`${this.getRootPath()}/results/${testSessionId}/compareintentutterances/${secondaryTestSessionId}/${encodeURIComponent(entry.primary.name)}`}
                              data-unique={`btnCoachDashboardIntentProgressTable_${testSessionId}_${secondaryTestSessionId}_${encodeURIComponent(entry.primary.name)}`}
                            >
                              {entry.primary.name}
                            </NavLink>,
                            () => <>
                            <Text md bold rightMarginxs displayInline>{_.isNumber(entry.primary.F1) ? entry.primary.F1.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}</Text>
                            {entry.secondary && <Text topMarginsm inline md regular>
                              <ShowIcon icon="exchange" /> {_.isNumber(entry.secondary.F1) ? entry.secondary.F1.toFixed(2).replace('0.', '').replace('.', '') : 'N/A'}
                            </Text>}
                            </>,
                            () => <DangerOrSuccessText successful={up}>{toFixedSafe(entry.diff)}</DangerOrSuccessText>
                          ]
                        })}
                      />
                    }
                  </GridItem>
                  <GridItem xs={12} center paddingTop>
                    <NavLink className={classes.showLink}
                      to={`${this.getRootPath()}/results/${testSessionId}/compareintents/${secondaryTestSessionId}`}
                      data-unique={`btnCoachDashboardIntentProgressTableShowFullList`}>SHOW FULL LIST
                    </NavLink>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>)
          }}
        </Query>
      }}
    </Query>
  }

  renderIntentUtteranceDistributionChart() {
    const {
      classes,
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
      history,
      showDataByExpected,
    } = this.props

    const STATE_KEY = 'intentUtteranceDistributionChart'

    const onClick = (e) => {
      history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidence/${showDataByExpected ? 'correctedintentname' : 'actualintentname'}/${encodeURIComponent(e.name)}`)
    }

    const minimumUtterancesPerIntent = (testSessionHeader.testProject && testSessionHeader.testProject.minimumUtterancesPerIntent) || MINIMUM_UTTERANCES_PER_INTENT
    const optionalSupportWarning = this.renderOptionalIntentResolutionSupportWarning(testSessionOverallStat)
    const renderCard = (chart, hasData, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardIntentUtteranceDistribution" fullheight >
        <CardHeader color="info">
          <GridContainer>
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={12}>
                  <Text header inline>Intent Utterance Distribution</Text>
                  <Text subheader>Shows amount of utterances per predicted intent - click in the chart
            to show details!</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardIntentUtteranceDistributionChartIntentUtteranceDistributionWiki"
                href="https://support.botium.ai/hc/en-us/articles/4973827739279-Intent-Utterance-Distribution"
                target="blank"
              >
              <Tooltip title="Help">
                <Text lg><ShowIcon icon="question-circle" /></Text>
              </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          {!optionalSupportWarning && !hasData && <Text info>No data to display</Text>}
          {!optionalSupportWarning && hasData && <GridContainer>
            <GridItem xs={12} largePaddingTop largePaddingBottom><Text>
              Low amount of utterances for an intent is an indicator for the lack of training data for the intent in
              question. Expecting {minimumUtterancesPerIntent} training utterances per
              intent.
            </Text></GridItem>
            <GridItem xs={12} largePaddingTop largePaddingBottom>
              {chart}
            </GridItem>
          </GridContainer>
          }
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, null, optionalSupportWarning)
    }

    return <Query query={CHART_INTENT_QUERY}
      variables={{
        testSessionId: testSessionId,
        intentFilter,
        showDataByExpected,
        orderBy: 'count_ASC',
        incomprehensionIsBad: INCOMPREHENSION_IS_BAD
      }}>
      {({ loading, error, data: intents }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const intentNames = intents.trainerChartIntent.map(e => e.name)
        return <Query query={CHART_INTENT_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            showDataByExpected,
            intentNames
          }}
          skip={!secondaryTestSessionAvailable || !intentNames || !intentNames.length || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: intentsSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }

            if (intentsSecondary) {
              let chartData = mergeArraysDeep(
                intents.trainerChartIntent,
                intentsSecondary.trainerChartIntentSecondary,
                'count',
                'name')
              // order by primary, secondary
              // 15 example is good
              chartData.forEach((e) => {
                e.primaryErrorPercent = (1 - e.primary / minimumUtterancesPerIntent)
                e.secondaryErrorPercent = (1 - e.secondary / minimumUtterancesPerIntent)
              })
              const hasData = (this.hasValue(chartData, 'primary') || this.hasValue(chartData, 'secondary'))

              return renderCard(
                <BarChart
                  data={chartData}
                  labelField="name"
                  label1={`Primary: ${testsessionLabel(testSessionHeader)}`}
                  label2={`Secondary: ${testsessionLabel(secondaryTestSessionHeader)}`}
                  valueField1="primary"
                  valueField2="secondary"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="secondaryErrorPercent"
                  onClick={onClick}

                  tooltipFormatter={(value) => {
                    return  _.isNil(value) ? 'N/A' : <div>
                      Utterances: <NumberFormat value={value} displayType={'text'} decimalScale={4}/>
                      </div>
                  }}

                />, hasData)
            } else {
              let chartData = intents.trainerChartIntent
              // 15 example is good
              chartData.forEach((e) => e.errorPercent = (1 - e.count / minimumUtterancesPerIntent))
              const hasData = this.hasValue(chartData, 'count')

              return renderCard(
                <BarChart
                  data={chartData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="count"
                  errorPercent1="errorPercent"
                  onClick={onClick}

                  tooltipFormatter={(value) => {
                    return  _.isNil(value) ? 'N/A' : <div>
                      Utterances: <NumberFormat value={value} displayType={'text'} decimalScale={4}/>
                      </div>
                  }}

                />, hasData)
            }
          }}
        </Query>
      }}
    </Query>
  }

  renderEntityUtteranceDistributionChart() {
    const {
      classes,
      testSessionId,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      entityFilter,
      history,
    } = this.props

    const STATE_KEY = 'entityUtteranceDistributionChart'

    const onClick = (e) => {
      history.push(`${this.getRootPath()}/results/${testSessionHeader.id}/entityconfidence/entityname/${encodeURIComponent(e.name)}`)
    }

    const minimumUtterancesPerEntity = (testSessionHeader.testProject && testSessionHeader.testProject.minimumUtterancesPerEntity) || MINIMUM_UTTERANCES_PER_ENTITY

    const optionalSupportWarning = this.renderOptionalEntityResolutionSupportWarning(testSessionOverallStat)

    const renderCard = (chart, hasData, optionalSupportWarning) => (
      <Card data-unique="chartCoachDashboardEntityUtteranceDistribution" fullheight >
        <CardHeader color="info">
          <GridContainer>
            <GridItem md={10}>
              <GridContainer>
                <GridItem md={12}>
                  <Text header inline>Entity Utterance Distribution</Text>
                  <Text subheader>Shows amount of utterances per predicted entity - click in the chart
            to show details!</Text>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={2} right>
              {this.renderToggleSecondaryButton(STATE_KEY)}
              <a
                tabIndex={0}
                className={classes.cardHeaderIcon}
                aria-label="Help"
                title="Help"
                data-unique="btnCoachDashboardEntityUtteranceDistributionChartEntityUtteranceDistributionWiki"
                href="https://support.botium.ai/hc/en-us/articles/4973835304719-Entity-Utterance-Distribution"
                target="blank"
              >
              <Tooltip title="Help">
                <Text lg><ShowIcon icon="question-circle" /></Text>
              </Tooltip>
              </a>
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          {optionalSupportWarning}
          {!optionalSupportWarning && !hasData && <Text info>No data to display</Text>}
          {!optionalSupportWarning && hasData &&
            <GridContainer>
              <GridItem xs={12} largePaddingTop largePaddingBottom><Text>
                Low amount of utterances for an entity is an indicator for the lack of training data for the entity in
                question. Expecting minimum {minimumUtterancesPerEntity} training utterances
                per entity.
              </Text></GridItem>
              <GridItem xs={12} largePaddingTop largePaddingBottom>
                {chart}
              </GridItem>
            </GridContainer>
          }
        </CardBody>
      </Card>
    )

    if (optionalSupportWarning) {
      return renderCard(null, null, optionalSupportWarning)
    }
    return <Query query={CHART_ENTITY_QUERY}
      variables={{ testSessionId, entityFilter, orderBy: 'count_ASC' }}>
      {({ loading, error, data: entities }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const entityNames = entities.trainerChartEntity.map(e => e.name)
        return <Query query={CHART_ENTITY_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            entityNames
          }}
          skip={!secondaryTestSessionAvailable || !entityNames || !entityNames.length || this.getForceHideSecondary(STATE_KEY)}
        >
          {({ loading, error, data: entitiesSecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }
            if (entitiesSecondary) {
              let chartData = mergeArraysDeep(
                entities.trainerChartEntity,
                entitiesSecondary.trainerChartEntitySecondary,
                'count',
                'name')
              // order by primary, secondary
              chartData = _.sortBy(chartData, (result) => result.primary + (result.secondary ? (-1 / result.secondary) : -1))
              chartData.forEach((e) => {
                e.primaryErrorPercent = (1 - e.primary / minimumUtterancesPerEntity)
                e.secondaryErrorPercent = (1 - e.secondary / minimumUtterancesPerEntity)
              })
              const hasData = (this.hasValue(chartData, 'primary') || this.hasValue(chartData, 'secondary'))
              return renderCard(
                <BarChart
                  data={chartData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  label2={testsessionLabel(secondaryTestSessionHeader)}
                  valueField1="primary"
                  valueField2="secondary"
                  errorPercent1="primaryErrorPercent"
                  errorPercent2="secondaryErrorPercent"
                  onClick={onClick}
                />, hasData)
            } else {
              let chartData = entities.trainerChartEntity
              chartData.forEach((e) => e.errorPercent = (1 - e.count / minimumUtterancesPerEntity))
              const hasData = this.hasValue(chartData, 'count')

              return renderCard(
                <BarChart
                  data={chartData}
                  labelField="name"
                  label1={testsessionLabel(testSessionHeader)}
                  valueField1="count"
                  errorPercent1="errorPercent"
                  onClick={onClick}
                />, hasData)
            }
          }}
        </Query>
      }}
    </Query>
  }

  renderAdvices() {
    const { testSessionId } = this.props
    return renderAdvices(testSessionId)
  }

  renderAttentions(intentDiff, entityDiff) {
    const {
      classes,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      secondaryTestSessionOverallStat
    } = this.props

    return <GridContainer>
      {secondaryTestSessionAvailable && secondaryTestSessionOverallStat && secondaryTestSessionOverallStat.invalidReason &&
        <GridItem xs={12} sm={12}>
          <div className={classes.stats}>
            <Text important>{`Secondary Test Session is invalid: ${secondaryTestSessionOverallStat.invalidReason}`}</Text>
          </div>
        </GridItem>}
      {secondaryTestSessionHeader && secondaryTestSessionHeader.trainerSession && secondaryTestSessionHeader.trainerSession.status !== 'READY' &&
        <GridItem xs={12} sm={12}>
          <div className={classes.stats}>
            <Text important>{`The status of the secondary Trainer Session is ${secondaryTestSessionHeader.trainerSession.status}`}</Text>
          </div>
        </GridItem>}

      {/* test scripts ignored */}
      {testSessionOverallStat.testScriptsIgnored > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {secondaryTestSessionOverallStat && renderTrending(testSessionOverallStat.testScriptsIgnored, true, secondaryTestSessionOverallStat.testScriptsIgnored)}
          <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/ignoredtestscripts`} data-unique={`btnCoachDashboardAttentionsIgnoredTestScripts`}>
            <Text important>
              {`${testSessionOverallStat.testScriptsIgnored}/${testSessionOverallStat.testScriptsIgnored + testSessionOverallStat.testScriptsProcessed} test scripts ignored`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* conversation steps ignored */}
      {testSessionOverallStat.stepsIgnored > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {secondaryTestSessionOverallStat && renderTrending(testSessionOverallStat.stepsIgnored, true, secondaryTestSessionOverallStat.stepsIgnored)}
          <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/ignoredtestscriptsteps`} data-unique={`btnCoachDashboardAttentionsIgnoredSteps`}>
            <Text important>
              {`${testSessionOverallStat.stepsIgnored}/${testSessionOverallStat.steps} conversation steps ignored`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* conversation steps without any predicted intent */}
      {INCOMPREHENSION_IS_BAD && testSessionOverallStat.incomprehensionIntents > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {secondaryTestSessionOverallStat && renderTrending(testSessionOverallStat.incomprehensionIntents, true, secondaryTestSessionOverallStat.incomprehensionIntents)}
          <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/notresolvedtoanyintent`} data-unique={`btnCoachDashboardAttentionsIncromprehensionIntents`}>
            <Text important>
              {`${testSessionOverallStat.incomprehensionIntents}/${testSessionOverallStat.count} conversation steps with incomprehension`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* utterances resolved to wrong intent */}
      {testSessionOverallStat.missedIntents > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {secondaryTestSessionOverallStat && renderTrending(testSessionOverallStat.missedIntents, true, secondaryTestSessionOverallStat.missedIntents)}
          <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/resolvedtowrongintent`} data-unique={`btnCoachDashboardAttentionsMissedIntents`}>
            <Text>
              {`${testSessionOverallStat.missedIntents}/${testSessionOverallStat.count} conversation steps predicted unexpected intent`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* entity was missing from response */}
      {testSessionOverallStat.justExpectedEntities > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {secondaryTestSessionOverallStat && renderTrending(
            testSessionOverallStat.justExpectedEntities + testSessionOverallStat.justActualEntities,
            true,
            secondaryTestSessionOverallStat.justExpectedEntities + secondaryTestSessionOverallStat.justActualEntities)}
          <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/justexpectedentities`} data-unique={`btnCoachDashboardAttentionsJustExpectedEntities`}>
            <Text important>
              {`${testSessionOverallStat.justExpectedEntities}/${testSessionOverallStat.justExpectedEntities + testSessionOverallStat.justActualEntitiesChecked + testSessionOverallStat.expectedAndActualEntities} expected entities not predicted`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* unexpected entities predicted */}
      {testSessionOverallStat.justActualEntitiesChecked > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {secondaryTestSessionOverallStat && renderTrending(
            testSessionOverallStat.justActualEntitiesChecked,
            true,
            secondaryTestSessionOverallStat.justActualEntitiesChecked)}
          <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/justactualentitieschecked`} data-unique={`btnCoachDashboardAttentionsJustActualEntitiesChecked`}>
            <Text important>
              {`${testSessionOverallStat.justActualEntitiesChecked}/${testSessionOverallStat.justExpectedEntities + testSessionOverallStat.justActualEntitiesChecked + testSessionOverallStat.expectedAndActualEntities} unexpected entities predicted`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* Intent prediction for xxx utterances starting to fail */}
      {intentDiff && intentDiff.correctToWrongCount > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {renderTrending(intentDiff.correctToWrongCount, true)}
          <NavLink
            to={`${this.getRootPath()}/results/${testSessionHeader.id}/compareintentcorrectnowwrong/${secondaryTestSessionHeader.id}`} data-unique={`btnCoachDashboardAttentionsIntentsCorrectToWrongCount`}>
            <Text important>
              {`Intent prediction for ${intentDiff.correctToWrongCount} utterances starting to fail`}
            </Text>
          </NavLink>
        </div>
      </GridItem>}
      {/* Entity prediction for xxx entities starting to fail */}
      {entityDiff && entityDiff.correctToWrongCount > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {renderTrending(entityDiff.correctToWrongCount, true)}
          <Text important>
            {`Entity prediction for ${entityDiff.correctToWrongCount} entities starting to fail`}
          </Text>
        </div>
      </GridItem>}
      {/* Entity prediction for xxx entities failing */}
      {entityDiff && entityDiff.wrongOrNothingToWrongCount > 0 && <GridItem xs={12} sm={12}>
        <div className={classes.stats}>
          {renderTrendingIcon(0, 0)}
          <Text important>
            {`Entity prediction for ${entityDiff.wrongOrNothingToWrongCount} entities still failing`}
          </Text>
        </div>
      </GridItem>}
    </GridContainer>
  }

  renderAttention() {
    const {
      classes,
      testSessionId,
      secondaryTestSessionId,
      secondaryTestSessionAvailable
    } = this.props

    return <Query query={OVERVIEW_INTENT_COMPARE_QUERY}
      variables={{
        testSessionId,
        secondaryTestSessionId
      }}
      skip={!secondaryTestSessionAvailable}>
      {({ loading, error, data: intentCompareOverviewData }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const intentDiff = secondaryTestSessionAvailable && intentCompareOverviewData.trainerOverviewIntentCompare

        return <Query query={OVERVIEW_ENTITY_COMPARE_QUERY}
          variables={{
            testSessionId,
            secondaryTestSessionId
          }}
          skip={!secondaryTestSessionAvailable}>
          {({ loading, error, data: entityCompareOverviewData }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }
            const entityDiff = secondaryTestSessionAvailable && entityCompareOverviewData.trainerOverviewEntityCompare
            const renderedAttentions = this.renderAttentions(intentDiff, entityDiff)

            const hasItem = !!renderedAttentions.props.children.filter(c => c).length


            return (<>
              <GridContainer fullWidth paddingLeft>
                <GridItem lg={10} middle noPaddingLeft>
                <Text regular>Attention</Text>
                </GridItem>
                <GridItem lg={2} right>
                  <a
                    tabIndex={0}
                    className={classes.cardHeaderIcon}
                    aria-label="Help"
                    title="Help"
                    data-unique="btnCoachDashboardAttentionQualityMetricsWiki"
                    href="https://support.botium.ai/hc/en-us/sections/10736136595983-NLP-Quality-Metrics"
                    target="blank" >
                    <Tooltip title="Help">
                      <Text lg><ShowIcon icon="question-circle" /></Text>
                    </Tooltip>
                  </a>
                </GridItem>
                <GridItem lg={12} noPaddingLeft>
                  {hasItem &&
                  <Text>Botium Coach detected results that require your
                    attention</Text>}
                  {!hasItem && <><Text icon="square" success inline>&nbsp;</Text> <Text inline>No risks detected</Text></>}
                  {renderedAttentions}
                </GridItem>
              </GridContainer>
            </>)
          }}
        </Query>
      }}
    </Query>
  }

  renderTrainingProgress() {
    const {
      classes,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionHeader,
      secondaryTestSessionOverallStat,
      secondaryTestSessionAvailable,
    } = this.props

    const renderLabel = (label, cool) => {
      return <GridItem xs={4} sm={4}>
        <div className={classes.stats}>
          {renderTrendingColor(label, cool)}
        </div>
      </GridItem>
    }

    const renderValue = (value, url, cool, toFixed, secValue) => {

      const renderValueImpl = () => {
        let valueAsString
        if (_.isNil(value)) {
          valueAsString = 'N/A'
        } else if (value === true) {
          valueAsString = 'yes'
        } else if (value === false) {
          valueAsString = 'no'
        } else if (!_.isNil(toFixed) && _.isNumber(value)) {
          valueAsString = value.toFixed(toFixed)
        } else {
          valueAsString = '' + value
        }

        if (_.isNumber(secValue) && _.isNumber(value)) {
          let trend = value - secValue
          const trendingIcon = renderTrendingIcon(trend, cool)
          let trendAsString
          if (!_.isNil(toFixed)) {
            trendAsString = trend.toFixed(toFixed)
          } else {
            trendAsString = '' + trend
          }
          if (trend >= 0) {
            trendAsString = `+${trendAsString}`
          }
          return (<>
            {renderTrendingColor(`${valueAsString} (${trendAsString})`, cool)}
            {trendingIcon}
          </>)
        } else {
          return renderTrendingColor(valueAsString, cool)
        }
      }

      if (!url) {
        return <GridItem xs={4} sm={4}>
          <div className={classes.stats}>
            {renderValueImpl()}
          </div>
        </GridItem>
      }

      return <GridItem xs={4} sm={4}>
        <NavLink to={url} data-unique={`btnCoachDashboardTrainingProgressValue${url.replace(/\//g, '_')}`}>
          <div className={classes.stats}>
            {renderValueImpl()}
          </div>
        </NavLink>
      </GridItem>
    }

    const isCool = (getter, increasingIsGood = true) => {
      if (!secondaryTestSessionAvailable) {
        return 0
      }

      const get = (stat) => {
        return _.isString(getter) ? stat[getter] : getter(stat)
      }
      return (get(testSessionOverallStat) - get(secondaryTestSessionOverallStat)) * (increasingIsGood ? 1 : -1)
    }

    const getSecSafe = (getter) => {
      if (!secondaryTestSessionAvailable) {
        return null
      }

      const get = (stat) => {
        return _.isString(getter) ? stat[getter] : getter(stat)
      }
      return get(secondaryTestSessionOverallStat)
    }

    const renderHeader = () => {
      return (
        <>
          <GridItem xs={4} sm={4}>
          </GridItem>
          <GridItem xs={4} sm={4}>
            <Text bold>{testsessionLabel(testSessionHeader)}</Text>
          </GridItem>
          <GridItem xs={4} sm={4}>
            <Text bold>{secondaryTestSessionAvailable ? testsessionLabel(secondaryTestSessionHeader) : '-'}</Text>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Divider dense />
          </GridItem>
        </>
      )
    }

    const renderInnerHeader = (title) => {
      return (
        <GridItem xs={12} sm={12}>
          <div className={classes.stats}>
            {renderTrendingColor(title, 0)}
          </div>
        </GridItem>
      )
    }

    const renderDivider = (margin = false) => {
      return (
        <GridItem xs={12} sm={12}>
          <Divider dense={!margin} />
        </GridItem>
      )
    }

    const NO_VALUE = <GridItem xs={4} sm={4}>-</GridItem>
    const EMPTY = <GridItem xs={4} sm={4}></GridItem>

    return (
      <GridContainer>

        <GridItem xs={12}>

          <br />

          <Card dense>
            <CardHeader color="info">
              <Text header>Test Set Statistics</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {renderHeader()}

                {renderLabel('Predicted intents', isCool('actualIntentCountNoIncomprehension'))}
                {renderValue(testSessionOverallStat.actualIntentCountNoIncomprehension, `${this.getRootPath()}/results/${testSessionHeader.id}/intents/F1_ASC`, isCool('actualIntentCountNoIncomprehension'), null, getSecSafe('actualIntentCountNoIncomprehension'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.actualIntentCountNoIncomprehension, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/intents/F1_ASC`, isCool('actualIntentCountNoIncomprehension')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('Predicted entities', isCool('actualEntityCount'))}
                {renderValue(testSessionOverallStat.actualEntityCount, `${this.getRootPath()}/results/${testSessionHeader.id}/entities/avg_ASC`, isCool('actualEntityCount'), null, getSecSafe('actualEntityCount'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.actualEntityCount, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/entities/avg_ASC`, isCool('actualEntityCount')) : NO_VALUE}

                {renderDivider(true)}

                {renderLabel('Test scripts', isCool((stat) => stat.testScriptsProcessed + stat.testScriptsIgnored))}
                {renderValue(testSessionOverallStat.testScriptsProcessed + testSessionOverallStat.testScriptsIgnored, null, isCool((stat) => stat.testScriptsProcessed + stat.testScriptsIgnored), null, getSecSafe((stat) => stat.testScriptsProcessed + stat.testScriptsIgnored))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.testScriptsProcessed + secondaryTestSessionOverallStat.testScriptsIgnored, null, isCool((stat) => stat.testScriptsProcessed + stat.testScriptsIgnored)) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...ignored', isCool('testScriptsIgnored', false))}
                {renderValue(testSessionOverallStat.testScriptsIgnored, `${this.getRootPath()}/results/${testSessionHeader.id}/ignoredtestscripts`, isCool('testScriptsIgnored', false), null, getSecSafe('testScriptsIgnored'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.testScriptsIgnored, `${this.getRootPath()}/results/${testSessionHeader.id}/ignoredtestscripts/${secondaryTestSessionHeader.id}`, isCool('testScriptsIgnored', false)) : NO_VALUE}

                {renderDivider(true)}

                {renderLabel('Conversation steps', isCool('steps'))}
                {renderValue(testSessionOverallStat.steps, null, isCool('steps'), null, getSecSafe('steps'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.steps, null, isCool('steps')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...ignored', isCool('stepsIgnored', false))}
                {renderValue(testSessionOverallStat.stepsIgnored, `${this.getRootPath()}/results/${testSessionHeader.id}/ignoredtestscriptsteps`, isCool('stepsIgnored', false), null, getSecSafe('stepsIgnored'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.stepsIgnored, `${this.getRootPath()}/results/${testSessionHeader.id}/ignoredtestscriptsteps/${secondaryTestSessionHeader.id}`, isCool('stepsIgnored', false)) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...with intent asserter', isCool('stepsWithIntent'))}
                {renderValue(testSessionOverallStat.stepsWithIntent, null, isCool('stepsWithIntent'), null, getSecSafe('stepsWithIntent'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.stepsWithIntent, null, isCool('stepsWithIntent')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...with entity asserter', isCool('stepsWithEntity'))}
                {renderValue(testSessionOverallStat.stepsWithEntity, null, isCool('stepsWithEntity'), null, getSecSafe('stepsWithEntity'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.stepsWithEntity, null, isCool('stepsWithEntity')) : NO_VALUE}

                {renderDivider(true)}

                {renderInnerHeader('Support for')}

                {renderDivider()}

                {renderLabel('...alternative intent lists', isCool('intentListSupported'))}
                {renderValue(testSessionOverallStat.intentListSupported, null, isCool('intentListSupported'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.intentListSupported, null, isCool('intentListSupported')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...alternative intent lists support', isCool('entityConfidenceSupported'))}
                {renderValue(testSessionOverallStat.entityConfidenceSupported, null, isCool('entityConfidenceSupported'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.entityConfidenceSupported, null, isCool('entityConfidenceSupported')) : NO_VALUE}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>

          <br />

          <Card dense>
            <CardHeader color="info">
              <Text header>Prediction Statistics</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>

                {renderHeader()}

                {renderInnerHeader('KPIs')}

                {renderDivider()}

                {renderLabel('...F1-Score', isCool('F1'))}
                {renderValue(testSessionOverallStat.F1, `${this.getRootPath()}/results/${testSessionHeader.id}/intents/F1_ASC`, isCool('F1'), 4, getSecSafe('F1'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.F1, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/intents/F1_ASC`, isCool('F1'), 4) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...Precision', isCool('precision'))}
                {renderValue(testSessionOverallStat.precision, `${this.getRootPath()}/results/${testSessionHeader.id}/intents/precision_ASC`, isCool('precision'), 4, getSecSafe('precision'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.precision, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/intents/precision_ASC`, isCool('precision'), 4) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...Recall', isCool('recall'))}
                {renderValue(testSessionOverallStat.recall, `${this.getRootPath()}/results/${testSessionHeader.id}/intents/recall_ASC`, isCool('recall'), 4, getSecSafe('recall'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.recall, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/intents/recall_ASC`, isCool('recall'), 4) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...Accuracy', isCool('accuracy'))}
                {renderValue(testSessionOverallStat.accuracy, `${this.getRootPath()}/results/${testSessionHeader.id}/intents/accuracy_ASC`, isCool('accuracy'), 4, getSecSafe('accuracy'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.accuracy, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/intents/accuracy_ASC`, isCool('accuracy'), 4) : NO_VALUE}

                {renderDivider(true)}

                {renderInnerHeader('Intents')}

                {renderDivider()}

                {renderLabel('...expected', isCool('stepsWithIntent'))}
                {renderValue(testSessionOverallStat.stepsWithIntent, null, isCool('stepsWithIntent'), null, getSecSafe('stepsWithIntent'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.stepsWithIntent, null, isCool('stepsWithIntent')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('......predicted correctly', isCool('matchedIntents'))}
                {renderValue(testSessionOverallStat.matchedIntents, null, isCool('matchedIntents'), null, getSecSafe('matchedIntents'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.matchedIntents, null, isCool('matchedIntents')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('......predicted incorrectly', isCool('missedIntents', false))}
                {renderValue(testSessionOverallStat.missedIntents, `${this.getRootPath()}/results/${testSessionHeader.id}/resolvedtowrongintent`, isCool('missedIntents', false), null, getSecSafe('missedIntents'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.missedIntents, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/resolvedtowrongintent`, isCool('missedIntents', false)) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...incomprehension', isCool('incomprehensionIntents', false))}
                {renderValue(testSessionOverallStat.incomprehensionIntents, `${this.getRootPath()}/results/${testSessionHeader.id}/notresolvedtoanyintent`, isCool('incomprehensionIntents', false), null, getSecSafe('incomprehensionIntents'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.incomprehensionIntents, `${this.getRootPath()}/results/${secondaryTestSessionHeader.id}/notresolvedtoanyintent`, isCool('incomprehensionIntents', false)) : NO_VALUE}

                {renderDivider(true)}

                {renderInnerHeader('Entities')}

                {renderDivider()}

                {renderLabel('...predicted correctly', isCool('expectedAndActualEntities'))}
                {renderValue(testSessionOverallStat.expectedAndActualEntities, null, isCool('expectedAndActualEntities'), null, getSecSafe('expectedAndActualEntities'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.expectedAndActualEntities, null, isCool('expectedAndActualEntities')) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...expected but not predicted', isCool('justExpectedEntities', false))}
                {renderValue(testSessionOverallStat.justExpectedEntities, `${this.getRootPath()}/results/${testSessionHeader.id}/justexpectedentities`, isCool('justExpectedEntities', false), null, getSecSafe('justExpectedEntities'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.justExpectedEntities, `${this.getRootPath()}/results/${testSessionHeader.id}/justexpectedentities/${secondaryTestSessionHeader.id}`, isCool('justExpectedEntities', false)) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...predicted unexpected', isCool('justActualEntitiesChecked', false))}
                {renderValue(testSessionOverallStat.justActualEntitiesChecked, `${this.getRootPath()}/results/${testSessionHeader.id}/justactualentitieschecked`, isCool('justActualEntitiesChecked', false), null, getSecSafe('justActualEntitiesChecked'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.justActualEntitiesChecked, `${this.getRootPath()}/results/${testSessionHeader.id}/justactualentitieschecked/${secondaryTestSessionHeader.id}`, isCool('justActualEntitiesChecked', false)) : NO_VALUE}

                {renderDivider()}

                {renderLabel('...predicted ignored', isCool('justActualEntitiesNotChecked', false))}
                {renderValue(testSessionOverallStat.justActualEntitiesNotChecked, `${this.getRootPath()}/results/${testSessionHeader.id}/justactualentitiesnotchecked`, isCool('justActualEntitiesNotChecked', false), null, getSecSafe('justActualEntitiesNotChecked'))}
                {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.justActualEntitiesNotChecked, `${this.getRootPath()}/results/${testSessionHeader.id}/justactualentitiesnotchecked/${secondaryTestSessionHeader.id}`, isCool('justActualEntitiesNotChecked', false)) : NO_VALUE}

              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>

          <br />

          <Card dense>
            <CardHeader color="info">
              <Text header>Training Progress Statistics</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>

                {!secondaryTestSessionAvailable && <GridItem xs={12} sm={12}>
                  <Text info>
                    Select Secondary Test Session for training progress
                  </Text>
                </GridItem>}
                {
                  secondaryTestSessionAvailable && <Query query={OVERVIEW_INTENT_COMPARE_QUERY}
                    variables={{
                      testSessionId: testSessionHeader.id,
                      secondaryTestSessionId: secondaryTestSessionHeader.id
                    }}>
                    {({ loading, error, data: compareOverviewData }) => {
                      let progressOrError = renderProgressOrError({ loading, error })
                      if (progressOrError) {
                        return progressOrError
                      }
                      const difference = compareOverviewData.trainerOverviewIntentCompare
                      return (<>
                        {renderHeader()}

                        {renderLabel('Utterances', isCool('steps'))}
                        {renderValue(testSessionOverallStat.steps, null, isCool('steps'))}
                        {secondaryTestSessionAvailable ? renderValue(secondaryTestSessionOverallStat.steps, null, isCool('steps')) : NO_VALUE}

                        {renderDivider()}

                        {renderLabel('...now predicted as expected', difference.wrongToCorrectCount)}
                        {renderValue(difference.wrongToCorrectCount, `${this.getRootPath()}/results/${testSessionHeader.id}/compareintentwrongnowcorrect/${secondaryTestSessionHeader.id}`, difference.wrongToCorrectCount)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...new', difference.justPrimaryCount)}
                        {renderValue(difference.justPrimaryCount, `${this.getRootPath()}/results/${testSessionHeader.id}/comparenewutterances/${secondaryTestSessionHeader.id}`, difference.justPrimaryCount)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...missing', -difference.justSecondaryCount)}
                        {renderValue(difference.justSecondaryCount, `${this.getRootPath()}/results/${testSessionHeader.id}/comparemissingutterances/${secondaryTestSessionHeader.id}`, -difference.justSecondaryCount)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...predicted same intent', 0)}
                        {renderValue(difference.sameIntentCount, `${this.getRootPath()}/results/${testSessionHeader.id}/comparesameintent/${secondaryTestSessionHeader.id}`, 0)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...predicted different intent', 0)}
                        {renderValue(difference.otherIntentCount, `${this.getRootPath()}/results/${testSessionHeader.id}/compareotherintent/${secondaryTestSessionHeader.id}`, 0)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...now not predicted as expected anymore', -difference.correctToWrongCount)}
                        {renderValue(difference.correctToWrongCount, `${this.getRootPath()}/results/${testSessionHeader.id}/compareintentcorrectnowwrong/${secondaryTestSessionHeader.id}`, -difference.correctToWrongCount)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...prediction changed, but still not predicted as expected', 0)}
                        {renderValue(difference.wrongToWrongWithOtherIntentCount, `${this.getRootPath()}/results/${testSessionHeader.id}/compareintentwrongnowwrongother/${secondaryTestSessionHeader.id}`, 0)}
                        {EMPTY}

                        {renderDivider()}

                        {renderLabel('...still not predicted as expected', 0)}
                        {renderValue(difference.wrongToWrongWithSameIntentCount, `${this.getRootPath()}/results/${testSessionHeader.id}/compareintentwrongnowwrongsame/${secondaryTestSessionHeader.id}`, 0)}
                        {EMPTY}

                      </>)
                    }}
                  </Query>
                }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }

  renderOverviewCompareColumn() {
    const {
      classes,
      testSessionHeader,
      secondaryTestSessionHeader
    } = this.props

    return <Query query={OVERVIEW_INTENT_COMPARE_QUERY}
      variables={{
        testSessionId: testSessionHeader.id,
        secondaryTestSessionId: secondaryTestSessionHeader.id
      }}>
      {({ loading, error, data: compareOverviewData }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const difference = compareOverviewData.trainerOverviewIntentCompare

        return <>
          {difference.justPrimaryCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/comparenewutterances/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnJustPrimaryCount`}>
                  <Text info>
                    {`${difference.justPrimaryCount} new utterances detected`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.justSecondaryCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/comparemissingutterances/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnJustSecondaryCount`}>
                  <Text warning>
                    {`${difference.justSecondaryCount} missing utterances detected!`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.sameIntentCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/comparesameintent/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnSameIntentCount`}
                >
                  <Text info>
                    {`${difference.sameIntentCount} utterances predicted same intent`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.otherIntentCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/compareotherintent/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnOtherIntentCount`}
                >
                  <Text warning>
                    {`${difference.otherIntentCount} utterances predicted different intent`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.wrongToCorrectCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                {renderTrending(difference.wrongToCorrectCount, false)}
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/compareintentwrongnowcorrect/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnWrongToCorrectCount`}>
                  <Text success>
                    {`${difference.wrongToCorrectCount} utterances now predicted as expected`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.correctToWrongCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                {renderTrending(difference.correctToWrongCount, true)}
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/compareintentcorrectnowwrong/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnCorrectToWrongCount`}>
                  <Text danger>
                    {`${difference.correctToWrongCount} utterances now not predicted as expected anymore`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.wrongToWrongWithOtherIntentCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                {renderTrendingIcon(0, -1)}
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/compareintentwrongnowwrongother/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnWrongToWrongWithOtherIntentCount`}>
                  <Text danger>
                    {`${difference.wrongToWrongWithOtherIntentCount} utterances prediction changed, but still not predicted as expected`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
          {difference.wrongToWrongWithSameIntentCount > 0 &&
            <GridItem xs={12} sm={12}>
              <div className={classes.stats}>
                {renderTrendingIcon(0, -1)}
                <NavLink
                  to={`${this.getRootPath()}/results/${testSessionHeader.id}/compareintentwrongnowwrongsame/${secondaryTestSessionHeader.id}`}
                  data-unique={`btnCoachDashboardOverviewCompareColumnWrongToWrongWithSameIntentCount`}>
                  <Text danger>
                    {`${difference.wrongToWrongWithSameIntentCount} utterances still not predicted as expected`}
                  </Text>
                </NavLink>
              </div>
            </GridItem>
          }
        </>
      }}
    </Query>
  }

  renderUnexpectedIntentList() {
    const {
      classes,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
    } = this.props
    const { expectedIntentsSupported } = testSessionOverallStat

    return <Query query={LIST_UNEXPECTED_INTENT_QUERY}
      variables={{ testSessionId: testSessionHeader.id, intentFilter }}>
      {({ loading, error, data: mismatchProbability }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const intent1And2s = mismatchProbability.trainerListUnexpectedIntent.map(e => JSON.stringify([e.intent1, e.intent2]))
        return <Query query={LIST_UNEXPECTED_INTENT_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            intent1And2s
          }}
          skip={!secondaryTestSessionAvailable || !intent1And2s || !intent1And2s.length}
        >
          {({ loading, error, data: mismatchProbabilitySecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }
            let data = Object.values(mergeArrays(
              mismatchProbability.trainerListUnexpectedIntent,
              mismatchProbabilitySecondary ? mismatchProbabilitySecondary.trainerListUnexpectedIntentSecondary : null,
              'intent1And2',
              false
            ))

            let mixingsPri = 0
            let mixingsSec = 0
            for (const e of data) {
              mixingsPri += e.primary ? e.primary.count : 0
              mixingsSec += e.secondary ? e.secondary.count : 0
            }
            const trendOverall = mixingsPri - mixingsSec

            return (

              <Card>
                <CardHeader
                  color={mixingsPri ? ((!secondaryTestSessionHeader || mixingsPri > mixingsSec) ? 'danger' : 'warning') : 'success'}>
                  <Text header> Intent Mismatch Probability Risks, Mixed Intents <a
                    data-unique="btnCoachDahsboardIntentMismatchProbabilityWiki"
                    href="https://support.botium.ai/hc/en-us/articles/10754225308943-Intent-Mismatch-Probability-Risks"
                    target="help" className={classes.cardHeaderIcon}><ShowIcon icon="question-circle" /></a></Text>
                  <Text subheader>
                    {`Mixed Intents: ${mixingsPri}${secondaryTestSessionHeader ? (' (' + renderTrendingLabel(trendOverall) + ')') : ''}`}
                  </Text>
                </CardHeader>
                <CardBody>
                  {!expectedIntentsSupported && <Text info>There are no intent asserters in this test project</Text>}
                  {expectedIntentsSupported && data.length === 0 && <Text info>No Mixed Intents detected</Text>}
                  {expectedIntentsSupported && data.length > 0 && <Table
                    name="IntentMismatchProbabilityPerIntentByUnexpectedIntentList"
                    disableHeader={true}
                    disableFooter={false}
                    tableHeaderColor="primary"
                    tableHead={['Intent 1', 'Intent 2', 'Number of mixings']}
                    tableData={data.map(entry => {
                      const countPri = entry.primary ? entry.primary.count : 0
                      const countSec = entry.secondary ? entry.secondary.count : 0

                      return [
                        () => <NavLink
                          to={`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidence/intentname/${encodeURIComponent(entry.primary.intent1)}`}
                          data-unique="btnCoachDashboardUnexpectedIntentListPrimaryIntent1">
                          {entry.primary.intent1}
                        </NavLink>,
                        () => <NavLink
                          to={`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidence/intentname/${encodeURIComponent(entry.primary.intent2)}`}
                          data-unique="btnCoachDashboardUnexpectedIntentListPrimaryIntent2">
                          {entry.primary.intent2}
                        </NavLink>,
                        () => {
                          const trend = countPri - countSec
                          return (<div className={classes.stats}>
                            {secondaryTestSessionHeader && renderTrending(countPri, true, countSec)}
                            {countPri}
                            {secondaryTestSessionHeader && ` (${trend > 0 ? ('+' + trend) : trend})`}
                          </div>)
                        }
                      ]
                    })}
                  />}
                </CardBody>
              </Card>
            )
          }}
        </Query>
      }}
    </Query>
  }

  renderUnexpectedIntentByAlternativeListList() {
    const {
      classes,
      testSessionHeader,
      testSessionOverallStat,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionAvailable,
      intentFilter,
    } = this.props
    const { intentListSupported } = testSessionOverallStat


    return <Query query={LIST_UNEXPECTED_INTENT_BY_ALTERNATIVE_LIST_QUERY}
      variables={{ testSessionId: testSessionHeader.id, intentFilter }}>
      {({ loading, error, data: mismatchProbability }) => {
        let progressOrError = renderProgressOrError({ loading, error })
        if (progressOrError) {
          return progressOrError
        }
        const intent1And2s = mismatchProbability.trainerListUnexpectedIntentByAlternativeList.map(e => JSON.stringify([e.intent1, e.intent2]))
        return <Query query={LIST_UNEXPECTED_INTENT_BY_ALTERNATIVE_LIST_SECONDARY_QUERY}
          variables={{
            testSessionId: secondaryTestSessionId,
            intent1And2s
          }}
          skip={!secondaryTestSessionAvailable || !intent1And2s || !intent1And2s.length}
        >
          {({ loading, error, data: mismatchProbabilitySecondary }) => {
            let progressOrError = renderProgressOrError({ loading, error })
            if (progressOrError) {
              return progressOrError
            }

            let data = Object.values(mergeArrays(
              mismatchProbability.trainerListUnexpectedIntentByAlternativeList,
              mismatchProbabilitySecondary ? mismatchProbabilitySecondary.trainerListUnexpectedIntentByAlternativeListSecondary : null,
              'intent1And2',
              false
            ))

            const avgOverallPrimary = this.avg(mismatchProbability.trainerListUnexpectedIntentByAlternativeList, 'avg')
            const avgOverallSecondary = mismatchProbabilitySecondary ? this.avg(mismatchProbabilitySecondary.trainerListUnexpectedIntentByAlternativeListSecondary, 'avg') : null
            return (
              <Card>
                <CardHeader
                  color={(secondaryTestSessionHeader && (avgOverallPrimary > avgOverallSecondary)) ? 'danger' : 'info'}>
                  <Text header> Top 100 Intent Mismatch Probability Risks, Alternative
                    Intents <a
                      data-unique="btnCoachDahsboardIntentMismatchProbabilityAlternativeIntentsListWiki"
                      href="https://support.botium.ai/hc/en-us/articles/10754225308943-Intent-Mismatch-Probability-Risks"
                      target="help" className={classes.cardHeaderIcon}><ShowIcon icon="question-circle" /></a></Text>
                  <Text subheader>
                    {'Average strength of Top 100 alternative intents: '}
                    <NumberFormat value={avgOverallPrimary} displayType={'text'} decimalScale={4} />
                    {mismatchProbabilitySecondary ? (' (' + renderTrendingLabel(avgOverallPrimary - avgOverallSecondary, false, 4) + ')') : ''}
                  </Text>
                </CardHeader>
                <CardBody>
                  {!intentListSupported &&
                    <Text info>Alternative intent prediction is not supported</Text>}
                  {intentListSupported && <Table
                    name="IntentMismatchProbabilityPerIntentByAlternativeIntentsList"
                    disableHeader={true}
                    disableFooter={false}
                    tableHeaderColor="primary"
                    tableHead={['Intent 1', 'Intent 2', 'Confidence', 'Occurences', 'Most confusing utterance']}
                    tableData={data.map(entry => {

                      // confidence is avg confidence weighted by worst one
                      const priConfidence = entry.primary.avg
                      const priOcc = entry.primary.count
                      const priUtt = entry.primary.worstUtterance
                      const secConfidence = entry.secondary ? entry.secondary.avg : 0
                      const secOcc = entry.secondary ? entry.secondary.count : 0
                      const secUtt = entry.secondary ? entry.secondary.worstUtterance : 'N/A'

                      return [
                        () => <NavLink
                          to={`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidence/intentname/${encodeURIComponent(entry.primary.intent1)}`}
                          data-unique="btnCoachDashboardUnexpectedIntentByAlternativeListListPrimaryIntent1">
                          {entry.primary.intent1}
                        </NavLink>,
                        () => <NavLink
                          to={`${this.getRootPath()}/results/${testSessionHeader.id}/intentconfidence/intentname/${encodeURIComponent(entry.primary.intent2)}`}
                          data-unique="btnCoachDashboardUnexpectedIntentByAlternativeListListPrimaryIntent2">
                          {entry.primary.intent2}
                        </NavLink>,
                        () => renderTrendingText(Math.floor(priConfidence * 100), (entry.secondary) ? (secConfidence - priConfidence) : 0, true, 4, entry.primary && entry.secondary),
                        priOcc + (secondaryTestSessionHeader ? `(${renderTrendingLabel(priOcc - secOcc)})` : ''),
                        priUtt + (secondaryTestSessionHeader ? ` (${secUtt})` : '')
                      ]
                    })}
                  />}
                </CardBody>
              </Card>
            )
          }}
        </Query>
      }}
    </Query>
  }

  renderDownloads() {
    const {
      testSessionHeader,
      secondaryTestSessionHeader,
      setAlertErrorMessage
    } = this.props

    const renderDownloadsForSession = (header) => {
      return <GridContainer>
          <GridItem xs={12} sm={12}>
          <Card>
              <CardHeader color="info">
                <Text header>{`Test Session: ${testsessionLabel(header)}`}</Text>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={8} lg={5} largePaddingBottom>
                    <SimpleTable
                      tableHeaderColor="primary"
                      tableData={
                        [
                          [
                            <Text>Test Result</Text>,
                            <><Button data-unique="btnCoachDashboardDownloadTestResultJson" small
                              onClick={() => {
                                downloadfile(`${config.api.base}/trainer/testresult/${header.id}?REPORTER=json`).catch(err => setAlertErrorMessage(err.message))
                              }}>
                              <ShowIcon icon="cloud-download-alt" /> JSON
                            </Button>
                              <Button data-unique="btnCoachDashboardDownloadTestResultCsv" small
                                onClick={() => {
                                  downloadfile(`${config.api.base}/trainer/testresult/${header.id}?REPORTER=csv`).catch(err => setAlertErrorMessage(err.message))
                                }}>
                                <ShowIcon icon="cloud-download-alt" /> CSV
                              </Button>
                            </>
                          ],
                          [
                            <><Text>NLU Analytics</Text><br /><Text muted>(Confusion Matrix, F1 Score, Resolved Intents)</Text></>,
                            <Button data-unique="btnCoachDashboardDownloadTestResultExcel" small
                              onClick={() => {
                                downloadfile(`${config.api.base}/nlpanalytics/${header.id}`).catch(err => setAlertErrorMessage(err.message))
                              }}>
                              <ShowIcon icon="cloud-download-alt" /> Excel
                            </Button>
                          ]

                        ]
                      }
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>  
          </GridItem>
      </GridContainer>
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          {testSessionHeader && renderDownloadsForSession(testSessionHeader)}
          {secondaryTestSessionHeader && renderDownloadsForSession(secondaryTestSessionHeader)}
        </GridItem>
      </GridContainer>
    )
  }


    renderToolBarold() {
    const {
      classes,
      testSessionId,
      secondaryTestSessionId,
      testSessionHeader,
      testSessionData,
      secondaryTestSessionHeader,
      secondaryTestSessionData,
      setDashboardSettings,
      testSessionSwitch,
      intentFilter,
      entityFilter,
      showDataByExpected
    } = this.props

    const primaryLoading = (testSessionData && testSessionData.loading)
    const secondaryLoading = (secondaryTestSessionData && secondaryTestSessionData.loading)
    const loading = primaryLoading || secondaryLoading

    const renderBar = (testSessionId, progress, text) => (
      <NavLink to={`${this.getRootPath()}/results/${testSessionHeader.id}/intents${testSessionId !== testSessionHeader.id ? `/${testSessionId}` : ''}/F1_ASC`} data-unique="btnCoachDashboardToolBarF1">
        <SimpleBar progress={progress} minGreen={1}><b>{text}</b></SimpleBar>
      </NavLink>
    )

    return (<>
      <GridContainer justify="space-between" style={{ paddingTop: 15 }}>
        <GridItem xs>
          <TestProjectDialog
            testSessionId={testSessionId}
            open={this.state.showPrimarySelectDialog}
            onSelected={async (testSessionId) => {
              this.setState({ showPrimarySelectDialog: false })
              setDashboardSettings('testSessionId', testSessionId)
              if (!testSessionId) {
                setDashboardSettings('testSessionId', '')
              }
            }}
            onCancel={() => this.setState({ showPrimarySelectDialog: false })}
          />
          <Card dense>
            <CardHeader color="info">
              <Text header>
                {testSessionHeader &&
                  <LinkButton className={classes.cardHeaderIcon} data-unique="btnCoachDashboardToolbarPrimarySelectDialog" onClick={() => this.setState({ showPrimarySelectDialog: true })}><EditIcon /></LinkButton>}
                {testSessionHeader && <b>{testsessionLabel(testSessionHeader)}<br /></b>}
                {!testSessionHeader && <b>Primary Test Session</b>}
                {testSessionHeader && <span className={classes.cardCategoryWhite}>Primary Test Session</span>}
              </Text>
            </CardHeader>
            <CardBody>
              {primaryLoading && <em>Analyzing Test Session ...</em>}
              {!primaryLoading && testSessionHeader && <GridContainer>
                <GridItem xs={12} className={classes.statsnoflex} block>
                  <NavLink tabIndex={-1} to={`/regression/projects/view/${testSessionHeader.testProject.id}/results/${testSessionId}`} data-unique="btnCoachDashboardToolBarPrimaryTestSessionCreatedAt">
                    <Chip tabIndex={0} clickable truncate="250px" variant="testsession" label={<DateFormat>{testSessionHeader.createdAt}</DateFormat>} />
                  </NavLink>
                  {testSessionHeader.testProject &&
                    <NavLink tabIndex={-1} to={`/regression/projects/view/${testSessionHeader.testProject.id}`} data-unique="btnCoachDashboardToolBarPrimaryTestSessionTestProject">
                      <Chip tabIndex={0} clickable truncate="250px" variant="testproject" label={testSessionHeader.testProject.name} />
                    </NavLink>
                  }
                  {testSessionHeader.chatbot &&
                    <NavLink tabIndex={-1} to={`/chatbots/view/${testSessionHeader.chatbot.id}`} data-unique="btnCoachDashboardToolBarPrimaryTestSessionChatbot">
                      <Chip tabIndex={0} clickablentruncate="250px" variant="chatbot" avatarImage={<AvatarImage avatar={testSessionHeader.chatbot.avatar} containermode={testSessionHeader.chatbot.containermode} chatbotId={testSessionHeader.chatbot.id} />}
                        label={testSessionHeader.chatbot.name} />
                    </NavLink>
                  }
                  {testSessionHeader.testSets && testSessionHeader.testSets.map(t =>
                    <NavLink tabIndex={-1} to={`/testsets/view/${t.id}`} key={t.id} data-unique={`btnCoachDashboardToolBarPrimaryTestSessionTestSet_${t.id}`}>
                      <Chip tabIndex={0} clickable truncate="250px" variant="testset" label={t.name} />
                    </NavLink>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <Divider />
                </GridItem>
                {hasConfusionMatrix(testSessionHeader) && <React.Fragment>
                  <GridItem xs={12} sm={4} >
                    {renderBar(testSessionHeader.id, testSessionHeader.trainerSession.overallStat.F1, `F1-Score: ${Math.round(testSessionHeader.trainerSession.overallStat.F1 * 100)}/100`)}
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    {renderBar(testSessionHeader.id, testSessionHeader.trainerSession.overallStat.accuracy, `Accuracy: ${Math.round(testSessionHeader.trainerSession.overallStat.accuracy * 100)}/100`)}
                  </GridItem>
                </React.Fragment>}
                {!hasConfusionMatrix(testSessionHeader) && hasIncomprehensionScore(testSessionHeader) &&
                  <React.Fragment>
                    <GridItem xs={12} sm={4}>
                      {renderBar(testSessionHeader.id, getIncomprehensionScore(testSessionHeader), `NLP Score: ${Math.round(getIncomprehensionScore(testSessionHeader) * 100)}/100`)}
                    </GridItem>
                  </React.Fragment>}
                {testSessionHeader.trainerSession && testSessionHeader.trainerSession.overallStat && testSessionHeader.trainerSession.overallStat.intentConfidenceSupported &&
                  <GridItem xs={12} sm={4}>
                    {renderBar(testSessionHeader.id, testSessionHeader.trainerSession.overallStat.avg || 0, `Avg Confidence: ${testSessionHeader.trainerSession.overallStat.avg ? Math.round(testSessionHeader.trainerSession.overallStat.avg * 100) / 100 : 'N/A'}`)}
                  </GridItem>
                }
              </GridContainer>}
              {!primaryLoading && !testSessionHeader &&
                <Button secondary data-unique="btnCoachDashboardSelectPrimaryTest" onClick={() => this.setState({ showPrimarySelectDialog: true })}>Select Primary Test
                  Session</Button>}
            </CardBody>
          </Card>
        </GridItem>
        {testSessionId && secondaryTestSessionId &&
          <GridItem xs={12} lg={1} middle center style={{ maxWidth: '60px' }}>
            <Text header>
              <LinkButton data-unique="btnCoachDashboardToolbarSwitch" onClick={() => {
                setDashboardSettings('testSessionSwitch', !testSessionSwitch)
              }} disabled={primaryLoading || secondaryLoading || !testSessionId}>
                <ShowIcon icon="exchange" />
              </LinkButton>
            </Text>
          </GridItem>}
        <GridItem xs={12} sm>
          <TestProjectDialog
            testSessionId={secondaryTestSessionId}
            excludeTestSessionId={testSessionId}
            selectedTestProjectId={secondaryTestSessionId || (testSessionHeader && testSessionHeader.testProject && testSessionHeader.testProject.id)}
            open={this.state.showSecondarySelectDialog}
            onSelected={(testSessionId) => {
              this.setState({ showSecondarySelectDialog: false })
              setDashboardSettings('secondaryTestSessionId', testSessionId)
              if (!testSessionId) {
                setDashboardSettings('secondaryTestSessionId', '')
              }
            }}
            onCancel={() => this.setState({ showSecondarySelectDialog: false })}
          />
          <Card dense>
            <CardHeader color="info">
              <Text header>
                {secondaryTestSessionHeader && <LinkButton className={classes.cardHeaderIcon} data-unique="btnCoachDashboardToolbarSecondaryClear"
                  onClick={() => setDashboardSettings('secondaryTestSessionId', '')}><DeleteIcon /></LinkButton>}
                {secondaryTestSessionHeader && <LinkButton className={classes.cardHeaderIcon} data-unique="btnCoachDashboardToolbarSecondarySelectDialog"
                  onClick={() => this.setState({ showSecondarySelectDialog: true })}><EditIcon /></LinkButton>}
                {secondaryTestSessionHeader && <b>{testsessionLabel(secondaryTestSessionHeader)}<br /></b>}
                {!secondaryTestSessionHeader && <b>Secondary Test Session</b>}
                {secondaryTestSessionHeader &&
                  <span className={classes.cardCategoryWhite}>Secondary Test Session</span>}
              </Text>
            </CardHeader>
            <CardBody>
              {secondaryLoading && <em>Analyzing Secondary Test Session ...</em>}
              {!secondaryLoading && secondaryTestSessionHeader && <GridContainer>
                <GridItem xs={12} className={classes.statsnoflex} block>
                  <NavLink to={`/regression/projects/view/${secondaryTestSessionHeader.testProject.id}/results/${secondaryTestSessionId}`} data-unique="btnCoachDashboardToolBarSecondaryTestSessionCreatedAt">
                    <Chip clickable truncate="250px" variant="testsession"
                      label={<DateFormat>{secondaryTestSessionHeader.createdAt}</DateFormat>} />
                  </NavLink>
                  {secondaryTestSessionHeader.testProject &&
                    <NavLink to={`/regression/projects/view/${secondaryTestSessionHeader.testProject.id}`} data-unique="btnCoachDashboardToolBarSecondaryTestSessionTestProject">
                      <Chip clickable truncate="250px" variant="testproject" key={secondaryTestSessionHeader.testProject.id}
                        label={secondaryTestSessionHeader.testProject.name} />
                    </NavLink>
                  }
                  {secondaryTestSessionHeader.chatbot &&
                    <NavLink to={`/chatbots/view/${secondaryTestSessionHeader.chatbot.id}`} data-unique="btnCoachDashboardToolBarSecondaryTestSessionChatbot">
                      <Chip clickable truncate="250px" variant="chatbot"
                        avatarImage={<AvatarImage avatar={secondaryTestSessionHeader.chatbot.avatar} containermode={secondaryTestSessionHeader.chatbot.containermode} chatbotId={secondaryTestSessionHeader.chatbot.id} />}
                        label={secondaryTestSessionHeader.chatbot.name} />
                    </NavLink>
                  }
                  {secondaryTestSessionHeader.testSets && secondaryTestSessionHeader.testSets.map(t =>
                    <NavLink to={`/testsets/view/${t.id}`} key={t.id} data-unique={`btnCoachDashboardToolBarSecondaryTestSessionTestSet_${t.id}`}>
                      <Chip clickable truncate="250px" variant="testset" label={t.name} />
                    </NavLink>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <Divider />
                </GridItem>
                {hasConfusionMatrix(secondaryTestSessionHeader) && <React.Fragment>
                  <GridItem xs={12} sm={4}>
                    {renderBar(secondaryTestSessionHeader.id, secondaryTestSessionHeader.trainerSession.overallStat.F1 || 0, `F1-Score: ${Math.round(secondaryTestSessionHeader.trainerSession.overallStat.F1 * 100)}/100`)}
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    {renderBar(secondaryTestSessionHeader.id, secondaryTestSessionHeader.trainerSession.overallStat.accuracy || 0, `Accuracy: ${Math.round(secondaryTestSessionHeader.trainerSession.overallStat.accuracy * 100)}/100`)}
                  </GridItem>
                </React.Fragment>}
                {!hasConfusionMatrix(secondaryTestSessionHeader) && hasIncomprehensionScore(secondaryTestSessionHeader) &&
                  <React.Fragment>
                    <GridItem xs={12} sm={4}>
                      {renderBar(secondaryTestSessionHeader.id, getIncomprehensionScore(secondaryTestSessionHeader), `NLP Score: ${Math.round(getIncomprehensionScore(secondaryTestSessionHeader) * 100)}/100`)}
                    </GridItem>
                  </React.Fragment>}
                {secondaryTestSessionHeader.trainerSession && secondaryTestSessionHeader.trainerSession.overallStat && secondaryTestSessionHeader.trainerSession.overallStat.intentConfidenceSupported &&
                  <GridItem xs={12} sm={4}>
                    {renderBar(secondaryTestSessionHeader.id, secondaryTestSessionHeader.trainerSession.overallStat.avg || 0, `Avg Confidence: ${secondaryTestSessionHeader.trainerSession.overallStat.avg ? Math.round(secondaryTestSessionHeader.trainerSession.overallStat.avg * 100) / 100 : 'N/A'}`)}
                  </GridItem>
                }
              </GridContainer>}
              {!secondaryLoading && !secondaryTestSessionHeader &&
                <Button secondary data-unique="btnCoachDashboardSelectSecondaryTest" onClick={() => this.setState({ showSecondarySelectDialog: true })}>Select Secondary Test
                  Session</Button>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {testSessionHeader && <GridContainer>
        <GridItem xs={3}>
          <CustomTextField
            disabled={loading || !testSessionId}
            label={'Filter Intents'}
            input={{
              name: 'intents',
              key: 'txtCoachDashboardFilterIntends',
              value: intentFilter,
              onChange: (e, child) => {
                setDashboardSettings('intentFilter', e.target.value)
              },
            }}
            data-unique="txtCoachDashboardFilterIntends"
            helperText="Will restrict the view to intents matching one of these words"
          />
        </GridItem>
        <GridItem xs={3}>
          <CustomTextField
            disabled={loading || !testSessionId}
            label={'Filter Entities'}
            input={{
              name: 'entities',
              value: entityFilter,
              onChange: (e, child) => {
                setDashboardSettings('entityFilter', e.target.value)
              },
            }}
            data-unique="txtCoachDashboardFilterEntities"
            helperText="Will restrict the view to entities matching one of these words"
          />
        </GridItem>
        <GridItem xs={4}>
          <CustomCheckbox
            disabled={loading || !testSessionId}
            input={{
              onChange: e => {
                setDashboardSettings('showDataByExpected', !e.target.checked)
              },
              checked: !showDataByExpected,
            }}
            label="Skip penalty for incorrect intent predictions"
            data-unique="chkLiveChatSplitToConvoAndUtterancesMe"
          />
        </GridItem>
      </GridContainer>}
    </>)
  }


  renderToolBar() {
    const {
      testSessionId,
      secondaryTestSessionId,
      secondaryTestSessionHeader,
      secondaryTestSessionData,
      setDashboardSettings,
    } = this.props

    const secondaryLoading = (secondaryTestSessionData && secondaryTestSessionData.loading)


    return (<>
      <GridContainer>
        <GridItem xs={12} right middle smallPadding smallMarginRight>
          <Tooltip title={`Compared to`}>
            <Button secondary small data-unique="btnCoachDashboardToolbarSecondarySelectDialog" onClick={() => this.setState({ showSecondarySelectDialog: true })} >
              <ShowIcon icon="exchange" /> Compared to
            </Button>
          </Tooltip>
          <Text>
          {secondaryTestSessionHeader && <>
            {secondaryTestSessionHeader.name ? testsessionLabel(secondaryTestSessionHeader) : <LoadingIndicator />}
            </>}
          </Text>
          <TestProjectDialog
            selectedTestSessionId={secondaryTestSessionId}
            excludeTestSessionId={testSessionId}
            selectedTestProjectId={secondaryTestSessionHeader && secondaryTestSessionHeader.testProject && secondaryTestSessionHeader.testProject.id}
            open={this.state.showSecondarySelectDialog}
            onSelected={(testSessionId) => {
              this.setState({ showSecondarySelectDialog: false })
              setDashboardSettings('secondaryTestSessionId', testSessionId)
              if (!testSessionId) {
                setDashboardSettings('secondaryTestSessionId', '')
              }
            }}
            onCancel={() => this.setState({ showSecondarySelectDialog: false })}
          />

          {secondaryLoading && <em>Analyzing Secondary Test Session ...</em>}
          {secondaryTestSessionHeader && <Tooltip title={`Removed`}><Button secondary small data-unique="btnCoachDashboardToolbarSecondaryClear"
            onClick={() => setDashboardSettings('secondaryTestSessionId', '')}><ShowIcon icon="xmark" /></Button></Tooltip>}
        </GridItem>
      </GridContainer>
    </>)
  }

  renderStatistics() {
    const {
      classes,
      testSessionHeader,
      testSessionData,
      secondaryTestSessionData,
      secondaryTestSessionHeader,
      testSessionId,
      secondaryTestSessionId,
    } = this.props

    const primaryLoading = (testSessionData && testSessionData.loading)
    const secondaryLoading = (secondaryTestSessionData && secondaryTestSessionData.loading)

    return (<>
      {secondaryLoading && <em>Analyzing Secondary Test Session ...</em>}
      {primaryLoading && <em>Analyzing Test Session ...</em>}
      {!primaryLoading && testSessionHeader && <Card noMarginTop><CardBody noPaddingTop noPaddingBottom><GridContainer>
        {hasConfusionMatrix(testSessionHeader) &&
          <React.Fragment>
            <GridItem lg borderRight noPadding>
              <NavLink to={!secondaryTestSessionHeader ?
                  `${this.getRootPath()}/results/${testSessionHeader.id}/intents${testSessionId !== testSessionHeader.id ? `/${testSessionId}` : ''}/F1_ASC` :
                  `${this.getRootPath()}/results/${testSessionId}/compareintents/${secondaryTestSessionId}`
                } data-unique="btnCoachDashboardF1ScoreNumber">
                <Card noBorder noMarginBottom noMarginTop hoverlight borderRadiusRight fullheight>
                  <CardBody
                    LargePadding
                    tabIndex={0}
                    className={classes.cardLink}
                  >
                    <GridContainer fullWidth paddingLeft>
                      <GridItem lg={12} middle noPaddingLeft><Text regular>F1-Score</Text></GridItem>
                      <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                        <Text mlg bold rightMarginxs data-unique="txtCoachDashboardF1ScoreNumber">{Math.round(testSessionHeader.trainerSession.overallStat.F1 * 100)}</Text>
                        {!secondaryLoading && secondaryTestSessionHeader &&
                          <>{hasConfusionMatrix(secondaryTestSessionHeader) &&
                            <React.Fragment>
                              <Text topMarginsm inline md regular>
                                <ShowIcon icon="exchange" /> {Math.round(secondaryTestSessionHeader.trainerSession.overallStat.F1 * 100)}
                              </Text>
                            </React.Fragment>
                          }</>
                        }
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </NavLink>
            </GridItem>
            <GridItem lg borderRight noPadding>
              <NavLink to={!secondaryTestSessionHeader ?
                    `${this.getRootPath()}/results/${testSessionHeader.id}/intents${testSessionId !== testSessionHeader.id ? `/${testSessionId}` : ''}/F1_ASC` :
                    `${this.getRootPath()}/results/${testSessionId}/compareintents/${secondaryTestSessionId}`
                  } data-unique="btnCoachDashboardAccuracyNumber">
                <Card noBorder noMarginBottom noMarginTop hoverlight noBorderRadius fullheight>
                  <CardBody
                    LargePadding
                    tabIndex={0}
                    className={classes.cardLink}
                  >
                    <GridContainer fullWidth paddingLeft>
                      <GridItem lg={12} middle noPaddingLeft><Text regular>Accuracy</Text></GridItem>
                      <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                        <Text mlg bold rightMarginxs data-unique="txtCoachDashboardAccuracyNumber">{Math.round(testSessionHeader.trainerSession.overallStat.accuracy * 100)}</Text>
                        {!secondaryLoading && secondaryTestSessionHeader &&
                          <>{hasConfusionMatrix(secondaryTestSessionHeader) &&
                            <React.Fragment>
                              <Text topMarginsm inline md regular>
                                <ShowIcon icon="exchange" /> {Math.round(secondaryTestSessionHeader.trainerSession.overallStat.accuracy * 100)}
                              </Text>
                            </React.Fragment>
                          }</>
                        }
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </NavLink>
            </GridItem>
          </React.Fragment>}
        {!hasConfusionMatrix(testSessionHeader) && hasIncomprehensionScore(testSessionHeader) &&
          <React.Fragment>
            <GridItem lg borderRight noPadding>
              <NavLink to={!secondaryTestSessionHeader ?
                    `${this.getRootPath()}/results/${testSessionHeader.id}/intents${testSessionId !== testSessionHeader.id ? `/${testSessionId}` : ''}/F1_ASC` :
                    `${this.getRootPath()}/results/${testSessionId}/compareintents/${secondaryTestSessionId}`
                  } data-unique="btnCoachDashboardNLPScoreNumber">
                <Card noBorder noMarginBottom noMarginTop hoverlight noBorderRadius fullheight>
                  <CardBody
                    smallPadding
                    tabIndex={0}
                    className={classes.cardLink}
                  >
                    <GridContainer fullWidth paddingLeft>
                      <GridItem lg={12} middle noPaddingLeft><Text regular>NLP Score</Text></GridItem>
                      <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                        <Text mlg bold rightMarginxs data-unique="txtCoachDashboardNLPScoreNumber">{Math.round(getIncomprehensionScore(testSessionHeader) * 100)}</Text>
                        {!secondaryLoading && secondaryTestSessionHeader &&
                          <>{!hasConfusionMatrix(secondaryTestSessionHeader) && hasIncomprehensionScore(secondaryTestSessionHeader) &&
                            <React.Fragment>
                              <Text topMarginsm inline md regular>
                                <ShowIcon icon="exchange" /> {Math.round(getIncomprehensionScore(secondaryTestSessionHeader) * 100)}</Text>
                            </React.Fragment>
                          }</>
                        }
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </NavLink>
            </GridItem>
          </React.Fragment>}
        {testSessionHeader.trainerSession && testSessionHeader.trainerSession.overallStat && testSessionHeader.trainerSession.overallStat.intentConfidenceSupported &&
          <GridItem md={2} lg borderRight noPadding>
            <NavLink to={!secondaryTestSessionHeader ?
              `${this.getRootPath()}/results/${testSessionHeader.id}/intents${testSessionId !== testSessionHeader.id ? `/${testSessionId}` : ''}/F1_ASC` :
              `${this.getRootPath()}/results/${testSessionId}/compareintents/${secondaryTestSessionId}`
            } data-unique="btnCoachDashboardAvgConfidenceNumber">
              <Card noBorder noMarginBottom noMarginTop hoverlight noBorderRadius fullheight>
                <CardBody
                  smallPadding
                  tabIndex={0}
                  className={classes.cardLink}
                >
                  <GridContainer fullWidth paddingLeft>
                    <GridItem lg={12} middle noPaddingLeft><Text regular>Avg Confidence</Text></GridItem>
                    <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                      <Text mlg bold rightMarginxs data-unique="txtCoachDashboardAvgConfidenceNumber">
                        {testSessionHeader.trainerSession.overallStat.avg ? Math.round(testSessionHeader.trainerSession.overallStat.avg.toFixed(2).replace('0.', '').replace('.', '') * 100) / 100 : 'N/A'}
                      </Text>
                      {!secondaryLoading && secondaryTestSessionHeader &&
                        <>{secondaryTestSessionHeader.trainerSession && secondaryTestSessionHeader.trainerSession.overallStat && secondaryTestSessionHeader.trainerSession.overallStat.intentConfidenceSupported &&
                          <>

                            <Text topMarginsm inline md regular>
                              <ShowIcon icon="exchange" /> {secondaryTestSessionHeader.trainerSession.overallStat.avg ? Math.round(secondaryTestSessionHeader.trainerSession.overallStat.avg.toFixed(2).replace('0.', '').replace('.', '') * 100) / 100 : 'N/A'}
                            </Text>
                          </>}
                        </>
                      }
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </NavLink>
          </GridItem>}
          <GridItem lg borderRight noPadding>
            <Card noBorder noMarginBottom noMarginTop hoverlight borderRadiusLeft fullheight>
              <CardBody smallPadding tabIndex={0}>
                {this.renderAttention()}
              </CardBody>
            </Card>
          </GridItem>
      </GridContainer></CardBody></Card>}
      </>)

  }

  renderObjectChips() {
    const {
      testSessionHeader,
    } = this.props

    return (
      <ObjectChips dataUniquePrefix="btnTestSession" testProject={testSessionHeader.testProject} chatbot={testSessionHeader.chatbot} testSets={testSessionHeader.testSets} deviceSets={testSessionHeader.deviceSets} />
    )
  }

  getForceHideSecondary(stateKey) {
    return this.state[stateKey].hideSecondary
  }

  renderToggleSecondaryButton(stateKey) {
    const { secondaryTestSessionAvailable, classes } = this.props

    if (secondaryTestSessionAvailable) {
      const forceHideSecondary = this.getForceHideSecondary(stateKey)

      return (
        <LinkButton
          data-unique={`btnCoachDashboardToggleFilter${_.upperFirst(stateKey)}`}
          className={classes.cardHeaderIcon}
          onClick={() => {
            this.setState({ [stateKey]: { hideSecondary: !forceHideSecondary } })
          }}>
          <Tooltip title={forceHideSecondary ? 'Show Secondary Test Session' : 'Hide Secondary Test Session'} key={'button_' + stateKey}>
            <Text {...(forceHideSecondary ? { muted: true } : {})} lg><ShowIcon icon="clone" /></Text>
          </Tooltip>
        </LinkButton>
      )
    }
    return null
  }

  render() {
    const {
      testSessionId,
      testSessionHeader,
      testSessionData,
      testSessionOverallStat,
      secondaryTestSessionData,
    } = this.props

    let progressOrError = renderProgressOrError(testSessionData) || renderProgressOrError(secondaryTestSessionData)
    let criticalUI
    if (progressOrError) {
      criticalUI = progressOrError
    } else if (!testSessionHeader || !testSessionId) {
      criticalUI = <MessageBox
              variant="warning"
              title={`Choose a Test Session`}
              text=""
        />
    } else if (['PENDING', 'RUNNING'].includes(testSessionHeader.status) || ['RUNNING'].includes(testSessionHeader.trainerSession?.status)) {
      // this is not loading, but in progess
      criticalUI = <LoadingIndicator />
    } else if (!testSessionHeader.trainerSession) {
      criticalUI = <MessageBox
        variant="warning"
        title={`Test Session has no NLP Analytics`}
        text=""
      />
    } else if (testSessionHeader.trainerSession.status !== 'READY') {
      criticalUI = <MessageBox
              variant="warning"
              title={`The status of the NLP Analytics Session is ${testSessionHeader.trainerSession.status}`}
              text={testSessionHeader.trainerSession.err && '(' + testSessionHeader.trainerSession.err + ')'}
       />
    } else if (testSessionOverallStat.invalidReason) {
      criticalUI = <MessageBox
              variant="warning"
              title={testSessionOverallStat.invalidReason}
              text=""
        />
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          {criticalUI}
          {!criticalUI && <Switch>
            {dashboardRoutes.filter(r => r.component).map((r, rindex) => (
              <Route key={rindex} path={r.path} component={r.component} />
            ))}
            <Route component={() => this.renderTabs()} />
          </Switch>}
        </GridItem>
      </GridContainer>
    )
  }
}

const _hasDataForTrainer = (data) => {
  const ts = data && data.testsession && data.testsession.trainerSession
  if (!ts) {
    return false
  }
  if (!ts.overallStat || ts.overallStat.invalidReason) {
    return false
  }
  return ts.status === 'READY' && !ts.cleanedUp
}

class DashboardProgressProxy extends React.Component {

  render() {
    const {
      testSessionDataTemp,
      testSessionId
    } = this.props
    return <TestSessionProgress
      query={TRAINER_SESSION_ROOT_QUERY}
      subscription={TRAINER_SESSION_ROOT_SUBSCRIPTION}
      testSession={testSessionDataTemp?.testsession}
      key={`TestSession_NlpResultIcon_${testSessionId}`}>
      {({testSessionProgress, testSessionProgressLoading, testSessionProgressErr}) => {
        if (!testSessionProgress) {
          return <LoadingIndicator />
        }
        return <Dashboard {...Object.assign({}, this.props, {
          testSessionAvailable: _hasDataForTrainer(testSessionProgress),
          testSessionOverallStat: testSessionProgress?.trainerSession?.overallStat,
          testSessionHeader: testSessionProgress || {},
          // reconstructing the same structure from subscription result as apollo.query returns.
          testSessionData: {loading: testSessionProgressLoading, error: testSessionProgressErr}
        })}/>
      }}
    </TestSessionProgress>
  }
}

export default compose(
  withRouter,
  withStyles(dashboardStyle),
  withWidth(),
  connect(
    (state, ownProps) => ({
      user: state.token.user,
      license: state.settings.license,
      intentFilter: state.dashboard.intentFilter || '',
      entityFilter: state.dashboard.entityFilter || '',
      showDataByExpected: !_.isNil(state.dashboard.showDataByExpected) ? state.dashboard.showDataByExpected : true,
      testSessionSwitch: !_.isNil(state.dashboard.testSessionSwitch) ? state.dashboard.testSessionSwitch : false,
    }),
    { setAlertSuccessMessage, setAlertErrorMessage, clearDashboardSettings, setDashboardSettings },
  ),

  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({ data }) => ({
      testSessionDataTemp: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.testSessionId
        },
      }
    }
  }),

  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({ data }) => ({
      secondaryTestSessionAvailable: _hasDataForTrainer(data),
      secondaryTestSessionOverallStat: _.get(data, 'testsession.trainerSession.overallStat'),
      secondaryTestSessionHeader: data.testsession || {},
      secondaryTestSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.secondaryTestSessionId
        },
      }
    },
    skip: (props) => !props.secondaryTestSessionId || props.secondaryTestSessionId === props.testSessionId,
  }),
)(DashboardProgressProxy)

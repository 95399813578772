import React from 'react'
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const style = theme => ({
  root: {
    padding: '0 11px 0 12px',
  },
  noPadding: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  noPaddingRight: {
    paddingRight: 0,
  },
  grid: {
    display: 'grid'
  },
  noMargin: {
    margin: '0px !important',
  },
  smallMargin: {
    margin: '8px !important',
  },
  smallPadding: {
    padding: '8px !important',
  },
  largeMargin: {
    margin: '20px !important',
  },
  largePadding: {
    padding: '20px  !important'
  },
  middle: {
    display: 'flex',
    alignItems: 'center'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  left: {
    display: 'flex',
    paddingLeft: 43,
  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  header: {
    height: 68
  },
  '@media (max-width: 1280px)': {
    header: {
      height: 'auto'
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.colors.tableRow}`
  },
  borderTop: {
    borderTop: `1px solid ${theme.colors.tableRow}`
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.colors.tableRow}`
  },
  borderRight: {
    borderRight: `1px solid ${theme.colors.tableRow}`
  },
  smallMarginBottom: {
    marginBottom: 8
  },
  smallMarginTop: {
    marginTop: 8
  },
  smallMarginLeft: {
    marginLeft: 8
  },
  smallMarginRight: {
    marginRight: 8
  },
  largeMarginBottom: {
    marginBottom: 20
  },
  largeMarginTop: {
    marginTop: 20
  },
  largeMarginLeft: {
    marginLeft: 20
  },
  largeMarginRight: {
    marginRight: 20
  },
  smallPaddingLeft: {
    paddingLeft: '30px!Important'
  },
  largePaddingLeft: {
    paddingLeft: '50px'
  },
  block: {
    display: 'block'
  },
  floatLeft: {
    float: 'left'
  },
  floatNo: {
    float: 'none'
  },
  marginTop: {
    marginTop: 45,
  },
  marginTopBottom: {
    marginTop: 15,
    marginBottom: 15
  },
  grey: {
    backgroundColor: theme.colors.testsetseditorbg
  },
  primary: {
    backgroundColor: theme.colors.modalBg
  },
  danger: {
    color: theme.colors.common.white,
    background: theme.colors.common.danger
  },
  flexAuto: {
    flex: 'auto'
  },
  skipTableSpace: {
    //position: 'relative',
    //top: '-75px',
    //padding: '0 13px',
  },
  textCenter: {
    textAlign: 'center'
  },
  paddingTop: {
    paddingTop: 20,
  },
  largePaddingTop: {
    paddingTop: 8,
  },
  largePaddingBottom: {
    paddingBottom: 20
  },
  smallPaddingBottom: {
    paddingBottom: 8
  },
  smallPaddingTop: {
    paddingTop: 4
  },
  positionRelative: {
    position: 'relative'
  },
  marginAuto: {
    margin: 'auto'
  },
  '@media (max-width: 1279px)': {
    widthPercent: {
      width: '33%'
    },
    widthPercentWithoutBorder: {
      width: '34%',
      borderRight: 0
    }
  }
})

function GridItem({ ...props }) {
  const {
    classes,
    className,
    children,
    center,
    middle,
    noMargin,
    smallMargin,
    smallPadding,
    largeMargin,
    largePadding,
    right,
    left,
    bottom,
    header,
    borderBottom,
    borderLeft,
    borderTop,
    borderRight,
    smallMarginBottom,
    smallMarginTop,
    smallMarginLeft,
    smallMarginRight,
    largeMarginBottom,
    largeMarginTop,
    largeMarginLeft,
    largeMarginRight,
    smallPaddingLeft,
    largePaddingLeft,
    grid,
    block,
    floatLeft,
    grey,
    primary,
    danger,
    flexAuto,
    noPadding,
    noPaddingLeft,
    noPaddingRight,
    floatNo,
    marginTop,
    marginTopBottom,
    skipTableSpace,
    textCenter,
    paddingTop,
    largePaddingTop,
    largePaddingBottom,
    smallPaddingTop,
    smallPaddingBottom,
    widthPercent,
    widthPercentWithoutBorder,
    positionRelative,
    marginAuto,
     ...rest
  } = props

  return (
    <Grid {...rest} className={classNames({
      [classes.root]: true,
      [classes.noPadding]: noPadding,
      [classes.noPaddingLeft]: noPaddingLeft,
      [classes.noPaddingRight]: noPaddingRight,
      [classes.grid]: grid,
      [classes.noMargin]: noMargin,
      [classes.smallMargin]: smallMargin,
      [classes.smallPadding]: smallPadding,
      [classes.largeMargin]: largeMargin,
      [classes.largePadding]: largePadding,
      [classes.center]: center,
      [classes.middle]: middle,
      [classes.right]: right,
      [classes.left]: left,
      [classes.bottom]: bottom,
      [classes.header]: header,
      [classes.borderBottom]: borderBottom,
      [classes.borderLeft]: borderLeft,
      [classes.borderRight]: borderRight,
      [classes.borderTop]: borderTop,
      [classes.smallMarginBottom]: smallMarginBottom,
      [classes.smallMarginTop]: smallMarginTop,
      [classes.smallMarginLeft]: smallMarginLeft,
      [classes.smallMarginRight]: smallMarginRight,
      [classes.smallPaddingLeft]:smallPaddingLeft,
      [classes.largePaddingLeft]:largePaddingLeft,
      [classes.largeMarginBottom]: largeMarginBottom,
      [classes.largeMarginTop]: largeMarginTop,
      [classes.largeMarginLeft]: largeMarginLeft,
      [classes.largeMarginRight]: largeMarginRight,
      [classes.block]: block || floatLeft,
      [classes.floatLeft]: floatLeft,
      [classes.grey]: grey,
      [classes.primary]: primary,
      [classes.danger]: danger,
      [classes.flexAuto]: flexAuto,
      [classes.floatNo]: floatNo,
      [classes.marginTop]: marginTop,
      [classes.marginTopBottom]: marginTopBottom,
      [classes.skipTableSpace]: skipTableSpace,
      [classes.textCenter]: textCenter,
      [classes.paddingTop]: paddingTop,
      [classes.largePaddingTop]: largePaddingTop,
      [classes.largePaddingBottom]: largePaddingBottom,
      [classes.smallPaddingTop]: smallPaddingTop,
      [classes.smallPaddingBottom]: smallPaddingBottom,
      [classes.borderRight]: borderRight,
      [classes.widthPercent]: widthPercent,
      [classes.widthPercentWithoutBorder]: widthPercentWithoutBorder,
      [classes.positionRelative]: positionRelative,
      [classes.marginAuto]: marginAuto,
      ...(className ? { [className]: true } : {})
    })} item>
      {children}
    </Grid>
  )
}

export default withStyles(style)(GridItem)
